import React, { useEffect, useState } from 'react'

import { Icon, Pagination } from 'semantic-ui-react'

import './AccessRequestsPagination.scss'

const AccessRequestsPagination = (props) => {
    return (
        <div className='cb-access-requests-pagination-wrapper'>
            <Pagination
                className='cb-access-requests-pagination'
                activePage={props.currentPage}
                totalPages={props.lastPage}
                siblingRange='3'
                onPageChange={(e, { activePage }) => props.onPageChange(activePage)}
                ellipsisItem={{
                    content: '...',
                    className: 'cb-ellipsis-item'
                }}
                prevItem={
                    props.previousPage
                    ? {
                          'aria-label': 'Previous item',
                          content: <Icon name='left chevron'/>,
                          onClick: () => props.onPageChange(props.previousPage)
                      }
                    : null
                }
                nextItem={
                    props.nextPage !== null
                    ? {
                          'aria-label': 'Next item',
                          content: <Icon name='right chevron'/>,
                          onClick: () => props.onPageChange(props.nextPage)
                      }
                    : null
                }
                firstItem={null}
                lastItem={null}/>
        </div>
    )
}

export default AccessRequestsPagination