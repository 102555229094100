// https://soshace.com/react-user-login-authentication-using-usecontext-and-usereducer/
import React, { createContext, useReducer } from 'react'
import { AuthReducer, initialState } from './reducer'
 
export const AuthStateContext = createContext()
export const AuthDispatchContext = createContext()

export const AuthProvider = ({ children }) => {
    const [user, dispatch] = useReducer(AuthReducer, initialState)

    return (
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}