import React from 'react'

import { Label } from 'semantic-ui-react'
import '../../../assets/styles/Colors.scss';

import './InfoLabel.scss'

const InfoLabel = (props) => {
    return (
        <Label basic color={'$segurtempo-violet'} size='large' className='cb-info-label'>
            {props.children}
        </Label>
    )
}

export default InfoLabel