import React from 'react'
import { Button, Image, Segment } from 'semantic-ui-react'

import LoginForm from '../LoginForm/LoginForm'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'
import { useAuthState } from '../../../contexts/Auth'

import mainLogo from '../../../assets/images/common/segurtempo-logo.png'
import './LoginSegment.scss'

const LoginSegment = (props) => {
    const { loginLoading } = useAuthState()

    return (
        <Segment className='cb-login-segment'>
            <Image src={mainLogo} size='medium' className='cb-centerrisk-logo' />
            <LoginForm/>
            <span className='cb-forgotten-password-text' onClick={props.showPasswordReset}>
                <strong>¿Has olvidado la contraseña?</strong>
            </span>
            <Button.Group className='cb-buttons'>
                <SecondaryButton onClick={props.showAccessRequest}>
                    Solicitar acceso
                </SecondaryButton>
                <PrimaryButton form='cb-login-form' type='submit' loading={loginLoading} disabled={loginLoading}>
                    Entrar
                </PrimaryButton>
            </Button.Group>
            <div className='cb-copyright'>
                SEGURTEMPO {new Date().getFullYear()} – todos los derechos reservados
            </div>
        </Segment>
    )
}

export default LoginSegment