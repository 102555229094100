import React from 'react'
import { Button } from 'semantic-ui-react'

import './SecondaryButton.scss'

const SecondaryButton = (props) => {
    return (
        <Button {...props} className={`${props.className ? `${props.className} ` : ''}cb-secondary-button`}>
            {props.children}
        </Button>
    )
}

export default SecondaryButton