import React, { useEffect } from 'react'

import { Form, Label } from 'semantic-ui-react'

import {
    deleteReferences,
    updateEnablement,
    updateRequirement,
    updateValue,
    updateVisibility,
    useEnablement,
    useRequirement,
    useValue,
    useVisibility,
    useFormDispatch,
    useFormState,
    useValidationError
} from '../../../../../contexts/Form'

import { isEmptyValue } from '../../../../../assets/js/utils'


const FieldWrapper = (props) => {
    const formState = useFormState()
    const dispatch = useFormDispatch()
    const value = useValue(props.name)
    const enabled = useEnablement(props.name)
    const required = useRequirement(props.name)
    const visible = useVisibility(props.name)
    const validationError = useValidationError(props.name, props.isFileField ? value?.pending : value?.raw)

    const getFieldWrapperProps = () => {
        return {
            key: props.key,
            disabled: !Boolean(enabled),
            required: Boolean(required),
            width: props.width,
            className: props.className
        }
    }

    const hasInvalidValue = () => {
        if (props.prevRequirementContition !== undefined) {
            return props.prevRequirementContition && validationError
        }

        return validationError
    }

    useEffect(() => {
        if (visible === undefined) {
            updateVisibility(dispatch, props.name, props.defaultVisible !== false)
        }
    }, [])

    useEffect(() => {
        if (visible === undefined) {
            updateVisibility(dispatch, props.name, props.defaultVisible !== false)
        } else if (visible) {
            updateEnablement(dispatch, props.name, !Boolean(props.disabled))
            updateRequirement(dispatch, props.name, Boolean(props.required))

            return () => deleteReferences(dispatch, props.name)
        }
    }, [visible])

    return (
        <Form.Field {...getFieldWrapperProps()} error={hasInvalidValue()}>
            {props.children}
            {
                hasInvalidValue() &&
                <Label pointing prompt>
                    Este campo es obligatorio
                </Label>
            }
        </Form.Field>
    )
}

export default FieldWrapper