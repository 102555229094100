import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Header } from 'semantic-ui-react'

import { paths } from '../../config/routes'
import { quoteStatus, quoteStatusNames } from '../../assets/constants/quote'
import { useErrorState } from '../../contexts/ErrorHandling'
import {
    useQuotesAmountByStatus,
    useTotalQuotesAmount
} from '../../contexts/PeriodicUpdates'

import FullPageLoader from '../../components/UI/FullPageLoader/FullPageLoader'
import OverviewGrid from '../../components/UI/OverviewGrid/OverviewGrid'
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton'

import quotesService from '../../services/quotesService'

import './BrokeragesQuotesOverview.scss'

const BrokeragesQuotesOverview = () => {
    const { statusSlug } = useParams()
    const history = useHistory()
    const { setErrorDialog } = useErrorState()
    const [brokeragesQuotes, setBrokeragesQuotes] = useState(null)
    const [status, setStatus] = useState(null)

    const currentStatusAmount = useQuotesAmountByStatus(status)
    const totalQuotes = useTotalQuotesAmount()

    const refresh = (fullRefresh = true) => {
        if (fullRefresh) {
            setBrokeragesQuotes(null)
        }
        
        quotesService.getQuotesAmountsPerBrokerage(status)
            .then(res => {
                const brokerages = []

                res.data?.forEach(brokerage => {
                    const item = {
                        title: brokerage.name,
                        content: `${brokerage.quotes_amount}`,
                        link: paths.BROKERAGE_QUOTES_BUSINESS
                                .replace(':statusSlug', statusSlug)
                                .replace(':brokerageId', brokerage.id)
                    }

                    brokerages.push(item)
                })
                
                setBrokeragesQuotes(brokerages)
            })
            .catch(error => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al cargar las cotizaciones. Por favor, inténtalo de nuevo más tarde.'
                }

                setErrorDialog({
                    title: 'Error cargando cotizaciones',
                    description: errorMessage,
                    onConfirm: () => history.push(paths.DASHBOARD)
                })
            })
    }

    useEffect(() => {
        if (statusSlug) {
            const statusCode = quoteStatus.fromSlug(statusSlug)

            if (!statusCode) {
                history.push(paths.NOT_FOUND)
            } else {
                setStatus(statusCode)
            }
        }
    }, [statusSlug])

    useEffect(() => {
        if (status) {
            refresh()
        }
    }, [status])

    useEffect(() => {
        if (status) {
            refresh(false)
        }
    }, [currentStatusAmount])

    return (
        status &&
        <>
            <Helmet>
                <title>
                    Cotizaciones {quoteStatusNames[status].plural.toLowerCase()} - SegurTempo
                </title>
            </Helmet>
            <div className='cb-quotes-per-brokerage-wrapper'>
                <Header className='cb-page-title'>
                    Vista previa de todas las cotizaciones {quoteStatusNames[status].plural.toLowerCase()}
                    <Header.Subheader>
                        Puedes seleccionar un colaborador para acceder a más información
                    </Header.Subheader>
                </Header>
                {
                    !brokeragesQuotes &&
                    <FullPageLoader text='Cargando...'/>
                }
                {
                    brokeragesQuotes &&
                    <OverviewGrid
                        items={brokeragesQuotes}
                        emptyMessage={{
                            title: 'No se han encontrado solicitudes',
                            content: `Actualmente no hay ninguna solicitud de cotización <strong><i>${quoteStatusNames[status].singular.toLowerCase()}</i></strong>`
                        }}
                        infoLabel={
                            <>
                                COTIZACIONES {quoteStatusNames[status].plural.toUpperCase()} {' '}
                                <strong>{currentStatusAmount}</strong>/{totalQuotes}
                            </>
                        }/>
                }
            </div>
        </>
    )
}

export default BrokeragesQuotesOverview 
