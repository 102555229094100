import { quoteStatus, quoteStatusNames } from '../../assets/constants/quote'
import { eventTypes } from '../constants/notifications'
import { paths } from '../../config/routes'

export default class Notification {
    constructor(event, isBrokerView) {
        this.event = event
        this.isBrokerView = isBrokerView
        this.notificationMessages = {
            [eventTypes.ACCESS_REQUEST_CHANGED]: 'Nueva solicitud de acceso del colaborador {0}',
            [eventTypes.QUOTE_STATUS_CHANGED]: {
                [quoteStatus.NEW]: 'Nueva cotización ({0}){1}',
                [quoteStatus.IN_PROGRESS]: 'La cotización {0}{1} ha pasado a estar {2}',
                [quoteStatus.ISSUED]: 'La cotización {0}{1} ha sido {2}',
                [quoteStatus.QUOTED]: 'La cotización {0}{1} ha pasado a {2}',
                [quoteStatus.REJECTED]: 'La cotización {0}{1} ha sido {2}',
                [quoteStatus.NOT_ACCEPTED]: 'La cotización {0}{1} ha pasado a {2}',
            },
            [eventTypes.NEW_QUOTE_MESSAGE]: 'Nuevo mensaje de {0} en la cotización {1}',
        }
        this.notificationLinks = {
            [eventTypes.ACCESS_REQUEST_CHANGED]: paths.ACCESS_REQUESTS,
            [eventTypes.QUOTE_STATUS_CHANGED]: paths.QUOTE_MANAGEMENT,
            [eventTypes.NEW_QUOTE_MESSAGE]: paths.QUOTE_MANAGEMENT
        }
    }

    get message() {
        let message = this.notificationMessages[this.event.code]

        if (!message) {
            return ''
        }

        switch (this.event.code) {
            case eventTypes.ACCESS_REQUEST_CHANGED:
                message = message.replace('{0}', this.event.data?.brokerage)
                break
            case eventTypes.QUOTE_STATUS_CHANGED:
                message = message[this.event.data?.status]
                message = message.replace('{0}', this.event.data?.number)
                                 .replace('{1}', this.isBrokerView ? '' : ` del colaborador ${this.event.data?.brokerage}`)
                                 .replace('{2}', quoteStatusNames[this.event.data?.status].singular.toLowerCase())
                break
            case eventTypes.NEW_QUOTE_MESSAGE:
                message = message.replace('{0}', this.event.data?.sender)
                                 .replace('{1}', this.event.data?.number)
                break
        }

        return message
    }

    get link() {
        let link = this.notificationLinks[this.event.code]

        if (!link) {
            return
        }

        switch (this.event.code) {
            case eventTypes.QUOTE_STATUS_CHANGED:
            case eventTypes.NEW_QUOTE_MESSAGE:
                link = link.replace(':quoteId', this.event.data?.quote)
                break
        }

        return link
    }
}
