import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'

import { paths } from '../../../config/routes'
import { useUserGroups } from '../../../contexts/Auth'
import {
    resetForm,
    updateValidation,
    useFormDispatch,
    useFormState
} from '../../../contexts/Form'
import { useErrorState } from '../../../contexts/ErrorHandling'

import { isEmptyValue } from '../../../assets/js/utils'
import { quoteStatus } from '../../../assets/constants/quote'

import documentationService from '../../../services/documentationService'
import quotesService from '../../../services/quotesService'

import QuoteForm from '../../QuoteForm/QuoteForm'
import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'

import './QuoteFormPane.scss'

const QuoteFormPane = (props) => {
    const [loadingState, setLoadingState] = useState({ isLoading: true })
    const { setErrorDialog } = useErrorState()
    const dispatch = useFormDispatch()
    const formState = useFormState()
    const userGroups = useUserGroups()
    const history = useHistory()

    const validateForm = () => {
        updateValidation(dispatch, true)

        let isValidForm = true

        for (let fieldName in formState.requirements) {
            let required = formState.requirements[fieldName]
            let fieldValue = formState.values[fieldName]?.raw

            if (required && isEmptyValue(fieldValue)) {
                isValidForm = false
                break
            }
        }

        if (isValidForm) {
            return true
        }

        setErrorDialog({
            title: 'Formulario incompleto',
            description: `Hay campos obligatorios sin completar en el formulario.
                          Por favor, introduce la información necesaria antes de
                          guardar la solicitud.`
        })

        return false
    }

    const saveQuote = () => {
        document.activeElement.blur()

        if (!validateForm()) {
            return
        }

        setLoadingState({ isLoading: true, message: 'Actualizando datos...' })

        const formData = new FormData()
        let data = {...formState.values}
        delete data['quoteRequestFilesUpload']

        formData.append('quote_data', JSON.stringify(data))

        const saveResult = quotesService.updateQuote(props.quote.id, formData)
        let docData

        return saveResult
                .then(res => {
                    setLoadingState({ isLoading: true, message: 'Actualizando documentación...' })

                    docData = {
                        structure: {
                            modalityName: props.quote.modality.long_name,
                            sections: [...formState.config.sections],
                            values: {...formState.values},
                            visibilities: {...formState.visibilities}
                        }
                    }

                    return documentationService.createQuotePdf(props.quote.id, docData)
                })
                .then(() => documentationService.createQuoteDocx(props.quote.id, docData))
                .then(res => {
                    setLoadingState({ isLoading: true, message: 'Actualizando información...' })
                    return props.refreshQuoteData()
                })
                .then(() => {
                    setLoadingState({ isLoading: false })
                })
                .catch(error => {
                    setLoadingState({ isLoading: false })

                    let errorMessage = error?.response?.data?.error

                    if (!errorMessage) {
                        errorMessage = 'Ha tenido lugar un error al guardar los datos. Por favor, inténtalo de nuevo más tarde.'
                    }

                    setErrorDialog({
                        title: 'Error al guardar',
                        description: errorMessage
                    })
                })
    }

    const goBack = () => {
        const statusSlug = quoteStatus.toSlug(props.quote.status.code)
        const brokerageSlug = props.quote.requester.brokerage.id
        const baseUrl = (
            userGroups.business_department
                ? paths.BROKERAGE_QUOTES_BUSINESS
                : paths.BROKERAGE_QUOTES_BROKER
        )

        const previousUrl = baseUrl.replace(':statusSlug', statusSlug)
                                   .replace(':brokerageId', brokerageSlug)

        history.push(previousUrl)
    }

    useEffect(() => {
        setTimeout(() => setLoadingState({ isLoading: false }), 500)
    }, [])

    return (
        <>
            {
                loadingState?.isLoading &&
                <FullPageLoader text={loadingState.message || 'Cargando formulario...'}/>
            }
            <div className='cb-quote-form-wrapper'>
                <QuoteForm
                    modality={props.quote.modality}
                    values={props.quote.data}
                    hiddenFields={['quoteRequestFilesUpload']}
                    readOnly={!userGroups.business_department}/>
                <div className='cb-quote-generic-actions'>
                    <Button icon labelPosition='left' onClick={goBack}>
                        <Icon name='reply'/>
                        Volver
                    </Button>
                    {
                        userGroups.business_department &&
                        <Button icon labelPosition='right' onClick={saveQuote}>
                            Guardar cambios
                            <Icon name='save'/>
                        </Button>
                    }
                    {
                        props.actionButtons?.map((buttonConfig, index) => (
                            <Button
                                icon
                                labelPosition='right'
                                onClick={buttonConfig.action}
                                key={index}
                                className={buttonConfig.highlight ? 'cb-highlighted' : undefined}>
                                {buttonConfig.text}
                                <Icon name={buttonConfig.icon}/>
                            </Button>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default QuoteFormPane