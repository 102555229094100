import React from 'react'
import { useLocation } from 'react-router-dom'

import {
    useClosedQuotesAmount,
    useQuotesAmountByStatus
} from '../../../contexts/PeriodicUpdates'
import { useUserGroups } from '../../../contexts/Auth'
import { quoteStatus, quoteStatusHints } from '../../../assets/constants/quote'
import { paths } from '../../../config/routes'
import SideBarExternalLink from '../SideBarExternalLink/SideBarExternalLink'
import SideBarLink from '../SideBarLink/SideBarLink'
import SideBarTitle from '../SideBarTitle/SideBarTitle'

import sideBarLogo from '../../../assets/images/common/segurtempo-logo-s.png'
import './SideBar.scss'

const SideBar = (props) => {
    const draftQuotesAmount = useQuotesAmountByStatus(quoteStatus.DRAFT)
    const newQuotesAmount = useQuotesAmountByStatus(quoteStatus.NEW)
    const inProgressQuotesAmount = useQuotesAmountByStatus(quoteStatus.IN_PROGRESS)
    const closedQuotesAmount = useClosedQuotesAmount()
    const issuedQuotesAmount = useQuotesAmountByStatus(quoteStatus.ISSUED)
    const quotedQuotesAmount = useQuotesAmountByStatus(quoteStatus.QUOTED)
    const rejectedQuotesAmount = useQuotesAmountByStatus(quoteStatus.REJECTED)
    const notAcceptedQuotesAmount = useQuotesAmountByStatus(quoteStatus.NOT_ACCEPTED)
    const userGroups = useUserGroups()

    return (
        <nav className='cb-sidebar'>
            <header>
                <img className='sidebar-logo' src={sideBarLogo} alt='centerbrok-logo' />
            </header>
            <SideBarLink
                link={paths.DASHBOARD}
                name='Inicio'
                icon='home' />
            {
                userGroups.business_department &&
                <SideBarLink
                    link={paths.BROKERAGES}
                    name='Colaboradores'
                    icon='folder open' />
            }
            {
                userGroups.business_department &&
                <SideBarLink
                    link={paths.ACCOUNTS_MANAGEMENT}
                    validForRegex={new RegExp(`^${paths.ACCOUNTS_MANAGEMENT}(/.*)?$`)}
                    name='Gestionar altas'
                    icon='user plus' />
            }
            {
                userGroups.brokers &&
                <SideBarLink
                    link={paths.QUOTE_REQUEST}
                    validForRegex={new RegExp(`^${paths.QUOTE_REQUEST}(/[0-9]+)?$`)}
                    name='Nueva solicitud'
                    icon='plus square' />
            }
            <SideBarTitle
                name='Cotizaciones'
                icon='list' />

            <SideBarLink
                link={paths.QUOTES_SEARCH}
                name='Buscador'
                icon='search' />


            <SideBarLink
                link={paths.QUOTES_STATISTICS}
                name='Estadísticas'
                icon='chart bar' />
            {
                userGroups.business_department &&
                <>
                    <SideBarLink
                        link={paths.ASSIGNED_QUOTES}
                        name='Mis cotizaciones'
                        icon='folder open' />
                </>
            }
            {
                userGroups.brokers &&
                <SideBarLink
                    link={paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'draft')}
                    name='Guardadas'
                    icon='save'
                    label={draftQuotesAmount} />
            }
            {
                userGroups.business_department &&
                <SideBarLink
                    link={
                        userGroups.business_department
                            ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'new')
                            : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'new')
                    }
                    validForRegex={new RegExp(`^${paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'new')}(/[0-9]+)?$`)}
                    name='Nuevas'
                    icon='hourglass start'
                    label={newQuotesAmount} />
            }
            <SideBarLink
                link={
                    userGroups.business_department
                        ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'in-progress')
                        : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'in-progress')
                }
                validForRegex={
                    userGroups.business_department
                        ? new RegExp(`^${paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'in-progress')}(/[0-9]+)?$`)
                        : new RegExp(`^${paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'in-progress')}$`)
                }
                name='En trámite'
                icon='hourglass half'
                label={inProgressQuotesAmount}
                hint={
                    userGroups.brokers
                        ? quoteStatusHints.inProgress
                        : undefined
                } />
            <SideBarLink
                name='Cerradas'
                icon='hourglass end'
                label={closedQuotesAmount}
                hint={
                    userGroups.brokers
                        ? quoteStatusHints.closed
                        : undefined
                } />
            <SideBarLink
                isSecondary
                link={
                    userGroups.business_department
                        ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'issued')
                        : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'issued')
                }
                validForRegex={
                    userGroups.business_department
                        ? new RegExp(`^${paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'issued')}(/[0-9]+)?$`)
                        : new RegExp(`^${paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'issued')}$`)
                }
                name='Emitidas'
                icon='hourglass end'
                label={issuedQuotesAmount} />
            <SideBarLink
                isSecondary
                link={
                    userGroups.business_department
                        ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'quoted')
                        : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'quoted')
                }
                validForRegex={
                    userGroups.business_department
                        ? new RegExp(`^${paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'quoted')}(/[0-9]+)?$`)
                        : new RegExp(`^${paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'quoted')}$`)
                }
                name='Cotizadas'
                icon='hourglass end'
                label={quotedQuotesAmount} />
            <SideBarLink
                isSecondary
                link={
                    userGroups.business_department
                        ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'rejected')
                        : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'rejected')
                }
                validForRegex={
                    userGroups.business_department
                        ? new RegExp(`^${paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'rejected')}(/[0-9]+)?$`)
                        : new RegExp(`^${paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'rejected')}$`)
                }
                name='Rechazadas'
                icon='hourglass end'
                label={rejectedQuotesAmount} />
            <SideBarLink
                isSecondary
                link={
                    userGroups.business_department
                        ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'not-accepted')
                        : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'not-accepted')
                }
                validForRegex={
                    userGroups.business_department
                        ? new RegExp(`^${paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'not-accepted')}(/[0-9]+)?$`)
                        : new RegExp(`^${paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'not-accepted')}$`)
                }
                name='No aceptadas'
                icon='hourglass end'
                label={notAcceptedQuotesAmount} />
                <div className="powered-by">Powered by CenterBrok</div> 
        </nav>
    )
}

export default SideBar