import React, { useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Icon, Label } from 'semantic-ui-react'

import './SideBarLink.scss'

const SideBarLink = (props) => {
    const [isVisibleHint, setVisibleHint] = useState(false)
    const location = useLocation()
    const hintRef = useRef(null)

    const isActive = () => {
        if (props.validForRegex) {
            return props.validForRegex.test(location.pathname)
        }

        return location.pathname === props.link
    }

    const generateClassName = () => {
        let className = 'cb-sidebar-link'

        if (isActive()) {
            className += ' cb-active'
        }

        if (props.isSecondary) {
            className += ' cb-secondary'
        } else if(props.isExternal) {
            className += ' cb-external'
        } else {
            className += ' cb-primary'
        }

        if (props.label !== undefined) {
            className += ' cb-smaller-padding'
        }

        if (props.link === undefined) {
            className += ' cb-no-link'
        }

        return className
    }

    const getActionProps = () => {
        const actionProps = {}

        if (props.hint) {
            actionProps.onMouseEnter = () => setVisibleHint(true)
            actionProps.onMouseLeave = () => setVisibleHint(false)
            actionProps.onMouseMove = (event) => {
                const hint = hintRef?.current

                if (!hint) {
                    return
                }

                hint.style.top = (event.clientY - hint.offsetHeight - 15) + 'px'
                hint.style.left = (event.clientX - 20) + 'px'
            }
        }

        return actionProps
    }

    return (
        <>
            <Link
                {...getActionProps()}
                to={(props.link !== undefined) ? props.link : location.pathname}
                className={generateClassName()}>
                <div className='cb-sidebar-link-content'>
                    <div>
                        <Icon name={props.icon}/>
                        <span>{props.name}</span>
                    </div>
                    {
                        (props.label !== undefined) &&
                        <div>
                            <Label circular className='cb-drafts-amount-label' size={props.isSecondary ? 'tiny' : 'medium'}>
                                {props.label}
                            </Label>
                        </div>
                    }
                </div>
            </Link>
            {
                (props.hint && isVisibleHint) &&
                <span ref={hintRef} className='cb-status-hint'>
                    {props.hint}
                </span>
            }
        </>
    )
}

export default SideBarLink