export const initialState = {
    checkingUpdates: false,
    updateTimerId: null,
    notifications: {},
    quotesAmounts: {},
    accessRequestsAmount: 0,
    newIssueOrders: 0
}

export const PeriodicUpdatesActionTypes = {
    START_CHECK_SCHEDULE: 'START_CHECK_SCHEDULE',
    UPDATES_RECEIVED: 'UPDATES_RECEIVED',
    UPDATE_QUOTES_AMOUNTS: 'UPDATE_QUOTES_AMOUNTS',
    UPDATE_ACCESS_REQUESTS_AMOUNT: 'UPDATE_ACCESS_REQUESTS_AMOUNT',
    UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
    UPDATE_NEW_ISSUE_ORDERS: 'UPDATE_NEW_ISSUE_ORDERS',
    TURNOFF_UPDATES: 'TURNOFF_UPDATES'
}
 
export const PeriodicUpdatesReducer = (initialState, action) => {
    switch (action.type) {
        case PeriodicUpdatesActionTypes.START_CHECK_SCHEDULE:
            return {
                ...initialState,
                checkingUpdates: true
            }
        case PeriodicUpdatesActionTypes.UPDATES_RECEIVED:
            return {
                ...initialState,
                checkingUpdates: false,
                updateTimerId: action.data.updateTimerId
            }
        case PeriodicUpdatesActionTypes.UPDATE_QUOTES_AMOUNTS:
            return {
                ...initialState,
                quotesAmounts: {
                    ...action.data
                }
            }
        case PeriodicUpdatesActionTypes.UPDATE_ACCESS_REQUESTS_AMOUNT:
            return {
                ...initialState,
                accessRequestsAmount: action.data
            }
        case PeriodicUpdatesActionTypes.UPDATE_NOTIFICATIONS:
            return {
                ...initialState,
                notifications: {
                    ...action.data
                }
            }
        case PeriodicUpdatesActionTypes.UPDATE_NEW_ISSUE_ORDERS:
            return {
                ...initialState,
                newIssueOrders: action.data
            }
        case PeriodicUpdatesActionTypes.TURNOFF_UPDATES:
            return {
                ...initialState,
                checkingUpdates: false,
                updateTimerId: null,
                notifications: {},
                quotesAmounts: {},
                accessRequestsAmount: 0,
                newIssueOrders: 0
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}