import React, { useState } from 'react'

import { Link, useHistory } from 'react-router-dom'

import { Button, Icon, Modal, Segment, Transition } from 'semantic-ui-react'

import { paths } from '../../../config/routes'

import {
    resetForm,
    updateValidation,
    useFormDispatch,
    useFormState
} from '../../../contexts/Form'
import {
    fetchUpdatesOnDemand,
    usePeriodicUpdatesState,
    usePeriodicUpdatesDispatch
} from '../../../contexts/PeriodicUpdates'
import { useErrorState } from '../../../contexts/ErrorHandling'

import documentationService from '../../../services/documentationService'
import quotesService from '../../../services/quotesService'

import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'
import QuoteForm from '../../QuoteForm/QuoteForm'

import { isEmptyValue } from '../../../assets/js/utils'

import './RequestForm.scss'

const RequestForm = (props) => {
    const formState = useFormState()
    const dispatch = useFormDispatch()
    const { setErrorDialog } = useErrorState()
    const history = useHistory()

    const [saving, setSaving] = useState(false)
    const [continuing, setContinuing] = useState(false)
    const [cancelModalVisible, setCancelModalVisible] = useState(false)
    const [saveSuccessModalVisible, setSaveSuccessModalVisible] = useState(false)

    const periodicUpdatesDispatch = usePeriodicUpdatesDispatch()
    const periodicUpdatesData = usePeriodicUpdatesState()

    const animationDuration = 300

    const cancelForm = () => {
        resetForm(dispatch)
        props.cancelForm()
    }

    const validateForm = () => {
        updateValidation(dispatch, true)

        let isValidForm = true

        for (let fieldName in formState.requirements) {
            let required = formState.requirements[fieldName]
            let fieldValue = formState.values[fieldName]
            let extraFilter = true

            if (fieldName === 'quoteRequestFilesUpload') {
                // If one or more files have been previously uploaded, the
                // FileField would be valid even if now empty
                extraFilter = !Boolean(fieldValue?.alreadyUploaded?.length)
                fieldValue = fieldValue?.pending
            } else {
                fieldValue = fieldValue?.raw
            }

            if (required && isEmptyValue(fieldValue) && extraFilter) {
                isValidForm = false
                break
            }
        }

        if (isValidForm) {
            return true
        }

        setErrorDialog({
            title: 'Formulario incompleto',
            description: `Hay campos obligatorios sin completar en el formulario.
                          Por favor, introduce la información necesaria antes de
                          enviar la solicitud.`
        })

        return false
    }

    const saveQuote = (origin) => {
        const formData = new FormData()
        const files = formState.values['quoteRequestFilesUpload']
        let data = {...formState.values}

        setSaving(true)

        files?.pending?.forEach(file => (
            formData.append('quote_files', file, file.name)
        ))

        delete data['quoteRequestFilesUpload']

        formData.append('quote_data', JSON.stringify(data))
        formData.append('quote_modality', props.modality.code)

        const saveResult = props.quoteId
                           ? quotesService.updateQuote(props.quoteId, formData)
                           : quotesService.saveQuote(formData)

        return saveResult
                .then(res => {
                    const quoteId = res.data.quote_id

                    fetchUpdatesOnDemand(periodicUpdatesDispatch, periodicUpdatesData.updateTimerId)

                    props.setCreatedQuoteId(quoteId)

                    if (origin === 'save') {
                        setSaving(false)
                        setSaveSuccessModalVisible(true)
                    }

                    return quoteId
                })
                .catch(error => {
                    setSaving(false)

                    let errorMessage = error?.response?.data?.error

                    if (!errorMessage) {
                        errorMessage = 'Ha tenido lugar un error al guardar la solicitud. Por favor, inténtalo de nuevo más tarde.'
                    }

                    setErrorDialog({
                        title: 'Error al guardar',
                        description: errorMessage
                    })
                })
    }

    const continueQuote = () => {
        if (!validateForm()) {
            return
        }

        saveQuote('continue')
            .then(quoteId => {
                setSaving(false)
                setContinuing(true)

                const data = {
                    structure: {
                        modalityName: props.modality.long_name,
                        sections: [...formState.config.sections],
                        values: {...formState.values},
                        visibilities: {...formState.visibilities}
                    }
                }

                props.setFormPdfStructure(data)

                return documentationService.createQuotePdf(quoteId, data)
            })
            .then(res => {
                props.showQuotePdf(res.data.file_url)
                setContinuing(false)
            })
            .catch(error => {
                setSaving(false)
                setContinuing(false)

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al generar la documentación. Por favor, inténtalo de nuevo más tarde.'
                }

                setErrorDialog({
                    title: 'Error generando documentación',
                    description: errorMessage,
                    onConfirm: () => history.push(paths.DASHBOARD)
                })
            })
    }

    return (
        <>
            <Segment attached className='cb-quote-request-form-segment'>
                <Transition.Group animation='fade' duration={animationDuration}>
                    <QuoteForm
                        modality={props.modality}
                        values={props.values}
                        animationDuration={animationDuration}/>
                    {
                        formState.config &&
                        <div className='cb-quote-request-actions'>
                            <Button
                                size='large'
                                className='cb-cancel-quote-request-form'
                                disabled={saving || continuing}
                                onClick={() => setCancelModalVisible(true)}>
                                Cancelar
                            </Button>
                            <Button
                                icon
                                labelPosition='right'
                                size='large'
                                className='cb-save-quote-request-form'
                                disabled={saving || continuing}
                                loading={saving}
                                onClick={() => saveQuote('save')}>
                                Guardar solicitud
                                <Icon name='save outline'/>
                            </Button>
                            <Button
                                icon
                                labelPosition='right'
                                size='large'
                                className='cb-continue-quote-request-form'
                                disabled={saving || continuing}
                                loading={continuing}
                                onClick={continueQuote}>
                                Continuar
                                <Icon name='check'/>
                            </Button>
                        </div>
                    }
                </Transition.Group>
            </Segment>
            <Modal
                open={cancelModalVisible}
                closeIcon={true}
                onClose={() => setCancelModalVisible(false)}
                size='mini'
                dimmer='blurring'
                header='Cancelar solicitud'
                content='¿Seguro que quieres cancelar la solicitud? Cualquier cambio no guardado se perderá.'
                actions={[
                    {
                        key: 'deny',
                        content: 'Cancelar',
                        compact: true,
                        as: PrimaryButton
                    }, {
                        key: 'accept',
                        content: 'Aceptar',
                        compact: true,
                        as: SecondaryButton,
                        onClick: cancelForm
                    }
                ]}
            />
            <Modal open={saveSuccessModalVisible}
                   size='mini'
                   dimmer='blurring'>
                <Modal.Header>
                    <Icon color='green' name='check circle'/>
                    Solicitud guardada
                </Modal.Header>
                <Modal.Content>
                    Los datos de la solicitud se han guardado correctamente.
                </Modal.Content>
                <Modal.Actions>
                    <Link to={paths.DASHBOARD}>
                        <PrimaryButton compact>
                            Aceptar
                        </PrimaryButton>
                    </Link>
                </Modal.Actions>
            </Modal>
            {
                saving &&
                <FullPageLoader text='Guardando datos...'/>
            }
            {
                continuing &&
                <FullPageLoader text='Generando documentación...'/>
            }
        </>
    )
}

export default RequestForm