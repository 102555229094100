import React, { useContext } from 'react'

import { ErrorHandlingContext } from './context'


export function useErrorState() {
    const context = useContext(ErrorHandlingContext)
    
    if (context === undefined) {
        throw new Error('useErrorState must be used within a ErrorHandlingProvider')
    }

    return context
}