import React, { useState } from 'react'
import { Form, Header, Message } from 'semantic-ui-react'

import authService from '../../../services/authService'

import './PasswordResetForm.scss'

const PasswordResetForm = (props) => {
    const [data, setData] = useState({})
    const [errorMessage, setErrorMessage] = useState(null)

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        props.setLoading(true)

        authService.requestPasswordReset(data)
            .then(res => {
                props.setLoading(false)
                setErrorMessage(null)
                props.setRequestSuccessData(res.data)
            })
            .catch(error => {
                props.setLoading(false)

                if (error.response) {
                    setErrorMessage(error.response.data.email)
                }
            })
    }

    return (
        <Form error={errorMessage ? true : false} className='cb-password-reset-form' id='cb-password-reset-form' onSubmit={handleSubmit}>
            <Header size='medium' className='cb-password-reset-form-header' dividing>
                Restablecer contraseña
            </Header>
            <Message
                info
                icon='info circle'
                content='Por favor, introduce tu dirección de correo electrónico.
                         Recibirás un email con instrucciones sobre cómo
                         restablecer tu contraseña.'/>
            <Form.Input
                error={errorMessage ? true : false}
                label='Correo electrónico'
                placeholder='Correo electrónico'
                icon='envelope'
                iconPosition='left'
                name='email'
                onChange={handleChange}/>
            {
                errorMessage &&
                <Message error content={errorMessage}/>
            }
        </Form>
    )
}

export default PasswordResetForm