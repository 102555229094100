import React, { useState } from 'react'

import { Card, Icon, Input, Label, Message } from 'semantic-ui-react'

import OverviewCard from '../OverviewCard/OverviewCard'
import InfoLabel from '../InfoLabel/InfoLabel'

import './OverviewGrid.scss'


const OverviewGrid = (props) => {
    const [searchString, setSearchString] = useState('')

    const handleInputChange = (event, result) => {
        const { value } = result || event.target
        setSearchString(value)
    }

    return (
        <div className='cb-overview-wrapper'>
            {
                (props.searchBar !== false) &&
                <div className='cb-overview-search-bar'>
                    <Input
                        icon='search'
                        iconPosition='left'
                        placeholder='Buscar...'
                        className='cb-search-input'
                        onChange={handleInputChange}/>
                    {
                        props.infoLabel &&
                        <InfoLabel>
                            {props.infoLabel}
                        </InfoLabel>
                    }
                </div>
            }
            {
                (props?.items?.length > 0) &&
                <Card.Group stackable itemsPerRow={5} className='cb-overview-grid'>
                    {
                        props.items.map((item, index) => (
                            (item.title?.toLowerCase().includes(searchString.toLowerCase())
                                || item.content?.toLowerCase().includes(searchString.toLowerCase())) &&
                            <OverviewCard
                                key={index}
                                title={item.title}
                                content={item.content}
                                link={item.link}/>
                        ))
                    }
                </Card.Group>
            }
            {
                ((props?.items?.length === 0) && props?.emptyMessage !== undefined) &&
                <Message info icon size='large' className='cb-empty-message'>
                    <Icon name='info circle'/>
                    <Message.Content>
                        <Message.Header>
                            {props?.emptyMessage?.title || 'Sin resultados'}
                        </Message.Header>
                        <span dangerouslySetInnerHTML={{ __html: props?.emptyMessage?.content || 'No se han encontrado resultados' }}/>
                    </Message.Content>
                </Message>
            }
        </div>
    )
}

export default OverviewGrid