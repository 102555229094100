import { AuthActionTypes } from './reducer'
import authService from '../../services/authService'

// Constants to use at Auth actions
const MARGIN_HOURS_REFRESH_TOKEN = 1
const MINUTES_UNTIL_LOGOUT = 30

// Auxiliar function to calculate the expiration date
function addTokenExpirationDate(rawTokenData) {
    const tokenData = {...rawTokenData}
    const currentDate = new Date()
    currentDate.setSeconds(currentDate.getSeconds() + tokenData.expires_in)

    tokenData.expirationDate = currentDate
    return tokenData
}

// Auxiliar function to calculate the refresh timeout
function calculateRefreshTimeout(tokenData) {
    const currentDate = new Date()
    const refreshDate = new Date(tokenData.expirationDate)
    refreshDate.setHours(refreshDate.getHours() - MARGIN_HOURS_REFRESH_TOKEN)

    const timeout = refreshDate.getTime() - currentDate.getTime()

    return timeout > 0 ? timeout : 0
}

export function login(dispatch, data) {
    const authData = {}

    try {
        dispatch({ type: AuthActionTypes.REQUEST_LOGIN })

        return authService.getNewAccessToken(data)
            .then(res => {
                const tokenData = addTokenExpirationDate(res.data)

                localStorage.setItem('accessToken', JSON.stringify(tokenData))
                authData.accessToken = tokenData

                return authService.getCurrentUser()
            })
            .then(res => {
                authData.currentUser = res.data

                dispatch({ type: AuthActionTypes.LOGIN_SUCCESS, authData })

                return authData
            })
            .catch(() => (
                dispatch({
                    type: AuthActionTypes.LOGIN_ERROR,
                    errorMessage: 'Las credenciales no son correctas'
                })
            ))
    } catch (error) {
        dispatch({ type: AuthActionTypes.LOGIN_ERROR, errorMessage: error })
    }
}

export function recoverUser(dispatch) {
    const authData = {}

    try {
        dispatch({ type: AuthActionTypes.RECOVER_USER })

        return authService.getCurrentUser()
            .then(res => {
                authData.currentUser = res.data

                dispatch({ type: AuthActionTypes.RECOVER_USER_SUCCESS, authData })

                return authData
            })
            .catch(() => (
                dispatch({ type: AuthActionTypes.RECOVER_USER_ERROR })
            ))
    } catch (error) {
        dispatch({ type: AuthActionTypes.RECOVER_USER_ERROR })
    }
}

export function refreshToken(dispatch) {
    const authData = {}

    try {
        return authService.refreshAccessToken()
            .then(res => {
                const tokenData = addTokenExpirationDate(res.data)

                localStorage.setItem('accessToken', JSON.stringify(tokenData))
                authData.accessToken = tokenData

                return authService.getCurrentUser()
            })
            .then(res => {
                authData.currentUser = res.data

                dispatch({ type: AuthActionTypes.REFRESH_TOKEN_SUCCESS, authData })

                return authData
            })
            .catch(() => (
                dispatch({
                    type: AuthActionTypes.REFRESH_TOKEN_ERROR,
                    minutesUntilLogout: MINUTES_UNTIL_LOGOUT
                })
            ))
    } catch (error) {
        dispatch({
            type: AuthActionTypes.REFRESH_TOKEN_ERROR,
            minutesUntilLogout: MINUTES_UNTIL_LOGOUT
        })
    }
}

export function scheduleTokenRefresh(dispatch, previousTimeoutId) {
    const tokenData = JSON.parse(localStorage.getItem('accessToken'))

    clearTimeout(previousTimeoutId)

    try {
        const timeoutAmount = calculateRefreshTimeout(tokenData)
        const timeoutId = setTimeout(() => {
            refreshToken(dispatch).then(res => {
                if (res && res.currentUser) {
                    scheduleTokenRefresh(dispatch)
                } else {
                    scheduleLogout(dispatch)
                }
            })
        }, timeoutAmount)
        dispatch({ type: AuthActionTypes.SCHEDULE_TOKEN_REFRESH_SUCCESS, timeoutId: timeoutId })
    } catch (error) {
        dispatch({ type: AuthActionTypes.SCHEDULE_TOKEN_REFRESH_ERROR })
    }
}

export function scheduleLogout(dispatch) {
    const timeoutAmount = MINUTES_UNTIL_LOGOUT * 60 * 1000
    const timeoutId = setTimeout(() => logout(dispatch), timeoutAmount)
    dispatch({ type: AuthActionTypes.SCHEDULE_LOGOUT_SUCCESS, timeoutId: timeoutId })
}

export function notifiedUserLogout(dispatch) {
    dispatch({ type: AuthActionTypes.NOTIFY_TOKEN_REFRESH_FAILURE })
}
 
export function logout(dispatch, refreshTimeout, logoutTimeout) {
    dispatch({ type: AuthActionTypes.LOGOUT })
    clearTimeout(refreshTimeout)
    clearTimeout(logoutTimeout)
    localStorage.removeItem('accessToken')
}