import React, { useEffect, useState } from 'react'

import { Checkbox } from 'semantic-ui-react'

import {
    deleteReferences,
    updateValue,
    updateVisibility,
    useEnablement,
    useValue,
    useFormState,
    useFormDispatch
} from '../../../../../contexts/Form'

import FieldWrapper from '../FieldWrapper/FieldWrapper'
import InfoTooltip from '../../../InfoTooltip/InfoTooltip'

import './CheckboxField.scss'

const CheckboxField = (props) => {
    const formState = useFormState()
    const dispatch = useFormDispatch()
    const enabled = useEnablement(props.name)
    const checked = useValue(props.name)

    const getBasicProps = () => {
        return {
            name: props.name,
            disabled: !Boolean(enabled),
            checked: checked?.raw || false,
            readOnly: props.readOnly || formState.readOnly,
            className: 'cb-checkbox',
            label: props.label ? props.label : null
        }
    }

    const getTextualValue = (checked) => (
        checked ? 'Sí' : 'No'
    )

    const handleChange = () => {
        const nextState = {
            raw: !checked?.raw,
            textual: getTextualValue(!checked?.raw)
        }
        
        updateValue(dispatch, props.name, nextState)
    }

    useEffect(() => {
        if (checked === undefined) {
            let defaultValue = props.defaultValue

            defaultValue = {
                raw: defaultValue ? defaultValue : false,
                textual: getTextualValue(defaultValue ? defaultValue : false)
            }
            
            updateValue(dispatch, props.name, defaultValue)
        }
    }, [])

    return (
        <FieldWrapper {...props} className={props.className ? `${props.className} cb-flex` : 'cb-flex'}>
            <Checkbox
                {...getBasicProps()}
                onChange={handleChange}/>
            {
                props.info &&
                <InfoTooltip text={props.info}/>
            }
        </FieldWrapper>
    )
}

export default CheckboxField