import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'

import { NewPasswordSegment } from '../../components/PasswordReset'

import loginBackground from '../../assets/images/login/login-background.jpg'
import './NewPassword.scss'

const NewPassword = () => {
    const bodyStyle = `
        body {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.4)),
                url(${loginBackground});
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    `

    return (
        <>
            <Helmet>
                <title>Nueva contraseña - SegurTempo</title>
                <style>
                    {bodyStyle}
                </style>
            </Helmet>
            <div className='cb-new-password-segment-wrapper'>
                <NewPasswordSegment/>
            </div>
        </>
    )
}

export default NewPassword