import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { chartColor } from '../../../assets/constants/quote'

import './QuotesAmountStat.scss'


const QuotesAmountStat = (props) => {
    const history = useHistory()
    const [isVisibleHint, setVisibleHint] = useState(false)
    const hintRef = useRef(null)

    const getActionProps = () => {
        const actionProps = {}

        if (typeof props.onClick === 'function') {
            actionProps.onClick = props.onClick
        } else if (props.link) {
            actionProps.onClick = (() => (
                history.push(props.link)                
            ))
        }

        if (props.hint) {
            actionProps.onMouseEnter = () => setVisibleHint(true)
            actionProps.onMouseLeave = () => setVisibleHint(false)
            actionProps.onMouseMove = (event) => {
                const hint = hintRef?.current

                if (!hint) {
                    return
                }

                hint.style.top = (event.clientY - hint.offsetHeight - 10) + 'px'
                hint.style.left = (event.clientX - (hint.offsetWidth / 2)) + 'px'
            }
        }

        return actionProps
    }

    return (
        <>
            <div {...getActionProps()} className={`cb-quotes-amount-stat${props.className ? ` ${props.className}` : ''}`}>
                <h2 className='cb-stat-title'>
                    {props.title}
                </h2>
                <h1 className='cb-stat-amount'>
                    {props.amount}
                </h1>
            </div>
            {
                (props.hint && isVisibleHint) &&
                <span ref={hintRef} className='cb-status-hint'>
                    {props.hint}
                </span>
            }
        </>
    )
}

export default QuotesAmountStat