import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

import './FullPageLoader.scss'

const FullPageLoader = (props) => {
    return (
        <Dimmer active inverted page>
            <Loader size='large'>
                {props.text ? props.text : null}
            </Loader>
        </Dimmer>
    )
}

export default FullPageLoader