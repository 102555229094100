import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Header } from 'semantic-ui-react'

import { AssignedQuotesTable } from '../../components/AssignedQuotes'

import './AssignedQuotes.scss'

const AssignedQuotes = () => {
    return (
        <>
            <Helmet>
                <title>Mis cotizaciones - SegurTempo</title>
            </Helmet>
            <div className='cb-my-quotes-wrapper'>
                <Header className='cb-page-title'>
                    Mis cotizaciones
                    <Header.Subheader>
                        Gestiona las cotizaciones de las cuales eres técnico
                    </Header.Subheader>
                </Header>
                <AssignedQuotesTable/>
            </div>
        </>
    )
}

export default AssignedQuotes 
