import React from 'react'
import { Button } from 'semantic-ui-react'

import './PrimaryButton.scss'

const PrimaryButton = (props) => {
    return (
        <Button {...props} className={`${props.className ? `${props.className} ` : ''}cb-primary-button`}>
            {props.children}
        </Button>
    )
}

export default PrimaryButton