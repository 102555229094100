import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Header, Message } from 'semantic-ui-react'

import authService from '../../../services/authService'

import './NewPasswordForm.scss'

const NewPasswordForm = (props) => {
    const [data, setData] = useState({})
    const [isVisiblePassword, setPasswordVisibility] = useState(false)
    const [isVisibleRepeatPassword, setRepeatPasswordVisibility] = useState(false)
    const [isError, setIsError] = useState(false)
    const [fieldErrors, setFieldErrors] = useState({})

    const { resetKey } = useParams()

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        props.setLoading(true)

        authService.resetPassword(resetKey, data)
            .then(res => {
                props.setLoading(false)
                props.setRequestSuccess(true)
                setFieldErrors({})
                setIsError(false)
            })
            .catch(error => {
                props.setLoading(false)
                props.setRequestSuccess(false)
                setIsError(true)

                if (error.response) {
                    setFieldErrors(error.response.data)
                }
            })
    }

    return (
        <Form error={isError ? true : false} className='cb-new-password-form' id='cb-new-password-form' onSubmit={handleSubmit}>
            <Header size='medium' className='cb-new-password-form-header' dividing>
                Restablecer contraseña
            </Header>
            <Message
                info
                icon='info circle'
                content='Por favor, introduce una nueva contraseña.'/>
            <Form.Input
                error={fieldErrors.new_password || fieldErrors.non_field_errors ? true : false}
                label='Nueva contraseña'
                placeholder='Nueva contraseña'
                icon='lock'
                iconPosition='left'
                name='new_password'
                type={isVisiblePassword ? 'text' : 'password'}
                action={{
                    icon: 'eye',
                    className: 'cb-show-password-button',
                    type: 'button',
                    onClick: () => setPasswordVisibility(!isVisiblePassword)
                }}
                onChange={handleChange}/>
            <Form.Input
                error={fieldErrors.repeat_password || fieldErrors.non_field_errors ? true : false}
                label='Repetir contraseña'
                placeholder='Repetir contraseña'
                icon='lock'
                iconPosition='left'
                name='repeat_password'
                type={isVisibleRepeatPassword ? 'text' : 'password'}
                action={{
                    icon: 'eye',
                    className: 'cb-show-password-button',
                    type: 'button',
                    onClick: () => setRepeatPasswordVisibility(!isVisibleRepeatPassword)
                }}
                onChange={handleChange}/>
            {
                isError &&
                <Message error>
                    <Message.List>
                    {
                        Object.entries(fieldErrors).map(
                            ([k, v], i) => (
                                <Message.Item key={i}>
                                    {v[0]}
                                </Message.Item>
                            )
                        )
                    }
                    </Message.List>
                </Message>
            }
        </Form>
    )
}

export default NewPasswordForm