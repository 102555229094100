import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Header, Icon, Message } from 'semantic-ui-react'

import { paths } from '../../config/routes'

import { UsersTable } from '../../components/BrokerageUsers'
import FullPageLoader from '../../components/UI/FullPageLoader/FullPageLoader'

import accountsService from '../../services/accountsService'

import './BrokerageUsers.scss'

const BrokerageUsers = () => {
    const { brokerageId } = useParams()
    const history = useHistory()
    const [brokerage, setBrokerage] = useState(null)
    const [brokerageUsers, setBrokerageUsers] = useState(null)

    const refresh = () => (
        accountsService.getBrokerageUsers(brokerageId)
            .then(res => {
                if (!res.data?.users?.length) {
                    history.push(paths.USERS_PER_BROKERAGE)
                } else {
                    setBrokerage(res.data?.brokerage)
                    setBrokerageUsers(res.data?.users)
                }
            })
            .catch(error => {
                if (error.response.status === 404) {
                    history.push(paths.NOT_FOUND)
                }
            })
    )

    useEffect(() => {
        if (!brokerageId || !parseInt(brokerageId)) {
            history.push('/not-found')
        }

        refresh()
    }, [])

    return (
        <>
            <Helmet>
                <title>Altas de colaborador - Segurtempo</title>
            </Helmet>
            <div className='cb-brokerage-users-wrapper'>
                {
                    (!brokerage || !brokerageUsers) &&
                    <FullPageLoader text='Cargando usuarios...'/>
                }
                {
                    brokerage &&
                    <>
                        <Header className='cb-page-title'>
                            Usuarios dados de alta del colaborador <span>{brokerage.name}</span>
                        </Header>
                        <UsersTable users={brokerageUsers} refresh={refresh}/>
                    </>
                }
            </div>
        </>
    )
}

export default BrokerageUsers 
