import React, { useEffect, useState } from 'react'

import { Form, Header, Message } from 'semantic-ui-react'

import { useErrorState } from '../../../contexts/ErrorHandling'

import accountsService from '../../../services/accountsService'
import businessService from '../../../services/businessService'

import './AccessRequestForm.scss'

const AccessRequestForm = (props) => {
    const [data, setData] = useState({})
    const [brokerages, setBrokerages] = useState([])
    const [fieldErrors, setFieldErrors] = useState({})
    const [isError, setIsError] = useState(false)
    const { setErrorDialog } = useErrorState()

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        props.setLoading(true)

        accountsService.createAccessRequest(data)
            .then(res => {
                props.setLoading(false)
                props.setRequestSuccess(true)
                setIsError(false)
                setFieldErrors({})
            })
            .catch(error => {
                props.setLoading(false)
                setIsError(true)

                if (error.response) {
                    setFieldErrors(error.response.data)
                }
            })
    }

    useEffect(() => {
        let isMounted = true

        businessService.getBrokerages()
            .then(res => {
                const options = []

                res.data.forEach(brokerage => {
                    options.push({
                        key: brokerage.id,
                        text: brokerage.name,
                        value: brokerage.id
                    })
                })

                if (isMounted) {
                    setBrokerages(options)
                }
            })
            .catch(() => {
                if (isMounted) {
                    setErrorDialog({
                        title: 'Error al cargar los colaboradores',
                        description: `Ha tenido lugar un error al cargar la lista de colaboradores.
                                      Por favor, inténtalo de nuevo más tarde o contacta con el
                                      administrador de la plataforma.`,
                        onConfirm: props.showLogin
                    })
                }
            })

        return () => { isMounted = false }
    }, [])

    return (
        <Form error={isError} className='cb-access-request-form' id='cb-access-request-form' onSubmit={handleSubmit}>
            <Header size='small' className='cb-access-request-form-header' dividing>
                Completa el formulario para solicitar acceso
            </Header>
            <Form.Input
                error={fieldErrors.name ? true : false}
                label='Nombre'
                placeholder='Nombre'
                icon='user circle'
                iconPosition='left'
                name='name'
                onChange={handleChange}/>
            <Form.Input
                error={fieldErrors.surname ? true : false}
                label='Apellidos'
                placeholder='Apellidos'
                icon='user circle'
                iconPosition='left'
                name='surname'
                onChange={handleChange}/>
            <Form.Select
                search
                error={fieldErrors.brokerage ? true : false}
                label='Colaborador'
                placeholder='Seleccionar colaborador'
                name='brokerage'
                loading={!brokerages.length}
                options={brokerages}
                onChange={handleChange}/>
            <Form.Input
                error={fieldErrors.email ? true : false}
                label='Correo electrónico'
                placeholder='Correo electrónico'
                icon='envelope'
                iconPosition='left'
                name='email'
                onChange={handleChange}/>
            {
                isError &&
                <Message error>
                    <Message.List>
                    {
                        Object.entries(fieldErrors).map(
                            ([k, v], i) => (
                                <Message.Item key={i}>
                                    {v[0]}
                                </Message.Item>
                            )
                        )
                    }
                    </Message.List>
                </Message>
            }
        </Form>
    )
}

export default AccessRequestForm