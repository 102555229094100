import React from 'react'

import { Icon, Popup } from 'semantic-ui-react'

import './InfoTooltip.scss'

const InfoTooltip = (props) => {
    return (
        <Popup
            trigger={<Icon name='info circle' color='grey' className='cb-info-tooltip-icon'/>}
            content={props.text}
            position='top center'
            wide
        />
    )
}

export default InfoTooltip