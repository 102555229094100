import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { AuthProvider } from './contexts/Auth'
import { ErrorHandlingProvider } from './contexts/ErrorHandling'
import { PeriodicUpdatesProvider } from './contexts/PeriodicUpdates'

import './index.css'
import 'semantic-ui-css/semantic.min.css'

ReactDOM.render(
    <Router>

        <HelmetProvider>
            <AuthProvider>
                <ErrorHandlingProvider>
                    <PeriodicUpdatesProvider>
                        <App />
                    </PeriodicUpdatesProvider>
                </ErrorHandlingProvider>
            </AuthProvider>
        </HelmetProvider>

    </Router>,
    document.getElementById('root')
)
