import React, { useState } from 'react'
import { Form, Message } from 'semantic-ui-react'

import authService from '../../../services/authService'

import './UserDataUpdateForm.scss'

const UserDataUpdateForm = (props) => {
    const [data, setData] = useState({
        name: props.userToUpdate.first_name || '',
        surname: props.userToUpdate.last_name || '',
        email: props.userToUpdate.email || ''
    })
    const [isError, setIsError] = useState(false)
    const [fieldErrors, setFieldErrors] = useState({})

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        props.setUpdating(true)

        authService.updateUserData(props.userToUpdate.id, data)
            .then(res => {
                props.setUpdating(false)
                props.onUpdateSuccess()
                setFieldErrors({})
                setIsError(false)
            })
            .catch(error => {
                props.setUpdating(false)
                setIsError(true)

                if (error.response) {
                    setFieldErrors(error.response.data)
                }
            })
    }
    
    return (
        <Form
            error={isError ? true : false}
            className='cb-update-user-data-form'
            id='cb-update-user-data-form'
            onSubmit={handleSubmit}>
            <Form.Input
                error={fieldErrors.name ? true : false}
                label='Nombre'
                placeholder='Nombre'
                icon='user circle'
                iconPosition='left'
                name='name'
                value={data.name}
                onChange={handleChange}/>
            <Form.Input
                error={fieldErrors.surname ? true : false}
                label='Apellidos'
                placeholder='Apellidos'
                icon='user circle'
                iconPosition='left'
                name='surname'
                value={data.surname}
                onChange={handleChange}/>
            <Form.Input
                error={fieldErrors.email ? true : false}
                label='Correo electrónico'
                placeholder='Correo electrónico'
                icon='envelope'
                iconPosition='left'
                name='email'
                value={data.email}
                onChange={handleChange}/>
            {
                isError &&
                <Message error>
                    <Message.List>
                    {
                        Object.entries(fieldErrors).map(
                            ([k, v], i) => (
                                <Message.Item key={i}>
                                    {v[0]}
                                </Message.Item>
                            )
                        )
                    }
                    </Message.List>
                </Message>
            }
        </Form>
    )
}

export default UserDataUpdateForm