import axios from 'axios'

import { axiosInstance } from './serviceConfig'

const accountsEndpoint = '/api/accounts'

const createAccessRequest = data => (
    axiosInstance.post(`${accountsEndpoint}/access-request`, data)
)

const getAccessRequests = pageNumber => (
    axiosInstance.get(`${accountsEndpoint}/access-requests${pageNumber ? `?page=${pageNumber}` : ''}`)
)

const acceptAccessRequest = requestId => (
    axiosInstance.put(`${accountsEndpoint}/access-request/${requestId}`)
)

const rejectAccessRequest = requestId => (
    axiosInstance.delete(`${accountsEndpoint}/access-request/${requestId}`)
)

const getUsersPerBrokerage = () => (
    axiosInstance.get(`${accountsEndpoint}/users-per-brokerage`)
)

const getBrokerageUsers = brokerageId => (
    axiosInstance.get(`${accountsEndpoint}/users-per-brokerage/${brokerageId}`)
)

const exportAccountsXlsx = () => (
    axiosInstance.get(`${accountsEndpoint}/export`, {responseType: 'blob'})
)

const getTechnicians = () => (
    axiosInstance.get(`${accountsEndpoint}/technicians`)
)

export default {
    acceptAccessRequest,
    createAccessRequest,
    getAccessRequests,
    rejectAccessRequest,
    getUsersPerBrokerage,
    getBrokerageUsers,
    exportAccountsXlsx,
    getTechnicians
}