import axios from 'axios'

import { axiosInstance } from './serviceConfig'

const communicationEndpoint = '/api/communication'

const sendQuoteMessage = (quoteId, text) => (
    axiosInstance.post(`${communicationEndpoint}/quote/${quoteId}/send-message`, { text })
)

export default {
    sendQuoteMessage
}