import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Button, Form, Icon, Message, TextArea } from 'semantic-ui-react'

import { useErrorState } from '../../../contexts/ErrorHandling'
import { useUserGroups } from '../../../contexts/Auth'
import quotesService from '../../../services/quotesService'
import communicationService from '../../../services/communicationService'
import * as utils from '../../../assets/js/utils'

import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'

import './QuoteCommunicationPane.scss'

const QuoteCommunicationPane = (props) => {
    const [loadingState, setLoadingState] = useState({ isLoading: false })
    const [isSendingMessage, setSendingMessage] = useState(false)
    const [message, setMessage] = useState('')
    const { setErrorDialog } = useErrorState()
    const userGroups = useUserGroups()
    const fileInputRef = useRef(null)
    const history = useHistory()

    const generateMessages = () => (
        props.quote.messages.map((message, index) => {
            let className = 'cb-message'

            if ((userGroups.brokers && message.sender.brokerage) ||
                (userGroups.business_department && !message.sender.brokerage)) {
                className += ' cb-own-message'
            }
            
            return (
                <div key={index} className={className}>
                    <div className='cb-date'>
                        {utils.datetimeToStr(message.created_at)}
                    </div>
                    <div className='cb-content'>
                        <span className='cb-sender'>
                            {message.sender.first_name} {message.sender.last_name}
                        </span>
                        <span className='cb-text'>
                            {message.text}
                        </span>
                    </div>
                </div>
            )
        })
    )

    const handleFileChange = (addedFiles) => {
        if (!addedFiles.length) {
            return
        }

        setLoadingState({ isLoading: true, message: 'Subiendo archivos...' })

        const newFiles = [...Array.from(addedFiles)]
        const formData = new FormData()

        newFiles.forEach(file => (
            formData.append('quote_files', file, file.name)
        ))

        quotesService.updateQuote(props.quote.id, formData)
            .then(() => {
                setLoadingState({ isLoading: true, message: 'Actualizando información...' })
                return props.refreshQuoteData()
            })
            .then(() => (
                setLoadingState({ isLoading: false })
            ))
            .catch(error => {
                setLoadingState({ isLoading: false })

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al subir ${newFiles.length > 1 ? 'los archivos' : 'el archivo'}. Por favor, inténtalo de nuevo más tarde.`
                }

                setErrorDialog({
                    title: 'Error al subir archivos',
                    description: errorMessage
                })
            })
    }

    const handleFileSelection = (event) => {
        handleFileChange(event.target.files)
    }

    const handleUploadFilesButtonClick = () => {
        document.activeElement.blur()
        fileInputRef?.current?.click()
    }

    const sendMessage = () => {
        document.activeElement.blur()

        if (!message.trim()) {
            return
        }

        setSendingMessage(true)

        communicationService.sendQuoteMessage(props.quote.id, message)
            .then(() => props.refreshQuoteData())
            .then(() => {
                setMessage('')
                setSendingMessage(false)
            })
            .catch(error => {
                setSendingMessage(false)

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al enviar el mensaje. Por favor, inténtalo de nuevo más tarde.`
                }

                setErrorDialog({
                    title: 'Error al enviar mensaje',
                    description: errorMessage
                })
            })
    }

    const refresh = () => {
        document.activeElement.blur()
        
        setLoadingState({ isLoading: true, message: 'Actualizando información...' })

        props.refreshQuoteData()
            .then(() => (
                setLoadingState({ isLoading: false })
            ))
            .catch(error => {
                setLoadingState({ isLoading: false })

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al refrescar la información. Por favor, inténtalo de nuevo más tarde.`
                }

                setErrorDialog({
                    title: 'Error al refrescar',
                    description: errorMessage
                })
            })
    }

    return (
        <>
            {
                loadingState?.isLoading &&
                <FullPageLoader text={loadingState.message || 'Cargando conversación...'}/>
            }
            <div className='cb-quote-communication-wrapper'>
                <div className='cb-quote-communication'>
                    {
                        (props.quote.messages?.length > 0) &&
                        <div className='cb-quote-messages'>
                            {generateMessages()}
                        </div>
                    }
                    {
                        !props.quote.messages?.length &&
                        <div className='cb-empty-message'>
                            Aún no hay mensajes
                        </div>
                    }
                    <div className='cb-message-compose'>
                        <Form>
                            <TextArea
                                placeholder='Escribe tu mensaje...'
                                onInput={(event, data) => setMessage(data.value)}
                                value={message}/>
                        </Form>
                    </div>
                </div>
                <div className='cb-quote-generic-actions'>
                    <div>
                        <Button icon labelPosition='right' disabled={isSendingMessage} onClick={refresh}>
                            <Icon name='refresh'/>
                            Refrescar
                        </Button>
                    </div>
                    <div>
                        <Button icon labelPosition='right' disabled={isSendingMessage} onClick={handleUploadFilesButtonClick}>
                            Subir archivos
                            <Icon name='upload'/>
                        </Button>
                        <Button icon labelPosition='right' disabled={isSendingMessage} loading={isSendingMessage} onClick={sendMessage}>
                            Enviar mensaje
                            <Icon name='envelope'/>
                        </Button>
                    </div>
                </div>
                <input
                    multiple
                    ref={fileInputRef}
                    type='file'
                    onChange={handleFileSelection}/>
            </div>
        </>
    )
}

export default QuoteCommunicationPane