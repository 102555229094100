import React, { useEffect, useState } from 'react'
import { Form, Header, Message } from 'semantic-ui-react'
import { Button, Icon, Label, Modal, Segment, Dropdown, Input } from 'semantic-ui-react'
import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'
import { useErrorState } from '../../../contexts/ErrorHandling'
import businessService from '../../../services/businessService'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import quotesService from '../../../services/quotesService'
import { useUserGroups } from '../../../contexts/Auth'

import './QuoteCompaniesPane.scss'

const QuoteCompaniesPane = (props) => {
    const [loadingState, setLoadingState] = useState({ isLoading: false })
    let [data, setData] = useState({})
    
    const [status, setStatus] = useState([
        {
            key: true,
            text: 'Aceptado',
            value: true
        },
        {
            key: false,
            text: 'Rechazado',
            value: false
        }
    ])
    const { setErrorDialog } = useErrorState()
    
    const userGroups = useUserGroups()

    // Handles companies selector
    const [companiesList, setCompaniesList] = useState([])
    const [selectedCompanyValue, setSelectedCompanyValue] = useState({});

    // Handles search and list
    const [quoteCompanies, setQuoteCompanies] = useState([]);

    // Handles searcher
    const [searchString, setSearchString] = useState('');
    

    /**
     * Indicates if the companies list can be edited.
     * Only busssines dept. & admins.
     */
    const writable = !userGroups.brokers;

    const handleSubmit = event => {
        event.preventDefault()

        if (data.companies) {
            addCompany(data.companies);
        }
    }

    const addCompany = companyIdToAdd => {
        let newCompaniesList = [];
        // Reset selected company
        setSelectedCompanyValue('');

        companiesList.forEach((item, index) => {
            if(item.value == companyIdToAdd) {
                setQuoteCompanies([
                    ...quoteCompanies,
                    item
                ]);
            } else {
                newCompaniesList.push(item);
            }
        });

        setCompaniesList(newCompaniesList);
    }

    const removeCompany = (companyIdToRemove) => {
        let newQuoteCompanies = [];
        let newCompaniesList = [];

        quoteCompanies.forEach((item, index) => {
            
            if(item.value != companyIdToRemove) {
                // Remove from companies datatable
                newQuoteCompanies.push(item);
            } else {
                // Add to selector options
                newCompaniesList = [
                    ...companiesList,
                    item
                ];
                // Order selector options
                newCompaniesList.sort((a, b) => a.value - b.value);
            }
        });
        setQuoteCompanies(newQuoteCompanies);
        setCompaniesList(newCompaniesList);
    }

    const removeQuoteCompany = (company) => {
        document.activeElement.blur();
        setLoadingState({ isLoading: true, message: 'Borrando...' })

        if (company.id) {
            quotesService.deleteQuoteCompany(company.id)
                .then(res => {
                    removeCompany(company.value);
                    setLoadingState({ isLoading: false })
                })
                .catch(error => {
                    setLoadingState({ isLoading: false })

                    let errorMessage = error?.response?.data?.error

                    if (!errorMessage) {
                        errorMessage = 'Ha tenido lugar un error al borrar la compañía. Por favor, inténtalo de nuevo más tarde.'
                    }

                    setErrorDialog({
                        title: 'Error al borrar',
                        description: errorMessage
                    })
                });
        } else {

            removeCompany(company.value);
            setLoadingState({ isLoading: false })
        }

    }

    const saveQuoteCompany = (index, values) => {
        document.activeElement.blur();
        setLoadingState({ isLoading: true, message: 'Actualizando...' })

        const formData = new FormData();
        formData.append('company_id', values.key);
        formData.append('quote_id', values.quote_id);
        formData.append('company_data', JSON.stringify(values));

        if (values.id) {
            formData.append('quotecompanies_id', values.id);

            return quotesService.updateQuoteCompany(formData)
                .then(res => {
                    setLoadingState({ isLoading: false })
                })
                .catch(error => {
                    setLoadingState({ isLoading: false })

                    let errorMessage = error?.response?.data?.error

                    if (!errorMessage) {
                        errorMessage = 'Ha tenido lugar un error al guardar los datos. Por favor, inténtalo de nuevo más tarde.'
                    }

                    setErrorDialog({
                        title: 'Error al actualizar',
                        description: errorMessage
                    })
                })

        } else {
            return quotesService.saveQuoteCompany(formData)
                .then(res => {
                    let newQuoteCompanies = [...quoteCompanies];

                    if (res.data.success) {
                        values.id = res.data.quotecompanies_id;
                        newQuoteCompanies[index] = values;
                        setQuoteCompanies(newQuoteCompanies);
                    }
                    setLoadingState({ isLoading: false })
                })
                .catch(error => {
                    setLoadingState({ isLoading: false })
                    let errorMessage = error?.response?.data?.error;

                    if (!errorMessage) {
                        errorMessage = 'Ha tenido lugar un error al guardar los datos. Por favor, inténtalo de nuevo más tarde.'
                    }

                    setErrorDialog({
                        title: 'Error al guardar',
                        description: errorMessage
                    })
                })
        }
    }

    const handleChange = (event, result) => {

        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })

        setSelectedCompanyValue(value)
    }

    const getQuotesCompanies = (companiesListOptions = []) => {
        quotesService.getQuoteCompanies(props.quote.id)
            .then(res => {
                
                if (res.data.length > 0) {
                    let sQuoteCompanies = [];

                    res.data.forEach((item) => {
                        item.data['id'] = item.id;
                        sQuoteCompanies.push(item.data);

                        companiesListOptions.forEach((company, index) => {
                            if (company.value == item.company_id) {
                                companiesListOptions.splice(index, 1);
                            }
                        });
                    });

                    setQuoteCompanies(sQuoteCompanies);
                    setCompaniesList(companiesListOptions);
                }
            })
            .catch(() => {
                if (isMounted) {
                    setErrorDialog({
                        title: 'Error al cargar las compañías asociadas a la cotización',
                        description: `Ha tenido lugar un error al cargar la lista de compañías asociadas a la cotización. 
                    Por favor inténtalo más tarde`
                    })
                }
            })
    }

    useEffect(() => {
        let isMounted = true

        businessService.getCompanies()
            .then(res => {
                const options = [];

                res.data.forEach(company => {
                    options.push({
                        key: company.id,
                        text: company.name,
                        value: company.id,
                        id: '',
                        company_id: company.id,
                        quote_id: props.quote.id,
                        insurance_premium: '',
                        request_date: '',
                        reception_date: '',
                        rejection: '',
                        comments: '',
                        hide: 'false'
                    })
                })

                if (isMounted) {
                    setCompaniesList(options);
                }

                return options;
            })
            .then((options) => {
                getQuotesCompanies(options)
            })
            .catch(() => {
                if (isMounted) {
                    setErrorDialog({
                        title: 'Error al cargar las compañías',
                        description: `Ha tenido lugar un error al cargar la lista de compañías.
                                      Por favor, inténtalo de nuevo más tarde o contacta con el
                                      administrador de la plataforma.`
                    })
                }
            })

        return () => { isMounted = false }
    }, [])

    const handleCompanyChange = (event, result, fieldName) => {
        let { name, value } = result || event.target
        let index = parseInt(name.split('-')[1]);
        let colName = name.split('-')[0];
        
        if(colName == 'insurance_premium' && value < 0) {
            value = '';
        }

        let newQuoteCompanies = [...quoteCompanies];
        newQuoteCompanies[index][colName] = value;

        setQuoteCompanies(newQuoteCompanies);
    }

    const handleInput = (event, result) => {
        const { value } = result || event.target;
        setSearchString(value.toLowerCase());
    }

    return (
        <>
            {
                loadingState?.isLoading &&
                <FullPageLoader text={loadingState.message || 'Cargando...'} />
            }
            {
                (writable) &&
                <div className='cb-quote-companies-wrapper'>
                    <Form className='cb-companies-form' id='cb-companies-form' onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col select">
                                <Form.Select
                                    search
                                    label='Compañías'
                                    placeholder='Seleccionar compañía'
                                    name='companies'
                                    loading={!companiesList.length}
                                    options={companiesList}
                                    onChange={handleChange} />
                            </div>
                            <div className="col">
                                <PrimaryButton form='cb-companies-form' type='submit' disabled={!selectedCompanyValue}>
                                    Añadir
                                </PrimaryButton>
                            </div>
                        </div>
                    </Form>
                </div>
            }

            <div className='companieslists'>
                <table className="companies-datatable " cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="15%">Compañía
                            </th>
                            <th>Prima</th>
                            <th className={!writable ? 'hidden' : ''}>Solicitud</th>
                            <th className={!writable ? 'hidden' : ''}>Recepción</th>
                            <th>Estado</th>
                            <th className={!writable ? 'hidden' : ''}></th>
                        </tr>
                        <tr className='nopad'>
                            <th><Input
                                autoFocus
                                icon='search'
                                iconPosition='left'
                                placeholder='Buscar...'
                                className='cb-search-input'
                                onChange={handleInput}
                            />
                            </th>
                            <th colSpan={10}></th>
                        </tr>
                    </thead>

                    {
                        (quoteCompanies.length > 0) &&
                        <tbody>
                            {quoteCompanies.map((company, index) => (

                                <tr key={index} className={company.text.toLowerCase().search(searchString) !== -1 ? '' : 'hidden'}>
                                    <td className='company-name'>{company.text}</td>

                                    <td width={'10%'}>
                                        <div className='required field'>
                                            <div className='ui input'>
                                                <input
                                                    placeholder="Prima"
                                                    type="number"
                                                    readOnly={!writable}
                                                    name={'insurance_premium-' + index}
                                                    min={0}
                                                    value={company.insurance_premium}
                                                    onChange={handleCompanyChange}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    
                                    <td width={'10%'}
                                    className={!writable ? 'hidden' : ''}
                                    >
                                        <div className='required field'>
                                            <div className='ui input'>
                                                <input
                                                    type="date"
                                                    name={'request_date-' + index}
                                                    readOnly={!writable}
                                                    value={company.request_date}
                                                    onChange={handleCompanyChange}
                                                />
                                            </div>
                                        </div>
                                    </td>

                                    <td width={'10%'}
                                    className={!writable ? 'hidden' : ''}
                                    >
                                        <div className='required field'>
                                            <div className='ui input'>
                                                <input
                                                    type="date"
                                                    name={'reception_date-' + index}
                                                    readOnly={!writable}
                                                    value={company.reception_date}
                                                    onChange={handleCompanyChange}
                                                />
                                            </div>
                                        </div>
                                    </td>

                                    <td width={'25%'}>
                                        <div className="status-control">
                                            <Dropdown
                                                placeholder='Estado'
                                                name={'rejection-' + index}
                                                onChange={handleCompanyChange}
                                                value={company.rejection}
                                                disabled={!writable}
                                                options={status}
                                            />
                                        </div>

                                        <div className={company.rejection === false ? '' : 'hidden'}>
                                            <textarea
                                                placeholder='Observaciones'
                                                name={'comments-' + index}
                                                onChange={handleCompanyChange}
                                                value={company.comments}
                                                readOnly={!writable}
                                                rows={4} />
                                        </div>
                                    </td>

                                    <td width={'10%'}
                                    className={!writable ? 'hidden' : ''}
                                    >
                                        {
                                            (writable) &&
                                            <div className='actions'>
                                                <div className='delete'>
                                                    <Icon
                                                        name='trash'
                                                        className='delete-icon'
                                                        title="Borrar"
                                                        onClick={() => { removeQuoteCompany(company) }}
                                                    />
                                                </div>

                                                <div className='save'>
                                                    <PrimaryButton type='button'
                                                        onClick={() => { saveQuoteCompany(index, company) }}>
                                                        Guardar
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        }
                                    </td>
                                </tr>

                            ))}

                        </tbody>

                    }

                    {
                        (quoteCompanies.length == 0) &&
                        <tbody>
                            <tr>
                                <td colSpan={10} align="center">No hay compañías añadidas a la cotización</td>
                            </tr>
                        </tbody>
                    }
                </table>
            </div>
        </>
    )
}

export default QuoteCompaniesPane