import React, { useEffect, useState } from 'react'

import { Dropdown } from 'semantic-ui-react'

import {
    deleteReferences,
    updateValue,
    updateVisibility,
    useEnablement,
    useValue,
    useFormState,
    useFormDispatch
} from '../../../../../contexts/Form'

import FieldWrapper from '../FieldWrapper/FieldWrapper'
import InfoTooltip from '../../../InfoTooltip/InfoTooltip'

import './DropdownField.scss'

const DropdownField = (props) => {
    const formState = useFormState()
    const dispatch = useFormDispatch()
    const enabled = useEnablement(props.name)
    const value = useValue(props.name)

    const getTextualOptionsMap = () => {
        const optionsMap = {}

        props.options.forEach(option => {
            optionsMap[option.value] = option.text
        })

        return optionsMap
    }

    const formatOptions = () => {
        const formattedOptions = []

        props.options.forEach(option => {
            formattedOptions.push({
                key: option.value,
                value: option.value,
                text: option.text
            })
        })

        return formattedOptions
    }

    const getBasicProps = () => {
        return {
            placeholder: props.placeholder ? props.placeholder : 'Seleccionar',
            name: props.name,
            disabled: !Boolean(enabled) || Boolean(props.readOnly) || Boolean(formState.readOnly),
            value: value?.raw || '',
            clearable: props.clearable !== false,
            className: `cb-dropdown${props.shrink ? ' cb-half-width' : ''}`,
            options: formatOptions()
        }
    }

    const handleChange = (event, result) => {
        const { value } = result || event.target
        const valueObj = {
            raw: value,
            textual: textualOptions[value] || ''
        }

        updateValue(dispatch, props.name, valueObj);
        if(props.onChange) {
            props.onChange(valueObj);
        }
    }

    const textualOptions = getTextualOptionsMap()

    useEffect(() => {
        if (value === undefined) {
            let defaultValue = props.defaultValue

            if (defaultValue) {
                defaultValue = {
                    raw: defaultValue,
                    textual: textualOptions[defaultValue] || ''
                }
            }
            
            updateValue(dispatch, props.name, defaultValue)
        }
    }, [])

    return (
        <FieldWrapper {...props}>
            {
                props.label &&
                <label>
                    {props.label}
                    {
                        props.info &&
                        <InfoTooltip text={props.info}/>
                    }
                </label>
            }
            <Dropdown
                selection
                onChange={handleChange}
                {...getBasicProps()}/>
        </FieldWrapper>
    )
}

export default DropdownField