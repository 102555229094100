import React from 'react'
import { useHistory } from 'react-router-dom'

import { Helmet } from 'react-helmet-async'

import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton'

import { paths } from '../../config/routes'

import errorImage from '../../assets/images/common/not-found.svg'
import './NotFound.scss'

const NotFound = () => {
    const history = useHistory()

    const goToDashboard = () => {
        history.push(paths.DASHBOARD)
    }

    return (
        <>
            <Helmet>
                <title>No encontrado - SegurTempo</title>
            </Helmet>
            <div className='cb-not-found-wrapper'>
                <img src={errorImage} alt='error'/>
                <div>
                    <h1>¡Vaya!</h1>
                    <h2><i>Parece que la página que buscas no existe</i></h2>
                    <PrimaryButton size='massive' onClick={goToDashboard}>
                        Volver al Inicio
                    </PrimaryButton>
                </div>
            </div>
        </>
    )
}

export default NotFound