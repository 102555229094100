import React from 'react'

import { Icon, Step } from 'semantic-ui-react'

import { requestStep } from '../../../assets/constants/quote'

import './RequestSteps.scss'

const RequestSteps = (props) => {
    const isActiveStep = (stepCode) => props.activeStep === stepCode
    const isDisabledStep = (stepCode) => !isActiveStep(stepCode)

    return (
        <Step.Group attached='top' widths={3} className='cb-request-steps'>
            <Step active={isActiveStep(requestStep.MODALITY)}
                  disabled={isDisabledStep(requestStep.MODALITY)}>
                <Icon name='th list'/>
                <Step.Content>
                    <Step.Title>
                        Modalidad
                    </Step.Title>
                    <Step.Description>
                        Selecciona la modalidad de cotización que quieres tramitar
                    </Step.Description>
                </Step.Content>
            </Step>
            <Step active={isActiveStep(requestStep.FORM)}
                  disabled={isDisabledStep(requestStep.FORM)}>
                <Icon name='file alternate'/>
                <Step.Content>
                    <Step.Title>
                        Formulario
                    </Step.Title>
                    <Step.Description>
                        Completa el formulario con los datos de la solicitud
                    </Step.Description>
                </Step.Content>
            </Step>
            <Step active={isActiveStep(requestStep.CONFIRMATION)}
                  disabled={isDisabledStep(requestStep.CONFIRMATION)}>
                <Icon name='check'/>
                <Step.Content>
                    <Step.Title>
                        Confirmación
                    </Step.Title>
                    <Step.Description>
                        Comprueba los datos para enviar la solicitud
                    </Step.Description>
                </Step.Content>
            </Step>
        </Step.Group>
    )
}

export default RequestSteps