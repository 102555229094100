import React, { useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'
import { Button, Icon, Modal, Segment, Transition } from 'semantic-ui-react'

import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'
import PDFViewer from '../../UI/PDFViewer/PDFViewer'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'

import { useErrorState } from '../../../contexts/ErrorHandling'
import {
    fetchUpdatesOnDemand,
    usePeriodicUpdatesState,
    usePeriodicUpdatesDispatch
} from '../../../contexts/PeriodicUpdates'

import documentationService from '../../../services/documentationService'
import quotesService from '../../../services/quotesService'

import { paths } from '../../../config/routes'

import './RequestConfirmation.scss'

const RequestConfirmation = (props) => {
    const [sendSuccessModalVisible, setSendSuccessModalVisible] = useState(false)
    const [sendingRequest, setSendingRequest] = useState(false)
    const [loadingPdf, setLoadingPdf] = useState(true)
    const { setErrorDialog } = useErrorState()
    const history = useHistory()

    const periodicUpdatesDispatch = usePeriodicUpdatesDispatch()
    const periodicUpdatesData = usePeriodicUpdatesState()

    const animationDuration = 300

    const sendQuote = () => {
        setSendingRequest(true)

        quotesService.sendQuote(props.quoteId)
            .then(() => (
                documentationService.createQuotePdf(props.quoteId, props.formPdfStructure)
            ))
            .then(() => (
                documentationService.createQuoteDocx(props.quoteId, props.formPdfStructure)
            ))
            .then(() => {
                setSendingRequest(false)
                setSendSuccessModalVisible(true)
                fetchUpdatesOnDemand(periodicUpdatesDispatch, periodicUpdatesData.updateTimerId)
            })
            .catch(error => {
                setSendingRequest(false)

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al enviar la solicitud. Por favor, inténtalo de nuevo más tarde.'
                }

                setErrorDialog({
                    title: 'Error enviando solicitud',
                    description: errorMessage,
                    onConfirm: () => history.push(paths.DASHBOARD)
                })
            })
    }

    useEffect(() => {
        setTimeout(() => setLoadingPdf(false), 500)
    }, [])

    return (
        <>
            <Segment attached className='cb-quote-request-confirmation-segment'>
                <Transition.Group animation='fade' duration={animationDuration}>
                    <PDFViewer fileUrl={props.pdfUrl}/>
                    <div className='cb-quote-request-confirmation-actions'>
                        <Button
                            size='large'
                            className='cb-back-to-form'
                            onClick={props.reloadForm}>
                            Volver
                        </Button>
                        <Button
                            icon
                            labelPosition='right'
                            size='large'
                            className='cb-send-quote-request'
                            onClick={sendQuote}>
                            Enviar solicitud
                            <Icon name='envelope outline'/>
                        </Button>
                    </div>
                </Transition.Group>
            </Segment>
            <Modal open={sendSuccessModalVisible}
                   size='mini'
                   dimmer='blurring'>
                <Modal.Header>
                    <Icon color='green' name='check circle'/>
                    Solicitud enviada
                </Modal.Header>
                <Modal.Content>
                    Los datos de la solicitud se han enviado correctamente.
                </Modal.Content>
                <Modal.Actions>
                    <Link to={paths.DASHBOARD}>
                        <PrimaryButton compact>
                            Aceptar
                        </PrimaryButton>
                    </Link>
                </Modal.Actions>
            </Modal>
            {
                loadingPdf &&
                <FullPageLoader text='Cargando PDF...'/>
            }
            {
                sendingRequest &&
                <FullPageLoader text='Enviando solicitud...'/>
            }
        </>
    )
}

export default RequestConfirmation