import axios from 'axios'

import { axiosInstance } from './serviceConfig'

const documentationEndpoint = '/api/documentation'

const createQuotePdf = (quoteId, data) => (
    axiosInstance.post(`${documentationEndpoint}/quote/${quoteId}/pdf`, data)
)

const deleteQuotePdf = quoteId => (
    axiosInstance.delete(`${documentationEndpoint}/quote/${quoteId}/pdf`)
)

const downloadQuotePdf = quoteId => (
    axiosInstance.get(`${documentationEndpoint}/quote/${quoteId}/pdf`)
)

const createQuoteDocx = (quoteId, data) => (
    axiosInstance.post(`${documentationEndpoint}/quote/${quoteId}/docx`, data)
)

const deleteQuoteDocx = quoteId => (
    axiosInstance.delete(`${documentationEndpoint}/quote/${quoteId}/docx`)
)

const downloadQuoteDocx = quoteId => (
    axiosInstance.get(`${documentationEndpoint}/quote/${quoteId}/docx`)
)

const createIssueOrderPdf = (quoteId, data) => (
    axiosInstance.post(`${documentationEndpoint}/quote/${quoteId}/issue-order/pdf`, data)
)

const deleteIssueOrderPdf = quoteId => (
    axiosInstance.delete(`${documentationEndpoint}/quote/${quoteId}/issue-order/pdf`)
)

const downloadIssueOrderPdf = quoteId => (
    axiosInstance.get(`${documentationEndpoint}/quote/${quoteId}/issue-order/pdf`)
)

const createQuotesStatisticsXlsx = filters => (
    axiosInstance.post(`${documentationEndpoint}/quotes/statistics/xlsx`, filters, {responseType: 'blob'})
)

export default {
    createQuotePdf,
    deleteQuotePdf,
    downloadQuotePdf,
    createQuoteDocx,
    deleteQuoteDocx,
    downloadQuoteDocx,
    createIssueOrderPdf,
    deleteIssueOrderPdf,
    downloadIssueOrderPdf,
    createQuotesStatisticsXlsx
}