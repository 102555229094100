import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Button, Header, Icon, Message, Modal, Input, Form } from 'semantic-ui-react'

import { useErrorState } from '../../../contexts/ErrorHandling'
import * as utils from '../../../assets/js/utils'

import documentationService from '../../../services/documentationService'
import quotesService from '../../../services/quotesService'

import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'

import './QuoteFilesPane.scss'

const QuoteFilesPane = (props) => {
    const [loadingState, setLoadingState] = useState({ isLoading: false })
    const [downloadingFiles, setDownloadingFiles] = useState({})
    const [files, setFiles] = useState([])
    const { setErrorDialog } = useErrorState()
    const fileInputRef = useRef(null)
    const history = useHistory()
    const [showCreateFolderName, setShowCreateFolderName] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [currentPath, setCurrentPath] = useState('')
    const [isPrivate, setIsPrivate] = useState(false)

    const downloadFile = (index, file) => {
        setDownloadingFiles({ ...downloadingFiles, [index]: true })
        console.log(file);
        quotesService.downloadUploadedFile(file)
            .then(res => {
                const tempAnchor = document.createElement('a')

                if (file.endsWith('.pdf')) {
                    tempAnchor.setAttribute('rel', 'noopener')
                    tempAnchor.setAttribute('target', '_blank')
                } else {
                    tempAnchor.setAttribute('download', true)
                }

                tempAnchor.setAttribute('href', res.data.file_url)
                tempAnchor.style.display = 'none'

                document.body.appendChild(tempAnchor)
                tempAnchor.click()
                document.body.removeChild(tempAnchor)

                setDownloadingFiles({ ...downloadingFiles, [index]: false })
            })
            .catch(() => {
                setErrorDialog({
                    title: 'Error al descargar archivo',
                    description: `Ha tenido lugar un error al descargar el archivo.
                                  Por favor, inténtalo de nuevo más tarde.`
                })
                setDownloadingFiles({ ...downloadingFiles, [index]: false })
            })
    }

    const generateFileItem = (index, fileData) => {
        const variableProps = {}
        const file = fileData.path ? fileData.path : fileData.name
        const special = Boolean(fileData.important)

        return (
            <div
                className={`cb-file-item${special ? ' cb-highlighted' : ''}`}
                key={index}
                onClick={() => downloadFile(index, file)}>
                {
                    downloadingFiles[index] === true
                        ? <Icon loading name='circle notch' className='cb-loading-icon' />
                        : <Icon {...utils.getFileIconProps(fileData.name)} />
                }
                <Header size='small' className='cb-file-name'>{fileData.name}</Header>
                {
                    !special &&
                    <i className='cb-file-upload-date'>
                        {utils.datetimeToStr(fileData.last_modified)}
                    </i>
                }
            </div>
        )
    }

    const handleChange = (addedFiles) => {
        if (!addedFiles.length) {
            return
        }

        setLoadingState({ isLoading: true, message: 'Subiendo archivos...' })

        const newFiles = [...Array.from(addedFiles)]
        const formData = new FormData()
        const filesDataName = props.isPrivate ? 'quote_private_files' : 'quote_files'
        formData.append('subfolder', currentPath);

        newFiles.forEach(file => (
            formData.append(filesDataName, file, file.name)
        ))

        quotesService.uploadPrivateFile(props.quote.id, formData)
            .then(res => {
                setLoadingState({ isLoading: true, message: 'Subiendo archivos...' })
                //return props.refreshQuoteData()
            })
            .then(() => (
                reloadGallery(currentPath)
            ))
            .catch(error => {
                setLoadingState({ isLoading: false })

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al subir ${newFiles.length > 1 ? 'los archivos' : 'el archivo'}. Por favor, inténtalo de nuevo más tarde.`
                }

                setErrorDialog({
                    title: 'Error al subir archivos',
                    description: errorMessage
                })
            })
    }

    const handleFileSelection = (event) => {
        handleChange(event.target.files)
    }

    const handleUploadFilesButtonClick = () => {
        document.activeElement.blur()
        fileInputRef?.current?.click()
    }

    const handleNewFolderButtonClick = () => {
        setShowCreateFolderName(true);
    }

    const createFolder = () => {
        setLoadingState({ isLoading: true, message: 'Creando carpeta...' })
        quotesService.createQuoteFolder(props.quote.id, folderName)
            .then((res) => {
                setShowCreateFolderName(false);
                reloadGallery();
            })
            .catch(() => {
                setLoadingState({ isLoading: false })
                setErrorDialog({
                    title: 'Error al crear la carpeta',
                    description: ``
                })
            })
    }

    useEffect(() => {
        reloadGallery();
    }, [])

    const reloadGallery = (initialPath = '') => {

        setLoadingState({ isLoading: true, message: 'Cargando archivos...' })

        quotesService.getQuoteFiles(props.quote.id, props.isPrivate, {
            path: initialPath ? initialPath : ''
        })
            .then(res => {
                setFiles(res.data);
                setLoadingState({ isLoading: false })
            })
            .catch(() => {
                setLoadingState({ isLoading: false })
                setErrorDialog({
                    title: 'Error al cargar los archivos asociados a la cotización',
                    description: ``
                })
            })
    }

    const navigateToFolder = (file) => {
        setCurrentPath(file.name);
        reloadGallery(file.name);
    }

    const navigateToRoot = () => {
        setCurrentPath('')
        reloadGallery('');
    }

    return (
        <>
            {
                loadingState?.isLoading &&
                <FullPageLoader text={loadingState.message || 'Cargando archivos...'} />
            }
            <div className='cb-quote-files-wrapper'>
                <div className='cb-quote-files-toolbar'>
            
                    <div className='buttons'>
                        <Button icon labelPosition='right' onClick={handleUploadFilesButtonClick}>
                            Subir archivos
                            <Icon name='upload' />
                        </Button>
                        <input
                            multiple
                            ref={fileInputRef}
                            type='file'
                            onChange={handleFileSelection} />
                        {
                            (props.isPrivate && !currentPath ) &&
                            <Button icon labelPosition='right' onClick={handleNewFolderButtonClick}>
                                Nueva carpeta
                                <Icon name='folder' />
                            </Button>
                        }

                    </div>
                </div>
                <div className='cb-quote-files-datatable'>
                    <table>
                        <thead>
                            <tr>
                                <th width="5%" className='text-center'></th>
                                <th>Nombre</th>
                                <th width="20%">Modificado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (currentPath  != '') &&
                                <tr
                                onClick={navigateToRoot}
                                >
                                    <td>
                                        <i className="level up alternate icon"></i>

                                    </td>
                                    <td colSpan={10}>..</td>
                                </tr>
                            }
                            {
                                (files.length > 0) &&
                                files.map((file, index) => (
                                    (file.name != currentPath) &&
                                    <tr
                                        key={index}
                                        onClick={() => {
                                            if (file.is_folder)
                                                navigateToFolder(file)
                                            else
                                                downloadFile(index, file.path)
                                        }}>
                                        <td className='text-center'>
                                            <Icon {...utils.getFileIconProps(file.name)} />
                                        </td>
                                        <td>
                                            {file.name}
                                            {
                                                downloadingFiles[index] === true
                                                    ? <Icon loading name='circle notch' className='cb-loading-icon' />
                                                    : ''
                                            }
                                        </td>
                                        <td>{utils.datetimeToStr(file.last_modified)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                {
                    /*    
                    <div className='cb-quote-files-gallery'>
                        {
                            (files.length > 0) &&
                            files.map((file, index) => (
                                generateFileItem(index, file)
                            ))
                        }
                        {
                            (files.length === 0) &&
                            <Message info icon size='large' className='cb-empty-message'>
                                <Icon name='info circle' />
                                <Message.Content>
                                    <Message.Header>
                                        Todavía no hay archivos
                                    </Message.Header>
                                    Actualmente no hay ningún archivo
                                    {
                                        props.isPrivate &&
                                        <> privado </>
                                    }
                                    <> en esta cotización</>
                                </Message.Content>
                            </Message>
                        }
                    </div>
                    */
                }
            </div>
            <Modal open={showCreateFolderName}
                size='tiny'
                dimmer='blurring'>
                <Modal.Header>
                    Nueva carpeta
                </Modal.Header>
                <Modal.Content>

                    <Form>
                        <Form.Field>
                            <label>Nombre de la carpeta</label>
                            <Input
                                placeholder='Nombre...'
                                name='folderName'
                                onInput={(event, data) => {
                                    const { name, value } = data || event.target
                                    setFolderName(value)
                                }}
                            />
                        </Form.Field>

                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton
                        compact
                        onClick={() => setShowCreateFolderName(false)}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton
                        compact
                        onClick={createFolder}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default QuoteFilesPane