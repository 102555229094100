import React from 'react'

import { SideBar } from '../components/SideBar'
import { UserInfoWidget } from '../components/UserInfo'
import NotificationsWidget from '../components/NotificationsWidget/NotificationsWidget'

const LoggedPage = (props) => {
    return (
        <>
            <SideBar/>
            <div className='cb-main-content-wrapper' id='cb-main-content'>
                <div className='cb-top-widgets'>
                    <NotificationsWidget/>
                    <UserInfoWidget/>
                </div>
                {props.children}
            </div>
        </>
    )
}

export default LoggedPage