import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Header } from 'semantic-ui-react'

import { paths } from '../../config/routes'

import FullPageLoader from '../../components/UI/FullPageLoader/FullPageLoader'
import OverviewGrid from '../../components/UI/OverviewGrid/OverviewGrid'

import accountsService from '../../services/accountsService'

import './UsersPerBrokerage.scss'

const UsersPerBrokerage = () => {
    const [brokeragesUsers, setBrokeragesUsers] = useState(null)

    useEffect(() => {
        let isMounted = true

        accountsService.getUsersPerBrokerage()
            .then(res => {
                const brokerages = []

                res.data?.forEach(brokerage => {
                    const item = {
                        title: brokerage.name,
                        content: `${brokerage.users_amount} usuario${(brokerage.users_amount !== 1) ? 's' : ''}`,
                        link: paths.BROKERAGE_USERS.replace(':brokerageId', brokerage.id)
                    }

                    brokerages.push(item)
                })
                
                if (isMounted) {
                    setBrokeragesUsers(brokerages)
                }
            })

        return () => { isMounted = false }
    }, [])

    return (
        <>
            <Helmet>
                <title>Altas tramitadas - SegurTempo</title>
            </Helmet>
            <div className='cb-users-per-brokerage-wrapper'>
                <Header className='cb-page-title'>
                    Vista previa de todas las altas tramitadas
                    <Header.Subheader>
                        Puedes seleccionar un colaborador para acceder a más información
                    </Header.Subheader>
                </Header>
                {
                    !brokeragesUsers &&
                    <FullPageLoader text='Cargando...'/>
                }
                {
                    brokeragesUsers &&
                    <OverviewGrid
                        items={brokeragesUsers}
                        emptyMessage={{
                            title: 'No hay usuarios registrados',
                            content: 'Actualmente no hay ningún usuario registrado'
                        }}/>
                }
            </div>
        </>
    )
}

export default UsersPerBrokerage 
