import React, { useState } from 'react'

import { Accordion, Checkbox, Divider, Dropdown, Form, Header, Icon, Input } from 'semantic-ui-react'

import { useErrorState } from '../../../contexts/ErrorHandling'

import documentationService from '../../../services/documentationService'

import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'
import InfoTooltip from '../../UI/InfoTooltip/InfoTooltip'

import './QuotesStatisticsForm.scss'
import { useUserGroups } from '../../../contexts/Auth'

const QuotesStatisticsForm = (props) => {
    const userGroups = useUserGroups()
    const [accordionIndex, setAccordionIndex] = useState(null)
    const [loadingStatus, setLoadingStatus] = useState(null)
    const [data, setData] = useState({})
    const { setErrorDialog } = useErrorState()
    const isBroker = userGroups.brokers;


    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        
        if(userGroups.brokers) {
            data.isBroker  = true;
        }

        setLoadingStatus({
            isLoading: true,
            message: 'Generando informe...'
        })

        documentationService.createQuotesStatisticsXlsx(data)
            .then(res => {
                const filename = 'Informe de estadísticas.xlsx'
                const blob = res.data

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename)
                } else {
                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }

                setLoadingStatus({ isLoading: false })
            })
            .catch(() => {
                setLoadingStatus({ isLoading: false })

                setErrorDialog({
                    title: 'Error al generar el informe',
                    description: `Ha tenido lugar un error al generar el informe de estadísticas.
                                  Por favor, inténtalo de nuevo más tarde o contacta con el
                                  administrador de la plataforma.`
                })
            })
    }

    const handleAccordionSelection = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = (accordionIndex === index) ? -1 : index
        setAccordionIndex(newIndex)

        const newData = { ...data }

        delete newData.brokerages
        delete newData.modalities
        delete newData.statuses
        delete newData.technicians
        delete newData.onlyEfficiency
        delete newData.companies
        delete newData.companiesGlobalStats
        delete newData.companiesDetailedStats
        delete newData.users
        newData.isBroker = isBroker

        if (newIndex === 1) {
            newData.modalities = []
        } else if (newIndex === 4) {
            newData.onlyEfficiency = true
        } else if (newIndex === 5) {
            newData.companiesGlobalStats = true
        } else if (newIndex === 6) {
            newData.companiesDetailedStats = true
        }

        setData(newData)
    }

    return (
        <>
            <Form className='cb-quote-statistics-form' id='cb-quote-statistics-form' onSubmit={handleSubmit}>
                <Form.Group widths={3} className='cb-common-fields'>
                    <Form.Field>
                        <label>Desde fecha</label>
                        <Input
                            placeholder='Desde fecha'
                            name='fromDate'
                            type='date'
                            onChange={handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Hasta fecha</label>
                        <Input
                            placeholder='Hasta fecha'
                            name='untilDate'
                            type='date'
                            onChange={handleChange} />
                    </Form.Field>
                </Form.Group>
                <Divider hidden />
                <Divider />
                <Header className='cb-dark-grey cb-statistics-header'>
                    Estadística a calcular
                    <InfoTooltip text='No seleccionar ninguna para obtener un listado completo de cotizaciones' />
                </Header>
                <Accordion fluid styled>

                    {
                        isBroker &&
                        <>
                            <Accordion.Title
                                active={accordionIndex === 0}
                                index={0}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 0} />
                                Situación de las solicitudes según el estado en el que se encuentran
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 0}>
                                <Form.Field>
                                    <label>
                                        Elegir estados
                                        <InfoTooltip text='Dejar vacío para calcular con todos los estados' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Estados'
                                        name='statuses'
                                        onChange={handleChange}
                                        value={data?.statuses || []}
                                        options={props.options.statuses} />
                                </Form.Field>

                                <Form.Field>
                                    <label>
                                        Elegir usuarios
                                        <InfoTooltip text='Dejar vacío para calcular con todos los usuarios' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Usuarios'
                                        name='users'
                                        onChange={handleChange}
                                        value={data?.users || []}
                                        options={props.options.users} />
                                </Form.Field>
                            </Accordion.Content>

                            <Accordion.Title
                                active={accordionIndex === 2}
                                index={2}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 2} />
                                Listado de solicitudes por usuario del colaborador
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 2}>
                                <Form.Field>
                                    <label>
                                        Elegir usuarios
                                        <InfoTooltip text='Dejar vacío para calcular con todos los usuarios' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Usuarios'
                                        name='users'
                                        onChange={handleChange}
                                        value={data?.users || []}
                                        options={props.options.users} />
                                </Form.Field>
                            </Accordion.Content>

                            <Accordion.Title
                                active={accordionIndex === 4}
                                index={4}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 4} />
                                Efectividad del usuario del colaborador
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 4}>
                                <Form.Field>
                                    <label>
                                        Elegir usuario
                                        <InfoTooltip text='Dejar vacío para calcular con todos los usuarios' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Usuarios'
                                        name='users'
                                        onChange={handleChange}
                                        value={data?.users || []}
                                        options={props.options.users} />
                                </Form.Field>
                            </Accordion.Content>
                        </>
                    }

                    {
                        !isBroker &&
                        <>
                            <Accordion.Title
                                active={accordionIndex === 0}
                                index={0}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 0} />
                                Situación de las solicitudes según el estado en el que se encuentran
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 0}>
                                <Form.Field>
                                    <label>
                                        Elegir estados
                                        <InfoTooltip text='Dejar vacío para calcular con todos los estados' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Estados'
                                        name='statuses'
                                        onChange={handleChange}
                                        value={data?.statuses || []}
                                        options={props.options.statuses} />
                                </Form.Field>
                            </Accordion.Content>

                            <Accordion.Title
                                active={accordionIndex === 1}
                                index={1}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 1} />
                                Listado de solicitudes según el ramo
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 1}>
                                <Form.Field>
                                    <label>
                                        Elegir ramos
                                        <InfoTooltip text='Dejar vacío para calcular con todos los ramos' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Ramos'
                                        name='modalities'
                                        onChange={handleChange}
                                        value={data?.modalities || []}
                                        options={props.options.modalities} />
                                </Form.Field>
                            </Accordion.Content>


                            <Accordion.Title
                                active={accordionIndex === 2}
                                index={2}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 2} />
                                Listado de solicitudes por técnico
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 2}>
                                <Form.Field>
                                    <label>
                                        Elegir técnicos
                                        <InfoTooltip text='Dejar vacío para calcular con todos los técnicos' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Técnicos'
                                        name='technicians'
                                        onChange={handleChange}
                                        value={data?.technicians || []}
                                        options={props.options.technicians} />
                                </Form.Field>
                            </Accordion.Content>


                            <Accordion.Title
                                active={accordionIndex === 3}
                                index={3}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 3} />
                                Listado de solicitudes por colaborador
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 3}>
                                <Form.Field>
                                    <label>
                                        Elegir colaboradores
                                        <InfoTooltip text='Dejar vacío para calcular con todas los colaboradores' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Colaboradores'
                                        name='brokerages'
                                        onChange={handleChange}
                                        value={data?.brokerages || []}
                                        options={props.options.brokerages} />
                                </Form.Field>
                            </Accordion.Content>

                            <Accordion.Title
                                active={accordionIndex === 4}
                                index={4}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 4} />
                                Efectividad de colaboradores
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 4}>
                                <Form.Field>
                                    <label>
                                        Elegir colaboradores
                                        <InfoTooltip text='Dejar vacío para calcular con todas los colaboradores' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Colaboradores'
                                        name='brokerages'
                                        onChange={handleChange}
                                        value={data?.brokerages || []}
                                        options={props.options.brokerages} />
                                </Form.Field>
                            </Accordion.Content>

                            <Accordion.Title
                                active={accordionIndex === 5}
                                index={5}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 5} />
                                Estadística global de compañías
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 5}>
                                <Form.Field>
                                    <label>
                                        Elegir compañías
                                        <InfoTooltip text='Dejar vacío para calcular con todos los colaboradores' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Compañías'
                                        name='companies'
                                        onChange={handleChange}
                                        value={data?.companies || []}
                                        options={props.options.companies} />
                                </Form.Field>
                            </Accordion.Content>


                            <Accordion.Title
                                active={accordionIndex === 6}
                                index={6}
                                className='cb-dark-grey'
                                onClick={handleAccordionSelection}>
                                <Checkbox checked={accordionIndex === 6} />
                                Estadística detallada de compañías
                            </Accordion.Title>
                            <Accordion.Content active={accordionIndex === 6}>
                                <Form.Field>
                                    <label>
                                        Elegir compañías
                                        <InfoTooltip text='Dejar vacío para calcular con todas las compañías' />
                                    </label>
                                    <Dropdown
                                        fluid
                                        multiple
                                        search
                                        selection
                                        placeholder='Compañías'
                                        name='companies'
                                        onChange={handleChange}
                                        value={data?.companies || []}
                                        options={props.options.companies} />
                                </Form.Field>
                            </Accordion.Content>
                        </>
                    }

                </Accordion>
            </Form>
            {
                loadingStatus?.isLoading &&
                <FullPageLoader text={loadingStatus?.message || 'Cargando...'} />
            }
        </>
    )
}

export default QuotesStatisticsForm