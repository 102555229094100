import React, { useEffect, useState } from 'react'

import { Table } from 'semantic-ui-react'

import { useFormState } from '../../../../../contexts/Form'

import * as fieldTypes from '../../'

import './CatalogDataTable.scss'


const CatalogDataTable = (props) => {
    const formState = useFormState()

    const generateColumn = (column, index) => {
        return (
            <Table.HeaderCell key={index}>
                {column}
            </Table.HeaderCell>
        )
    }

    const generateRow = (row, index) => {
        return (
            <Table.Row key={index}>
                {
                    row.content.map((cell, index) => (
                        generateCell(cell, index)
                    ))
                }
            </Table.Row>
        )
    }

    const generateCell = (cell, index) => {
        const CellFieldType = fieldTypes[cell.fieldType]

        return (
            <Table.Cell key={index}>
                {
                    !cell.isField &&
                    cell.data
                }
                {
                    cell.isField &&
                    <CellFieldType
                        {...cell.data}/>
                }
            </Table.Cell>
        )
    }

    return (
        <Table celled className='cb-catalog-data-table'>
            <Table.Header>
                <Table.Row>
                    {
                        props.columns.map((column, index) => (
                            generateColumn(column, index)
                        ))
                    }
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    props.rows.map((row, index) => (
                        generateRow(row, index)
                    ))
                }
            </Table.Body>
        </Table>
    )
}

export default CatalogDataTable