import { FormActionTypes } from './reducer'


export function initializeForm(dispatch, modality, config, values, hiddenFields, readOnly) {
    dispatch({
        type: FormActionTypes.INIT_FORM,
        data: {
            modality: modality,
            config: {...config},
            values: {...values},
            hiddenFields: [...hiddenFields],
            readOnly
        }
    })
}

export function resetForm(dispatch) {
    dispatch({
        type: FormActionTypes.RESET_FORM
    })
}

export function updateValue(dispatch, name, value) {
    dispatch({
        type: FormActionTypes.UPDATE_VALUE,
        data: { name, value }
    })
}

export function updateEnablement(dispatch, name, enabled) {
    dispatch({
        type: FormActionTypes.UPDATE_ENABLEMENT,
        data: { name, enabled }
    })
}

export function updateRequirement(dispatch, name, required) {
    dispatch({
        type: FormActionTypes.UPDATE_REQUIREMENT,
        data: { name, required }
    })
}

export function updateVisibility(dispatch, name, visible) {
    dispatch({
        type: FormActionTypes.UPDATE_VISIBILITY,
        data: { name, visible }
    })
}

export function deleteReferences(dispatch, name) {
    dispatch({
        type: FormActionTypes.DELETE_REFERENCES,
        data: { name }
    })
}

export function updateValidation(dispatch, validated) {
    dispatch({
        type: FormActionTypes.UPDATE_VALIDATION,
        data: { validated }
    })
}