import { PeriodicUpdatesActionTypes } from './reducer'

import notificationsService from '../../services/notificationsService'

const UPDATE_INTERVAL = 10000  // 10 seconds


/******************************************
 * PERIODIC UPDATE FUNCTIONS
 ******************************************/
function runPeriodicUpdate(dispatch, isFirst = false) {
    updateData(dispatch, isFirst)
        .finally(() => {
            const updateTimerId = setTimeout(() => {
                runPeriodicUpdate(dispatch)
            }, UPDATE_INTERVAL)

            dispatch({
                type: PeriodicUpdatesActionTypes.UPDATES_RECEIVED,
                data: { updateTimerId }
            })
        })
}


/******************************************
 * ACTIONS
 ******************************************/
export function startPeriodicUpdates(dispatch) {
    dispatch({
        type: PeriodicUpdatesActionTypes.START_CHECK_SCHEDULE
    })

    runPeriodicUpdate(dispatch, true)
}

export function updateData(dispatch, isFirst) {
    const promise = isFirst
                    ? notificationsService.initialize()
                    : notificationsService.getUpdates()

    return promise
            .then(res => {
                if (res.status === 204) {
                    dispatch({
                        type: PeriodicUpdatesActionTypes.UPDATE_NEW_ISSUE_ORDERS,
                        data: 0
                    })
                    return
                }

                if (res.data.quote_requests) {
                    dispatch({
                        type: PeriodicUpdatesActionTypes.UPDATE_QUOTES_AMOUNTS,
                        data: res.data.quote_requests
                    })
                }

                if (res.data.access_requests >= 0) {
                    dispatch({
                        type: PeriodicUpdatesActionTypes.UPDATE_ACCESS_REQUESTS_AMOUNT,
                        data: res.data.access_requests
                    })
                }

                if (res.data.notifications) {
                    dispatch({
                        type: PeriodicUpdatesActionTypes.UPDATE_NOTIFICATIONS,
                        data: res.data.notifications
                    })
                }

                if (res.data.new_issue_orders) {
                    dispatch({
                        type: PeriodicUpdatesActionTypes.UPDATE_NEW_ISSUE_ORDERS,
                        data: res.data.new_issue_orders
                    })
                }
            })
}

export function fetchUpdatesOnDemand(dispatch, updateTimerId) {
    clearTimeout(updateTimerId)
    runPeriodicUpdate(dispatch, true)
}

export function getNotificationsPage(dispatch, pageNumber) {
    notificationsService.getNotificationsPage(pageNumber)
        .then(res => {
            dispatch({
                type: PeriodicUpdatesActionTypes.UPDATE_NOTIFICATIONS,
                data: res.data
            })
        })}

export function resetNewIssueOrders(dispatch) {
    dispatch({
        type: PeriodicUpdatesActionTypes.UPDATE_NEW_ISSUE_ORDERS,
        data: 0
    })
}

export function turnoffUpdates(dispatch, updateTimerId) {
    clearTimeout(updateTimerId)

    dispatch({
        type: PeriodicUpdatesActionTypes.TURNOFF_UPDATES
    })
}