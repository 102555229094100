import React, { useEffect, useState } from 'react'

import { Button, Form, Icon, Table } from 'semantic-ui-react'

import * as fieldTypes from '../../'

import {
    useFormState
} from '../../../../../contexts/Form'

import './DynamicDataTable.scss'


const DynamicDataTable = (props) => {
    const getCurrentTableFieldsNames = () => {
        const formFields = Object.keys(formState.values)
        const existingTableFields = []

        props.columns.forEach(column => {
            const result = formFields.filter(formField => formField.startsWith(`${column.name}-`))
            existingTableFields.push(...result)
        })

        return existingTableFields
    }

    const calculateRowsAmount = () => {
        const existingTableFields = getCurrentTableFieldsNames()
        let maxIndex = -1

        existingTableFields.forEach(fieldName => {
            let index = parseInt(fieldName.split('-').pop())

            if (index > maxIndex) {
                maxIndex = index
            }
        })

        return maxIndex + 1
    }

    const generateGroupingHeaderRow = () => {
        if (!props.columnGroups?.length) {
            return null
        }

        return (
            <Table.Row>
                {
                    props.columnGroups.map((group, index) => (
                        <Table.HeaderCell key={index} colSpan={group.size}>
                            {group.title}
                        </Table.HeaderCell>
                    ))
                }
                {
                    <Table.HeaderCell key={'deletion'}></Table.HeaderCell>
                }
            </Table.Row>
        )
    }

    const generateFieldsHeaderRow = () => {
        return (
            <Table.Row>
                {
                    props.columns.map(column => (
                        <Table.HeaderCell key={column.name}>
                            {column.title}
                        </Table.HeaderCell>
                    ))
                }
                {
                    <Table.HeaderCell key={'actions'} className='deleteCol'>
                    </Table.HeaderCell>
                }
            </Table.Row>
        )
    }

    const generateRow = (rowNumber) => {

        const row = (
            <Table.Row key={rowNumber}>
                {
                    props.columns.map(column => {
                        const FieldComponent = fieldTypes[column.fieldType]
                        const fieldName = `${column.name}-${rowNumber}`
                        const fieldProps = {
                            ...column.data,
                            name: fieldName
                        }
                        return (
                            <Table.Cell key={column.name}>
                                <FieldComponent
                                    {...fieldProps} />
                            </Table.Cell>
                        )
                    })
                }
                {
                    <Table.Cell key={`delete-${rowNumber}`} >
                        <Button
                            attached='bottom'
                            className='cb-add-row-button'
                            animated='fade'
                            onClick={() => { deleteRow(rowNumber) }}>
                            <Button.Content visible>
                                Borrar
                            </Button.Content>
                            <Button.Content hidden>
                                <Icon name='trash' />
                            </Button.Content>
                        </Button>
                    </Table.Cell>
                }
            </Table.Row>
        )

        return row
    }

    const generateTableBody = () => {
        if (!useStoredRows) {
            rows = []

            for (let i = 0; i < rowsAmount; i++) {
                rows.push(generateRow(i))
            }
        }

        return (
            <Table.Body>
                {rows}
            </Table.Body>
        )
    }

    const addRow = (event) => {
        setRowsAmount(rowsAmount + 1)

        /* If we do not remove the focus from the button,
           the 'plus' icon remains, so we remove the focus
           manually after clicked */
        document.activeElement.blur()
    }

    const deleteRow = (rowIndex) => {
        useStoredRows = true;
        rows.splice(rowIndex, 1);
        setRowsAmount(rowsAmount - 1)
    }

    let rows = []
    let useStoredRows = false;
    const formState = useFormState()
    const [rowsAmount, setRowsAmount] = useState(calculateRowsAmount())

    return (
        <>
            {
                props.label &&
                <Form.Field className='cb-marginless-bottom'>
                    <label>{props.label}</label>
                </Form.Field>
            }
            <Table
                celled
                attached={!formState.readOnly ? 'top' : undefined}
                className={`cb-dynamic-data-table${props.label ? ' cb-marginless-top' : ''}`}>
                <Table.Header>
                    {generateGroupingHeaderRow()}
                    {generateFieldsHeaderRow()}
                </Table.Header>
                {generateTableBody()}
            </Table>
            {
                !formState.readOnly &&
                <Button
                    attached='bottom'
                    className='cb-add-row-button'
                    animated='fade'
                    onClick={addRow}>
                    <Button.Content visible>
                        Añadir
                    </Button.Content>
                    <Button.Content hidden>
                        <Icon name='plus' />
                    </Button.Content>
                </Button>
            }
        </>
    )
}

export default DynamicDataTable