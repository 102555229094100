import React, { useContext } from 'react'

import { isEmptyValue } from '../../assets/js/utils'
import { FormDispatchContext, FormStateContext } from './context'

export function useFormState() {
    const context = useContext(FormStateContext)
    
    if (context === undefined) {
        throw new Error('useFormState must be used within a FormProvider')
    }

    return context
}
 
export function useFormDispatch() {
    const context = useContext(FormDispatchContext)
    
    if (context === undefined) {
        throw new Error('useFormDispatch must be used within a FormProvider')
    }

    return context
}

export function useValue(name, customEmpty) {
    const formState = useFormState()

    if (!formState.values.hasOwnProperty(name)) {
        return customEmpty
    }

    return formState.values[name]
}

export function useEnablement(name) {
    const formState = useFormState()

    if (!formState.enablements.hasOwnProperty(name)) {
        return
    }

    return formState.enablements[name]
}

export function useRequirement(name) {
    const formState = useFormState()

    if (!formState.requirements.hasOwnProperty(name)) {
        return
    }

    return formState.requirements[name]
}

export function useVisibility(name) {
    const formState = useFormState()

    if (!formState.visibilities.hasOwnProperty(name)) {
        return
    }

    return formState.visibilities[name]
}

export function useValidationError(name, value) {
    const formState = useFormState()
    const required = useRequirement(name)

    return required && isEmptyValue(value) && formState.validated
}