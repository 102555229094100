import React, { useEffect, useRef, useState } from 'react'

import NumberFormat from 'react-number-format'

import { Input } from 'semantic-ui-react'

import {
    deleteReferences,
    updateValue,
    updateVisibility,
    useEnablement,
    useValue,
    useFormState,
    useFormDispatch
} from '../../../../../contexts/Form'

import FieldWrapper from '../FieldWrapper/FieldWrapper'
import InfoTooltip from '../../../InfoTooltip/InfoTooltip'


const NumericField = (props) => {
    const formState = useFormState()
    const dispatch = useFormDispatch()
    const enabled = useEnablement(props.name)
    const value = useValue(props.name)
    const inputRef = useRef()

    const getBasicProps = () => {
        return {
            placeholder: props.placeholder ? props.placeholder : props.fieldLabel,
            labelPosition: props.inputLabel ? (props.inputLabel?.position || 'right') : null,
            label: props.inputLabel?.content,
            name: props.name,
            readOnly: props.readOnly || formState.readOnly,
            disabled: !Boolean(enabled),
            value: (typeof value?.raw === 'number') ? value.raw : ''
        }
    }

    const getFormattingProps = () => {
        let format = {
            allowNegative: props.allowNegative
        }

        if (props.isCurrency) {
            format = {
                ...format,
                thousandSeparator: '.',
                decimalSeparator: ',',
                decimalScale: 2,
                fixedDecimalScale: true
            }
        } else if (props.isInteger) {
            format = {
                ...format,
                decimalScale: 0,
                thousandSeparator: '.',
                decimalSeparator: ','
            }
        } else if (props.isYear) {
            format = {
                ...format,
                decimalScale: 0
            }
        } else if (props.isSurface || props.isPercentage || props.isMeasure) {
            format = {
                ...format,
                thousandSeparator: '.',
                decimalSeparator: ',',
                decimalScale: 2
            }
        }

        return format
    }

    const handleChange = (newValue) => {
        const value = {
            raw: newValue.floatValue,
            textual: newValue.formattedValue
        }

        if (value.raw === undefined) {
            value.raw = ''
            value.textual = ''
        } else if (props.inputLabel?.content) {
            value.textual += ` ${props.inputLabel?.content}`
        }
        
        updateValue(dispatch, props.name, value)
    }

    useEffect(() => {
        if (value === undefined) {
            let defaultValue = props.defaultValue

            if (defaultValue) {
                defaultValue = {
                    raw: defaultValue
                }
            }
            
            updateValue(dispatch, props.name, defaultValue)
        }
    }, [])

    useEffect(() => {
        let textualValue = inputRef?.current?.props?.value

        if (props.inputLabel?.content) {
            textualValue += ` ${props.inputLabel?.content}`
        }

        if ((typeof value?.raw === 'number') && (value?.textual !== textualValue)) {
            const valueObj = {
                ...value,
                textual: textualValue
            }
            
            updateValue(dispatch, props.name, valueObj)
        }
    }, [inputRef?.current?.props?.value])

    return (
        <FieldWrapper {...props}>
            {
                props.fieldLabel &&
                <label>
                    {props.fieldLabel}
                    {
                        props.info &&
                        <InfoTooltip text={props.info}/>
                    }
                </label>
            }
            <NumberFormat
                customInput={Input}
                {...getBasicProps()}
                {...getFormattingProps()}
                onValueChange={handleChange}
                getInputRef={inputRef}/>
        </FieldWrapper>
    )
}

export default NumericField