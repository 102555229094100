import axios from 'axios'

import { axiosInstance } from './serviceConfig'

const notificationsEndpoint = '/api/notifications'

const initialize = () => (
    axiosInstance.get(`${notificationsEndpoint}/init`)
)

const getUpdates = () => (
    axiosInstance.get(`${notificationsEndpoint}/updates`)
)

const markAllNotificationsAsRead = () => (
    axiosInstance.put(`${notificationsEndpoint}/`)
)

const markOneNotificationAsRead = (id) => (
    axiosInstance.put(`${notificationsEndpoint}/${id}`)
)

const getNotificationsPage = (pageNumber) => (
    axiosInstance.get(`${notificationsEndpoint}/?page=${pageNumber}`)
)

export default {
    initialize,
    getUpdates,
    markAllNotificationsAsRead,
    markOneNotificationAsRead,
    getNotificationsPage
}