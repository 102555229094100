// https://soshace.com/react-user-login-authentication-using-usecontext-and-usereducer/
import React, { createContext, useState } from 'react'

import { Icon, Modal } from 'semantic-ui-react'

import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton'


export const ErrorHandlingContext = createContext()

export const ErrorHandlingProvider = ({ children }) => {
    const [errorDialog, setErrorDialog] = useState(null)

    const handleConfirm = () => {
        if (typeof errorDialog?.onConfirm === 'function') {
            errorDialog.onConfirm()
        }

        if (errorDialog?.closeOnConfirm !== false) {
            setErrorDialog(null)
        }
    }

    const value = { setErrorDialog }

    return (
        <ErrorHandlingContext.Provider value={value}>
            {children}
            <Modal open={errorDialog !== null}
                   size={errorDialog?.size ? errorDialog.size : 'mini'}
                   dimmer='blurring'>
                <Modal.Header>
                    <Icon color='red' name='times circle'/>
                    {errorDialog?.title}
                </Modal.Header>
                <Modal.Content>
                    {errorDialog?.description}
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton compact onClick={handleConfirm}>
                        Aceptar
                    </PrimaryButton>
                </Modal.Actions>
            </Modal>
        </ErrorHandlingContext.Provider>
    )
}