import React, { useContext } from 'react'

import { quoteStatus } from '../../assets/constants/quote'

import { PeriodicUpdatesDispatchContext, PeriodicUpdatesStateContext } from './context'

export function usePeriodicUpdatesState() {
    const context = useContext(PeriodicUpdatesStateContext)
    
    if (context === undefined) {
        throw new Error('usePeriodicUpdatesState must be used within a PeriodicUpdatesProvider')
    }

    return context
}
 
export function usePeriodicUpdatesDispatch() {
    const context = useContext(PeriodicUpdatesDispatchContext)
    
    if (context === undefined) {
        throw new Error('usePeriodicUpdatesDispatch must be used within a PeriodicUpdatesProvider')
    }

    return context
}

export function useClosedQuotesAmount() {
    const periodicUpdatesState = usePeriodicUpdatesState()

    let sum = 0

    quoteStatus.closed().forEach(closedStatus => {
        let amount = periodicUpdatesState.quotesAmounts?.[closedStatus] || 0
        sum += amount
    })

    return sum
}

export function useTotalQuotesAmount() {
    const periodicUpdatesState = usePeriodicUpdatesState()

    let sum = 0

    quoteStatus.all().forEach(status => {
        let amount = periodicUpdatesState.quotesAmounts?.[status] || 0
        sum += amount
    })

    return sum
}

export function useQuotesAmountByStatus(status) {
    const periodicUpdatesState = usePeriodicUpdatesState()
    return periodicUpdatesState.quotesAmounts?.[status] || 0
}

export function useAccessRequestsAmount() {
    const periodicUpdatesState = usePeriodicUpdatesState()
    return periodicUpdatesState.accessRequestsAmount
}

export function useNotifications() {
    const periodicUpdatesState = usePeriodicUpdatesState()
    return periodicUpdatesState.notifications
}