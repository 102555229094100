import React from 'react'

import { Icon } from 'semantic-ui-react'

import './SideBarTitle.scss'

const SideBarTitle = (props) => {
    return (
        <div className='cb-sidebar-title'>
            <div className='cb-sidebar-title-content'>
                <Icon name={props.icon}/>
                <span>{props.name}</span>
            </div>
        </div>
    )
}

export default SideBarTitle