import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Header } from 'semantic-ui-react'

import { paths } from '../../config/routes'
import accountsService from '../../services/accountsService'
import ShortcutCard from '../../components/UI/ShortcutCard/ShortcutCard'

import './AccountsManagement.scss'

const AccountsManagement = () => {
    const [exportCardLoading, setExportCardLoading] = useState(false)

    const downloadAccountsXlsx = () => {
        setExportCardLoading(true)

        accountsService.exportAccountsXlsx()
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Usuarios por colaborador.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()

                setExportCardLoading(false)
            })
    }

    return (
        <>
            <Helmet>
                <title>Gestionar altas - SegurTempo</title>
            </Helmet>
            <div className='cb-accounts-management-wrapper'>
                <Header className='cb-page-title'>
                    Administra el acceso de colaboradores a la plataforma
                </Header>
                <section className='cb-accounts-management-shortcuts'>
                    <ShortcutCard
                        title='Altas pendientes de tramitar'
                        link={paths.ACCESS_REQUESTS}/>
                    <ShortcutCard
                        title='Usuarios por colaborador'
                        link={paths.USERS_PER_BROKERAGE}/>
                    <ShortcutCard
                        title='Exportar datos de usuarios'
                        onClick={downloadAccountsXlsx}
                        loading={exportCardLoading}/>
                </section>
            </div>
        </>
    )
}

export default AccountsManagement 
