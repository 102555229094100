import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Header, Modal } from 'semantic-ui-react'
import Clock from 'react-live-clock'

import { authGroup } from '../../assets/constants/auth'
import { chartColor, quoteStatus, quoteStatusHints } from '../../assets/constants/quote'
import { paths } from '../../config/routes'

import { useUserGroups } from '../../contexts/Auth'
import {
    useClosedQuotesAmount,
    useQuotesAmountByStatus
} from '../../contexts/PeriodicUpdates'

import {
    QuotesAmountStat,
    QuotesAmountsChart,
    QuotesBasicOverviewTable
} from '../../components/Dashboard'

import ShortcutCard from '../../components/UI/ShortcutCard/ShortcutCard'

import './Dashboard.scss'

const Dashboard = () => {
    const userGroups = useUserGroups()
    const newQuotesAmount = useQuotesAmountByStatus(quoteStatus.NEW)
    const inProgressQuotesAmount = useQuotesAmountByStatus(quoteStatus.IN_PROGRESS)
    const closedQuotesAmount = useClosedQuotesAmount()
    const issuedQuotesAmount = useQuotesAmountByStatus(quoteStatus.ISSUED)
    const quotedQuotesAmount = useQuotesAmountByStatus(quoteStatus.QUOTED)
    const rejectedQuotesAmount = useQuotesAmountByStatus(quoteStatus.REJECTED)
    const notAcceptedQuotesAmount = useQuotesAmountByStatus(quoteStatus.NOT_ACCEPTED)
    const [closedQuotesBreakdownModalVisible, setClosedQuotesBreakdownModalVisible] = useState(false)

    const newStatusLabel = () => {
        if (userGroups.business_department) {
            return 'Nuevas'
        }

        return 'Pendientes de validar'
    }

    return (
        <>
            <Helmet>
                <title>Inicio - SegurTempo</title>
            </Helmet>
            <div className='cb-dashboard-wrapper'>
                <Header className='cb-page-title'>
                    Bienvenido/a, estos son los datos más actualizados
                    <Header.Subheader>
                        Hoy, día <Clock format={'DD/MM/YYYY - HH:mm:ss'} ticking={true} timezone={'Europe/Madrid'}/>
                    </Header.Subheader>
                </Header>
                <section className='cb-overall-data-section'>
                    <QuotesAmountsChart
                        labels={[` ${newStatusLabel()}`, ' En trámite', ' Cerradas']}
                        colors={[chartColor.NEW, chartColor.IN_PROGRESS, chartColor.CLOSED]}
                        amounts={[newQuotesAmount, inProgressQuotesAmount, closedQuotesAmount]}/>
                    <div className='cb-quotes-stats-wrapper'>
                        <QuotesAmountStat
                            title={newStatusLabel()}
                            amount={newQuotesAmount}
                            color={chartColor.NEW}
                            link={
                                userGroups.business_department
                                ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'new')
                                : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'new')
                            }
                            className='cb-new'/>
                        <QuotesAmountStat
                            title='En trámite'
                            amount={inProgressQuotesAmount}
                            color={chartColor.IN_PROGRESS}
                            link={
                                userGroups.business_department
                                ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'in-progress')
                                : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'in-progress')
                            }
                            hint={
                                userGroups.brokers
                                ? quoteStatusHints.inProgress
                                : undefined
                            }
                            className='cb-in-progress'/>
                        <QuotesAmountStat
                            title='Cerradas'
                            amount={closedQuotesAmount}
                            color={chartColor.CLOSED}
                            className='cb-closed'
                            hint={
                                userGroups.brokers
                                ? quoteStatusHints.closed
                                : undefined
                            }
                            onClick={() => setClosedQuotesBreakdownModalVisible(true)}/>
                    </div>
                </section>
                {
                    userGroups.business_department &&
                    <section className='cb-overview-tables-section'>
                        <QuotesBasicOverviewTable
                            title='Cotizaciones sin asignar'
                            statusRange={quoteStatus.ranges.NEW}/>
                        <QuotesBasicOverviewTable
                            title='Cotizaciones cerradas totales'
                            statusRange={quoteStatus.ranges.CLOSED}/>
                    </section>
                }
                {
                    userGroups.brokers &&
                    <section className='cb-shortcuts-section'>
                        <ShortcutCard
                            title='Solicitar cotización'
                            link={paths.QUOTE_REQUEST}/>
                        <ShortcutCard
                            title='Solicitudes guardadas'
                            link={paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'draft')}/>
                    </section>
                }
            </div>
            <Modal basic
                   open={closedQuotesBreakdownModalVisible}
                   dimmer='blurring'
                   closeIcon={true}
                   onClose={() => setClosedQuotesBreakdownModalVisible(false)}
                   className='cb-closed-quotes-amounts-breakdown-modal'>
                <Modal.Content>
                    <QuotesAmountStat
                        title='Emitidas'
                        amount={issuedQuotesAmount}
                        color={chartColor.CLOSED}
                        link={
                            userGroups.business_department
                            ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'issued')
                            : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'issued')
                        }
                        className='cb-closed'/>
                    <QuotesAmountStat
                        title='Cotizadas'
                        amount={quotedQuotesAmount}
                        color={chartColor.CLOSED}
                        link={
                            userGroups.business_department
                            ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'quoted')
                            : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'quoted')
                        }
                        className='cb-closed'/>
                    <QuotesAmountStat
                        title='Rechazadas'
                        amount={rejectedQuotesAmount}
                        color={chartColor.CLOSED}
                        link={
                            userGroups.business_department
                            ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'rejected')
                            : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'rejected')
                        }
                        className='cb-closed'/>
                    <QuotesAmountStat
                        title='No aceptadas'
                        amount={notAcceptedQuotesAmount}
                        color={chartColor.CLOSED}
                        link={
                            userGroups.business_department
                            ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', 'not-accepted')
                            : paths.BROKERAGE_QUOTES_BROKER.replace(':statusSlug', 'not-accepted')
                        }
                        className='cb-closed'/>
                </Modal.Content>
            </Modal>
        </>
    )
}

export default Dashboard 
