import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Divider, Icon, Image, Message, Segment } from 'semantic-ui-react'

import authService from '../../../services/authService'
import { paths } from '../../../config/routes'

import NewPasswordForm from '../NewPasswordForm/NewPasswordForm'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'

import './NewPasswordSegment.scss'

const NewPasswordSegment = (props) => {
    const [checkingKey, setCheckingKey] = useState(true)
    const [isKeyCorrect, setIsKeyCorrect] = useState(true)
    const [loading, setLoading] = useState(false)
    const [requestSuccess, setRequestSuccess] = useState(null)
    
    const history = useHistory()
    const { resetKey } = useParams()

    const showLogin = () => {
        history.push(paths.LOGIN)
    }

    useEffect(() => {
        let isMounted = true

        authService.checkResetKey(resetKey)
            .then(res => {
                if (isMounted) {
                    setCheckingKey(false)
                    setIsKeyCorrect(true)
                }
            })
            .catch(() => {
                if (isMounted) {
                    setCheckingKey(false)
                    setIsKeyCorrect(false)
                }
            })

        return () => { isMounted = false }
    }, [])

    return (
        <Segment loading={checkingKey} className='cb-new-password-segment'>
            {
                !isKeyCorrect
                ? <Message error icon='times circle' className='cb-top-margin'
                           header='Clave incorrecta' 
                           content='La clave proporcionada por correo electrónico para
                                    llevar a cabo la recuperación de contraseña ya ha
                                    caducado o no existe. Por favor, vuelve a realizar
                                    la solicitud.'/>
                : requestSuccess
                  ? <Message positive icon='check' className='cb-top-margin'
                             header='Contraseña restaurada' 
                             content='La contraseña ha sido restaurada correctamente.
                                      Inicia sesión con la nueva contraseña para poder
                                      acceder nuevamente a la plataforma.'/>
                  : <NewPasswordForm setLoading={setLoading} setRequestSuccess={setRequestSuccess}/>
            }
            <Divider/>
            {
                requestSuccess || !isKeyCorrect
                ? <SecondaryButton fluid className='cb-bottom-margin' onClick={showLogin}>
                      Aceptar
                  </SecondaryButton>
                : <Button.Group fluid className='cb-bottom-margin'>
                      <PrimaryButton onClick={showLogin}>
                          Cancelar
                      </PrimaryButton>
                      <SecondaryButton form='cb-new-password-form' type='submit' loading={loading} disabled={loading}>
                          Cambiar contraseña
                      </SecondaryButton>
                  </Button.Group>
            }
        </Segment>
    )
}

export default NewPasswordSegment