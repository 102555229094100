import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Message } from 'semantic-ui-react'

import { login, scheduleTokenRefresh, useAuthDispatch, useAuthState } from '../../../contexts/Auth'
import { useUrlQuery } from '../../../hooks'

import { paths } from '../../../config/routes'

import './LoginForm.scss'

const LoginForm = () => {
    const [data, setData] = useState({})
    const [isVisiblePassword, setPasswordVisibility] = useState(false)

    const dispatch = useAuthDispatch()
    const { loginErrorMessage } = useAuthState()
    const urlQuery = useUrlQuery()
    
    const history = useHistory()

    const handleChange = event => {
        const { name, value } = event.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        // const v3TestEmails = ["direccioncomercial@centerbrok.es", "departamentoempresas@centerbrok.es", "beat.cuenca@gmail.com", "corredor.breo.esculta@bebluebrand.com", "breo.esculta@bebluebrand.com", "cotizacionesempresa@centerbrok.es"]
        try {
            // if(!v3TestEmails.includes(data.username)) {
            //     return alert("Lo sentimos, la plataforma está en mantenimiento desde el jueves 17/11/2022 a las 16:00 hasta el viernes 18/11/2022 a las 10:00")
            // }
            login(dispatch, data).then(res => {
                if (res && res.currentUser) {
                    const nextUrl = urlQuery.get('next')
                    scheduleTokenRefresh(dispatch)
                    history.push(nextUrl ? nextUrl : paths.DASHBOARD)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    
    return (
        <Form error={loginErrorMessage ? true : false} className='cb-login-form' id='cb-login-form' onSubmit={handleSubmit}>
            <Form.Input
                error={loginErrorMessage ? true : false}
                label='Correo electrónico'
                placeholder='Correo electrónico'
                icon='envelope'
                iconPosition='left'
                name='username'
                onChange={handleChange}/>
            <Form.Input
                error={loginErrorMessage ? true : false}
                label='Contraseña'
                placeholder='Contraseña'
                icon='lock'
                iconPosition='left'
                name='password'
                type={isVisiblePassword ? 'text' : 'password'}
                action={{
                    icon: 'eye',
                    className: 'cb-show-password-button',
                    type: 'button',
                    onClick: () => setPasswordVisibility(!isVisiblePassword)
                }}
                onChange={handleChange}/>
            {
                loginErrorMessage &&
                <Message error content={loginErrorMessage}/>
            }
        </Form>
    )
}

export default LoginForm