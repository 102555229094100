import React, { useEffect, useState } from 'react'
import { Switch, useHistory, useLocation } from 'react-router-dom'

import { Dimmer, Loader, Modal } from 'semantic-ui-react'

import { paths, routes } from './config/routes'

import {
    notifiedUserLogout,
    recoverUser,
    refreshToken,
    scheduleLogout,
    scheduleTokenRefresh,
    useAuthDispatch,
    useAuthState
} from './contexts/Auth'
import {
    startPeriodicUpdates,
    turnoffUpdates,
    usePeriodicUpdatesDispatch,
    usePeriodicUpdatesState
} from './contexts/PeriodicUpdates'

import { useUrlQuery } from './hooks'

import PrimaryButton from './components/UI/PrimaryButton/PrimaryButton'
import { SecureRoute } from './components/SecureRoute'
import { SideBar } from './components/SideBar'

import './App.scss'

const App = () => {
    const authData = useAuthState()
    const authDispatch = useAuthDispatch()
    const periodicUpdatesData = usePeriodicUpdatesState()
    const periodicUpdatesDispatch = usePeriodicUpdatesDispatch()
    const urlQuery = useUrlQuery()
    
    const history = useHistory()
    const location = useLocation()
    const [initialLoading, setInitialLoading] = useState(true)

    // Recover user if possible, refreshing token if necessary
    useEffect(() => {
        const nextUrl = urlQuery.get('next')

        if (!authData.loggedUser && authData.accessToken) {
            recoverUser(authDispatch)
                .then(res => {
                    if (res && res.currentUser) {
                        scheduleTokenRefresh(authDispatch, authData.tokenRefreshTimeoutId)

                        if (location.pathname === paths.LOGIN) {
                            history.push(nextUrl ? nextUrl : paths.DASHBOARD)
                        }

                        setInitialLoading(false)
                    } else {
                        return Promise.reject(new Error());
                    }
                })
                .catch(() => refreshToken(authDispatch))
                .then(res => {
                    if (res && res.currentUser) {
                        scheduleTokenRefresh(authDispatch)

                        if (location.pathname === paths.LOGIN) {
                            history.push(nextUrl ? nextUrl : paths.DASHBOARD)
                        }

                        setInitialLoading(false)
                    } else {
                        setInitialLoading(false)
                    }
                })
        } else {
            setInitialLoading(false)
        }
    }, [])

    // Initialize periodic updates
    useEffect(() => {
        const username = authData.loggedUser?.username

        if (username) {
            startPeriodicUpdates(periodicUpdatesDispatch)
        } else {
            turnoffUpdates(periodicUpdatesDispatch, periodicUpdatesData.updateTimerId)
        }
    }, [authData.loggedUser?.username])

    return (
        <main>
            <Modal
                open={
                    authData.tokenRefreshFailed && authData.loggedUser
                    ? true
                    : false
                }
                size='tiny'
                dimmer='blurring'
                header='Tu sesión caducará pronto'
                content={`Tu sesión caducará en ${authData.minutesUntilLogout}
                          minutos. Para evitar que la sesión se cierre de manera
                          automática, puedes cerrarla ahora e iniciarla de nuevo
                          y así evitar posibles problemas.`}
                actions={[{
                  key: 'done',
                  content: 'Aceptar',
                  compact: true,
                  as: PrimaryButton,
                  onClick: () => notifiedUserLogout(authDispatch)
                }]}
            />
            {
                (authData.checkingUser || periodicUpdatesData.checkingUpdates) &&
                <Dimmer active inverted>
                    <Loader indeterminate inverted size='big'>
                        {
                            authData.checkingUser
                            ? 'Comprobando usuario...'
                            : 'Actualizando información...'
                        }
                    </Loader>
                </Dimmer>
            }
            {
                !authData.checkingUser &&
                !periodicUpdatesData.checkingUpdates &&
                !initialLoading &&
                <Switch>
                    {routes.map((route, index) => (
                        <SecureRoute
                            exact
                            key={index}
                            path={route.path}
                            component={route.component}
                            isPrivate={route.isPrivate}
                            allowedGroups={route.allowedGroups}
                        />
                    ))}
                </Switch>
            }
        </main>
    )
}

export default App