import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Button, Icon, Message, Modal } from 'semantic-ui-react'

import { useErrorState } from '../../../contexts/ErrorHandling'
import { useUserGroups } from '../../../contexts/Auth'
import quotesService from '../../../services/quotesService'
import { quoteStatus } from '../../../assets/constants/quote'

import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'

import './QuoteActionsPane.scss'

const QuoteActionsPane = (props) => {
    const [loadingState, setLoadingState] = useState({ isLoading: false })
    const { setErrorDialog } = useErrorState()
    const userGroups = useUserGroups()
    const history = useHistory()

    const generateButtons = () => {
        if (props.actionButtons?.length > 0) {
            return props.actionButtons.map((config, index) => (
                <Button icon labelPosition='right' size='big' onClick={config.action} key={index}>
                    {config.text}
                    <Icon name={config.icon}/>
                </Button>
            ))
        }

        return [(
            <Message info icon size='large' className='cb-empty-message' key={1}>
                <Icon name='info circle'/>
                <Message.Content>
                    <Message.Header>
                        No hay acciones disponibles
                    </Message.Header>
                    Dado el estado actual de la cotización, en este momento no es posible llevar a cabo
                    ninguna acción.
                </Message.Content>
            </Message>
        )]
    }

    return (
        <>
            {
                loadingState?.isLoading &&
                <FullPageLoader text={loadingState.message || 'Cargando...'}/>
            }
            <div className='cb-quote-actions-wrapper'>
                <div className='cb-quote-actions'>
                    {
                        [...generateButtons()]
                    }
                </div>
            </div>
        </>
    )
}

export default QuoteActionsPane