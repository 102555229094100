import React, { useState } from 'react'

import { Icon, Input, Modal, Table } from 'semantic-ui-react'

import authService from '../../../services/authService'
import { useErrorState } from '../../../contexts/ErrorHandling'

import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'
import UserDataUpdateForm from '../UserDataUpdateForm/UserDataUpdateForm'

import './UsersTable.scss'

const UsersTable = (props) => {
    const [searchString, setSearchString] = useState('')
    const [selectedUser, setSelectedUser] = useState(null)
    const [isConfirmationVisible, setConfirmationVisible] = useState(false)
    const [isDeleting, setDeleting] = useState(false)
    const [isUpdating, setUpdating] = useState(false)
    const { setErrorDialog } = useErrorState()

    const handleInputChange = (event, result) => {
        const { value } = result || event.target
        setSearchString(value)
    }

    const updateSuccess = () => {
        props.refresh()
            .then(() => {
                setSelectedUser(null)
            })
    }

    const deleteUser = user => {
        setDeleting(true)

        authService.deleteUser(user.id)
            .then(() => {
                props.refresh()
                    .then(() => {
                        setDeleting(false)
                        setSelectedUser(null)
                        setConfirmationVisible(false)
                    })
            })
            .catch(() => {
                setDeleting(false)
                setSelectedUser(null)
                setConfirmationVisible(false)

                setErrorDialog({
                    title: 'Error al dar de baja',
                    description: `Ha tenido lugar un error al dar de baja el usuario.
                                  Por favor, inténtalo de nuevo más tarde o contacta con el
                                  administrador de la plataforma.`
                })
            })
    }

    return (
        <>
            <div className='cb-users-table-wrapper'>
                <div className='cb-user-search-bar'>
                    <Input
                        icon='search'
                        iconPosition='left'
                        placeholder='Buscar...'
                        className='cb-search-input'
                        onChange={handleInputChange}/>
                </div>
                <Table stackable selectable padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>NOMBRE DEL CORREDOR</Table.HeaderCell>
                            <Table.HeaderCell>CORREO ELECTRÓNICO</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            props.users?.map((user, index) => (
                                (`${user.first_name} ${user.last_name}`.toLowerCase().includes(searchString.toLowerCase()) 
                                    || user.email.toLowerCase().includes(searchString.toLowerCase())) &&
                                <Table.Row key={index} className='cb-user' onClick={() => setSelectedUser(user)}>
                                    <Table.Cell>
                                        <strong>{user.first_name} {user.last_name}</strong>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {user.email}
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </div>
            <Modal open={isConfirmationVisible}
                   size='mini'
                   dimmer='blurring'
                   className='cb-user-delete-confirmation-modal'>
                <Modal.Header>Dar de baja usuario</Modal.Header>
                <Modal.Content>
                    ¿Seguro que quieres dar de baja a este usuario?
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton compact disabled={isDeleting} onClick={() => setConfirmationVisible(false)}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton compact disabled={isDeleting} loading={isDeleting} onClick={() => deleteUser(selectedUser)}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
            <Modal open={(selectedUser !== null) && !isConfirmationVisible}
                   size='tiny'
                   dimmer='blurring'
                   className='cb-user-management-modal'>
                <Modal.Header>Gestión de usuario</Modal.Header>
                <Modal.Content>
                    <UserDataUpdateForm
                        userToUpdate={selectedUser}
                        setUpdating={setUpdating}
                        onUpdateSuccess={updateSuccess}/>
                </Modal.Content>
                <Modal.Actions>
                    <span
                        className='cb-deregister-user'
                        onClick={() => setConfirmationVisible(true)}>
                        <Icon name='remove user'/>
                        Dar de baja
                    </span>
                    <div>
                        <PrimaryButton compact disabled={isUpdating} onClick={() => setSelectedUser(null)}>
                            Cancelar
                        </PrimaryButton>
                        <SecondaryButton compact form='cb-update-user-data-form' type='submit' loading={isUpdating} disabled={isUpdating}>
                            Aceptar
                        </SecondaryButton>
                    </div>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default UsersTable