import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Button, Header, Icon } from 'semantic-ui-react'

import { paths } from '../../config/routes'
import { quoteStatus, quoteStatusNames } from '../../assets/constants/quote'
import { useErrorState } from '../../contexts/ErrorHandling'
import { useAuthState, useUserGroups } from '../../contexts/Auth'

import { BrokerageQuotesTable } from '../../components/BrokerageQuotes'
import FullPageLoader from '../../components/UI/FullPageLoader/FullPageLoader'

import businessService from '../../services/businessService'

import './BrokerageQuotes.scss'

const BrokerageQuotes = () => {
    const { statusSlug, brokerageId } = useParams()
    const { setErrorDialog } = useErrorState()
    const [brokerage, setBrokerage] = useState(null)
    const [status, setStatus] = useState(null)
    const { loggedUser } = useAuthState()
    const userGroups = useUserGroups()
    const history = useHistory()

    const generateHeaderEndingText = () => {
        let text = ''

        switch (status) {
            case quoteStatus.DRAFT:
                text = 'guardadas y pendientes de enviar'
                break
            case quoteStatus.NEW:
                if (brokerageId) {
                    text = 'que aún no han sido asignadas'
                } else {
                    text = 'pendientes de validar'
                }
                break
            case quoteStatus.IN_PROGRESS:
                text = 'que se encuentran en trámite'
                break
            case quoteStatus.ISSUED:
            case quoteStatus.QUOTED:
            case quoteStatus.REJECTED:
                text = `que ya han sido {0}`
                break
            case quoteStatus.NOT_ACCEPTED:
                text = 'que no han sido aceptadas'
                break
        }

        return text.replace('{0}', quoteStatusNames[status].plural.toLowerCase())
    }

    const goBack = () => {
        const statusSlug = quoteStatus.toSlug(status)
        const previousUrl = (
            userGroups.business_department
                ? paths.QUOTES_PER_BROKERAGE.replace(':statusSlug', statusSlug)
                : paths.DASHBOARD
        )

        history.push(previousUrl)
    }

    useEffect(() => {
        if (statusSlug) {
            const statusCode = quoteStatus.fromSlug(statusSlug)

            if (!statusCode) {
                history.push(paths.NOT_FOUND)
            } else if ((statusCode === quoteStatus.DRAFT) && brokerageId) {
                // Not allowing the Business Department to access Drafts
                history.push(paths.DASHBOARD)
            } else {
                setStatus(statusCode)
            }
        }

        if (!brokerageId && !loggedUser.brokerage) {
            history.push(paths.DASHBOARD)
        }

        if (brokerageId) {
            businessService.getBrokerage(brokerageId)
                .then(res => setBrokerage(res.data))
                .catch(() => history.push(paths.NOT_FOUND))
        } else {
            setBrokerage({...loggedUser.brokerage})
        }
    }, [statusSlug, brokerageId])

    return (
        <>
            <Helmet>
                <title>Listado de cotizaciones - SegurTempo</title>
            </Helmet>
            <div className='cb-brokerage-quotes-wrapper'>
                {
                    (!status || !brokerage) &&
                    <FullPageLoader text='Cargando cotizaciones...'/>
                }
                {
                    ((status !== null) && (brokerage !== null)) &&
                    <>
                        <Header className='cb-page-title'>
                            Cotizaciones
                            {
                                Boolean(brokerageId) &&
                                <> del colaborador <span>{brokerage.name}</span> </>
                            }
                            {
                                <> {generateHeaderEndingText()} </>
                            }
                        </Header>
                        <BrokerageQuotesTable
                            status={status}
                            brokerage={brokerage}
                            isBrokerView={!Boolean(brokerageId)}/>
                        <Button
                            icon
                            labelPosition='left'
                            className='cb-go-back-button'
                            onClick={goBack}>
                            <Icon name='reply'/>
                            Volver
                        </Button>
                    </>
                }
            </div>
        </>
    )
}

export default BrokerageQuotes 
