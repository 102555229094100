import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'

import LoggedPage from '../pages/LoggedPage'

import { useAuthState, useUserInGroups } from '../contexts/Auth'
import { paths } from '../config/routes'
 
const SecureRoute = ({ component: Component, path, isPrivate, allowedGroups, ...rest }) => {
    const authData = useAuthState()
    const hasPermission = useUserInGroups(allowedGroups)
    const location = useLocation()

    const requiresLogin = () => {
        return isPrivate && !authData.loggedUser && !authData.checkingUser
    }

    const requiresNoLogin = () => {
        return !isPrivate && authData.loggedUser
    }

    return (
        path
        ? <Route
              path={path}
              render={props => {
                  if (requiresLogin()) {
                      const next = (location.pathname === paths.DASHBOARD ? '' : `?next=${location.pathname}`)
                      return (<Redirect to={`${paths.LOGIN}${next}`}/>)
                  } else if (requiresNoLogin() || !hasPermission) {
                      return (<Redirect to={paths.DASHBOARD}/>)
                  }
                  return (
                    authData.loggedUser
                    ? <LoggedPage>
                        <Component {...props} />
                      </LoggedPage>
                    : <Component {...props} />
                  )
              }}
              {...rest}
          />
        : <Route render={props => <Component {...props} />} />
    )
}
 
export {
    SecureRoute
}