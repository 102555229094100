import React from 'react'

import { Link, useHistory } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'

import './ShortcutCard.scss'


const ShortcutCard = (props) => {
    const history = useHistory()

    const sanitizeProps = () => {
        const cardProps = {
            className: 'cb-shortcut-card'
        }

        if (props.link) {
            cardProps.onClick = () => history.push(props.link)
        } else if (typeof props.onClick === 'function') {
            cardProps.onClick = props.onClick
        }

        return cardProps
    }

    return (
        <div {...sanitizeProps()}>
            {
                props.loading &&
                <Loader active size='large' inline='centered'/>
            }
            {
                !props.loading &&
                <h1 className='cb-shortcut-title'>
                    {props.title}
                </h1>
            }
        </div>
    )
}

export default ShortcutCard