import React, { useState } from 'react'
import { Form, Message } from 'semantic-ui-react'

import authService from '../../../services/authService'

import './ChangePasswordForm.scss'

const ChangePasswordForm = (props) => {
    const [data, setData] = useState({})
    const [isCurrentPasswordVisible, setCurrentPasswordVisible] = useState(false)
    const [isNewPasswordVisible, setNewPasswordVisible] = useState(false)
    const [isRepeatPasswordVisible, setRepeatPasswordVisible] = useState(false)
    const [isError, setIsError] = useState(false)
    const [fieldErrors, setFieldErrors] = useState({})

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        props.setPasswordChangeLoading(true)

        authService.changePassword(data)
            .then(res => {
                props.setPasswordChangeLoading(false)
                props.setPasswordChangeSuccessful(true)
                setFieldErrors({})
                setIsError(false)
            })
            .catch(error => {
                props.setPasswordChangeLoading(false)
                props.setPasswordChangeSuccessful(false)
                setIsError(true)

                if (error.response) {
                    setFieldErrors(error.response.data)
                }
            })
    }
    
    return (
        <Form error={isError ? true : false} className='cb-change-password-form' id='cb-change-password-form' onSubmit={handleSubmit}>
            <Form.Input
                error={fieldErrors.current_password ? true : false}
                label='Contraseña actual'
                placeholder='Contraseña actual'
                icon='lock'
                iconPosition='left'
                name='current_password'
                type={isCurrentPasswordVisible ? 'text' : 'password'}
                action={{
                    icon: 'eye',
                    className: 'cb-show-password-button',
                    type: 'button',
                    onClick: () => setCurrentPasswordVisible(!isCurrentPasswordVisible)
                }}
                onChange={handleChange}/>
            <Form.Input
                error={fieldErrors.new_password || fieldErrors.non_field_errors ? true : false}
                label='Nueva contraseña'
                placeholder='Nueva contraseña'
                icon='lock'
                iconPosition='left'
                name='new_password'
                type={isNewPasswordVisible ? 'text' : 'password'}
                action={{
                    icon: 'eye',
                    className: 'cb-show-password-button',
                    type: 'button',
                    onClick: () => setNewPasswordVisible(!isNewPasswordVisible)
                }}
                onChange={handleChange}/>
            <Form.Input
                error={fieldErrors.repeat_password || fieldErrors.non_field_errors ? true : false}
                label='Repetir la nueva contraseña'
                placeholder='Repetir la nueva contraseña'
                icon='lock'
                iconPosition='left'
                name='repeat_password'
                type={isRepeatPasswordVisible ? 'text' : 'password'}
                action={{
                    icon: 'eye',
                    className: 'cb-show-password-button',
                    type: 'button',
                    onClick: () => setRepeatPasswordVisible(!isRepeatPasswordVisible)
                }}
                onChange={handleChange}/>
            {
                isError &&
                <Message error>
                    <Message.List>
                    {
                        Object.entries(fieldErrors).map(
                            ([k, v], i) => (
                                <Message.Item key={i}>
                                    {v[0]}
                                </Message.Item>
                            )
                        )
                    }
                    </Message.List>
                </Message>
            }
        </Form>
    )
}

export default ChangePasswordForm