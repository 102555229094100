import React, { useEffect, useState } from 'react'

import {
    Divider, Form, Input, Label,
    Message, Modal, Tab, TextArea
} from 'semantic-ui-react'
import NumberFormat from 'react-number-format'

import communicationService from '../../../services/communicationService'
import quotesService from '../../../services/quotesService'
import documentationService from '../../../services/documentationService'
import { useErrorState } from '../../../contexts/ErrorHandling'
import { useUserGroups } from '../../../contexts/Auth'
import { FormProvider } from '../../../contexts/Form'
import {
    fetchUpdatesOnDemand,
    usePeriodicUpdatesState,
    usePeriodicUpdatesDispatch
} from '../../../contexts/PeriodicUpdates'

import { authGroupsList } from '../../../assets/constants/auth'
import { quoteStatus, quoteStatusNames } from '../../../assets/constants/quote'
import * as utils from '../../../assets/js/utils'

import {
    QuoteFormPane,
    QuoteFilesPane,
    QuoteCommunicationPane,
    QuoteActionsPane
} from '../'
import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'

import './QuoteManagementPanel.scss'
import QuoteCompaniesPane from '../QuoteCompaniesPane/QuoteCompaniesPane'

const QuoteManagementPanel = (props) => {
    const periodicUpdatesDispatch = usePeriodicUpdatesDispatch()
    const periodicUpdatesData = usePeriodicUpdatesState()
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [loadingState, setLoadingState] = useState({ isLoading: false })
    const [isRequestInfoSelected, setRequestInfoSelected] = useState(false)
    const [isGiveApprovalSelected, setGiveApprovalSelected] = useState(false)
    const [isChangeToInProgressSelected, setChangeToInProgressSelected] = useState(false)
    const [isChangeToQuotedSelected, setChangeToQuotedSelected] = useState(false)
    const [isChangeToRejectedSelected, setChangeToRejectedSelected] = useState(false)
    const [isGiveOrderToIssueSelected, setGiveOrderToIssueSelected] = useState(false)
    const [isChangeToNotAcceptedSelected, setChangeToNotAcceptedSelected] = useState(false)
    const [isValidationErrors, setValidationErrors] = useState(false)
    const [infoMessage, setInfoMessage] = useState('')
    const [isInfoMessageError, setInfoMessageError] = useState(false)
    const [changeStatusData, setChangeStatusData] = useState({})
    const { setErrorDialog } = useErrorState()
    const userGroups = useUserGroups()

    const scrollToBottom = (e, activeIndex) => {
        if (e.target.innerText == 'Comunicación') {
            setTimeout(() => {
                var element = document.getElementById('cb-main-content');
                element.scrollTo(0, element.scrollHeight);
            }, 200);
        }
    }

    const getAvailableActions = () => {
        const buttonsConfigs = {
            requestInfo: {
                action: () => setRequestInfoSelected(true),
                text: 'Solicitar información',
                icon: 'info circle'
            },
            giveApproval: {
                action: () => setGiveApprovalSelected(true),
                text: 'Dar conformidad',
                icon: 'check'
            },
            giveOrderToIssue: {
                action: () => setGiveOrderToIssueSelected(true),
                text: 'Dar orden de emisión',
                icon: 'rocket',
                highlight: userGroups.brokers
            },
            changeToInProgress: {
                action: () => setChangeToInProgressSelected(true),
                text: 'Cambiar a en trámite',
                icon: 'hourglass half'
            },
            changeToQuoted: {
                action: () => setChangeToQuotedSelected(true),
                text: 'Cambiar a cotizada',
                icon: 'suitcase'
            },
            changeToRejected: {
                action: () => setChangeToRejectedSelected(true),
                text: 'Cambiar a rechazada',
                icon: 'thumbs down'
            },
            changeToNotAccepted: {
                action: () => setChangeToNotAcceptedSelected(true),
                text: 'Cambiar a no aceptada',
                icon: 'times'
            }
        }

        let chosenConfigs = []

        switch (props.quote.status.code) {
            case quoteStatus.NEW:
                if (userGroups.business_department) {
                    chosenConfigs = [buttonsConfigs.requestInfo, buttonsConfigs.giveApproval]
                }
                break
            case quoteStatus.IN_PROGRESS:
                if (userGroups.business_department) {
                    chosenConfigs = [buttonsConfigs.giveOrderToIssue, buttonsConfigs.changeToQuoted, buttonsConfigs.changeToRejected, buttonsConfigs.changeToNotAccepted]
                } else {
                    chosenConfigs = [buttonsConfigs.giveOrderToIssue, buttonsConfigs.changeToNotAccepted]
                }
                break
            case quoteStatus.ISSUED:
                if (userGroups.business_department) {
                    chosenConfigs = [buttonsConfigs.changeToRejected, buttonsConfigs.changeToNotAccepted]
                }
                break
            case quoteStatus.QUOTED:
                if (userGroups.business_department) {
                    chosenConfigs = [buttonsConfigs.changeToInProgress, buttonsConfigs.giveOrderToIssue, buttonsConfigs.changeToRejected, buttonsConfigs.changeToNotAccepted]
                } else {
                    chosenConfigs = [buttonsConfigs.giveOrderToIssue, buttonsConfigs.changeToNotAccepted]
                }
                break
            case quoteStatus.REJECTED:
                if (userGroups.business_department) {
                    chosenConfigs = [buttonsConfigs.changeToInProgress, buttonsConfigs.giveOrderToIssue, buttonsConfigs.changeToQuoted, buttonsConfigs.changeToNotAccepted]
                }
                break
            case quoteStatus.NOT_ACCEPTED:
                if (userGroups.business_department) {
                    chosenConfigs = [buttonsConfigs.changeToInProgress, buttonsConfigs.giveOrderToIssue, buttonsConfigs.changeToQuoted, buttonsConfigs.changeToRejected]
                } else {
                    chosenConfigs = [buttonsConfigs.giveOrderToIssue]
                }
                break
        }

        return chosenConfigs
    }

    const generatePanes = () => {
        const panes = []

        panes.push({
            menuItem: { key: 'Formulario', icon: 'wpforms', content: 'Formulario' },
            pane: {
                key: 'Formulario', className: 'cb-quote-form-pane', content: (
                    <FormProvider>
                        <QuoteFormPane
                            quote={props.quote}
                            actionButtons={userGroups.brokers ? getAvailableActions() : undefined}
                            refreshQuoteData={props.refreshQuoteData} />
                    </FormProvider>
                )
            }
        })

        const documentsTabTitle = `Documentos${userGroups.business_department ? ' compartidos' : ''}`

        panes.push({
            menuItem: { key: documentsTabTitle, icon: 'file alternate outline', content: documentsTabTitle },
            pane: {
                key: documentsTabTitle, className: 'cb-quote-public-files-pane', content: (
                    <QuoteFilesPane
                        quote={props.quote}
                        files={props.quote.uploaded_files.public}
                        refreshQuoteData={props.refreshQuoteData} />
                )
            }
        })

        if (userGroups.business_department) {
            panes.push({
                menuItem: { key: 'Carpeta privada', icon: 'lock', content: 'Carpeta privada' },
                pane: {
                    key: 'Carpeta privada', className: 'cb-quote-private-files-pane', content: (
                        <QuoteFilesPane
                            quote={props.quote}
                            files={props.quote.uploaded_files.private}
                            refreshQuoteData={props.refreshQuoteData}
                            isPrivate />
                    )
                }
            })
        }

        panes.push({
            menuItem: { key: 'Compañías', icon: 'building', content: 'Compañías' },
            pane: {
                key: 'Compañías', className: 'cb-quote-companies-pane',
                content: (
                    <QuoteCompaniesPane
                        onClick={scrollToBottom}
                        quote={props.quote} />
                )
            }
        })


        if (props.quote.status.code !== quoteStatus.NEW) {
            panes.push({
                menuItem: { key: 'Comunicación', icon: 'comment alternate outline', content: 'Comunicación' },
                pane: {
                    key: 'Comunicación', className: 'cb-quote-communication-pane', content: (
                        <QuoteCommunicationPane
                            quote={props.quote}
                            refreshQuoteData={props.refreshQuoteData} />
                    )
                }
            })
        }

        if (userGroups.business_department) {
            panes.push({
                menuItem: { key: 'Acciones', icon: 'sliders horizontal', content: 'Acciones' },
                pane: {
                    key: 'Acciones', className: 'cb-quote-actions-pane', content: (
                        <QuoteActionsPane
                            quote={props.quote}
                            actionButtons={getAvailableActions()} />
                    )
                }
            })
        }

        return panes
    }

    const requestInfo = () => {
        if (!infoMessage.trim()) {
            setInfoMessageError(true)
            return
        }

        setRequestInfoSelected(false)
        setLoadingState({ isLoading: true, message: 'Actualizando cotización...' })

        quotesService.approveQuote(props.quote.id)
            .then(() => communicationService.sendQuoteMessage(props.quote.id, infoMessage))
            .then(() => props.refreshQuoteData())
            .then(() => fetchUpdatesOnDemand(periodicUpdatesDispatch, periodicUpdatesData.updateTimerId))
            .then(() => {
                setActiveTabIndex(0)
                setLoadingState({ isLoading: false })
            })
            .catch(error => {
                setLoadingState({ isLoading: false })

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al solicitar información. Por favor, inténtalo de nuevo más tarde.`
                }

                setErrorDialog({
                    title: 'Error al solicitar información',
                    description: errorMessage
                })
            })
    }

    const approveQuote = () => {
        setGiveApprovalSelected(false)
        setLoadingState({ isLoading: true, message: 'Actualizando cotización...' })

        quotesService.approveQuote(props.quote.id)
            .then(() => props.refreshQuoteData())
            .then(() => fetchUpdatesOnDemand(periodicUpdatesDispatch, periodicUpdatesData.updateTimerId))
            .then(() => {
                setActiveTabIndex(0)
                setLoadingState({ isLoading: false })
            })
            .catch(error => {
                setLoadingState({ isLoading: false })

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al dar conformidad. Por favor, inténtalo de nuevo más tarde.`
                }

                setErrorDialog({
                    title: 'Error al dar conformidad',
                    description: errorMessage
                })
            })
    }

    const validateIssueOrderForm = () => {
        const mandatoryFields = [
            'issueEffectiveDate', 'issuePolicyholderName',
            'issuePolicyholderTaxID', 'issuePolicyholderAddress',
            'issuePaymentMethod', 'issueDirectDebitIban',
            'issueInsuranceCompany', 'issueTotalPremium'
        ]

        let isValid = true

        mandatoryFields.forEach(field => {
            let value = changeStatusData?.[field]

            if (typeof value !== 'string') {
                value = value?.raw
            }

            if (!value || (typeof value === 'string' && !value?.trim())) {
                setValidationErrors(true)
                isValid = false
            }
        })

        return isValid
    }

    const validateNotAcceptedForm = () => {
        if (!changeStatusData?.notAcceptedReason?.trim()) {
            setValidationErrors(true)
            return false
        }

        return true
    }

    const changeStatus = (nextStatus, data) => {
        let response

        setChangeToInProgressSelected(false)
        setChangeToQuotedSelected(false)
        setChangeToRejectedSelected(false)
        setGiveOrderToIssueSelected(false)
        setChangeToNotAcceptedSelected(false)
        setLoadingState({ isLoading: true, message: 'Actualizando cotización...' })

        switch (nextStatus) {
            case quoteStatus.IN_PROGRESS:
                response = quotesService.approveQuote(props.quote.id)
                break
            case quoteStatus.ISSUED:
                response = quotesService.issueQuote(props.quote.id, data)
                    .then(() => documentationService.createIssueOrderPdf(props.quote.id))
                break
            case quoteStatus.QUOTED:
                response = quotesService.quoteQuote(props.quote.id)
                break
            case quoteStatus.REJECTED:
                response = quotesService.rejectQuote(props.quote.id)
                    .then(() => documentationService.deleteIssueOrderPdf(props.quote.id))
                break
            case quoteStatus.NOT_ACCEPTED:
                response = quotesService.notAcceptedQuote(props.quote.id, data)
                    .then(() => documentationService.deleteIssueOrderPdf(props.quote.id))
                break
        }

        if (!response) {
            return
        }

        response
            .then(() => props.refreshQuoteData())
            .then(() => fetchUpdatesOnDemand(periodicUpdatesDispatch, periodicUpdatesData.updateTimerId))
            .then(() => {
                setActiveTabIndex(0)
                setLoadingState({ isLoading: false })
            })
            .catch(error => {
                setLoadingState({ isLoading: false })

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cambiar el estado. Por favor, inténtalo de nuevo más tarde.`
                }

                setErrorDialog({
                    title: 'Error al cambiar estado',
                    description: errorMessage
                })
            })
    }

    const handleStatusDataChange = (event, result) => {
        const { name, value } = result || event.target
        setChangeStatusData({
            ...changeStatusData,
            [name]: value
        })
    }

    return (
        <>
            {
                loadingState?.isLoading &&
                <FullPageLoader text={loadingState.message || 'Cargando...'} />
            }
            <Tab
                menu={{
                    attached: 'top',
                    stackable: true,
                    inverted: true,
                    className: 'cb-quote-management-panel-menu'
                }}
                activeIndex={activeTabIndex}
                panes={generatePanes()}
                renderActiveOnly={false}
                className='cb-quote-management-panel'
                onTabChange={(e, { activeIndex }) => {
                    scrollToBottom(e, activeIndex)
                    setActiveTabIndex(activeIndex)
                }
                } />
            <Modal open={isRequestInfoSelected}
                size='tiny'
                dimmer='blurring'>
                <Modal.Header>
                    Solicitar información
                </Modal.Header>
                <Modal.Content>
                    Escribe un mensaje de solicitud de información para que el colaborador {' '}
                    pueda visualizarlo. Una vez enviado, la solicitud pasará a estar {' '}
                    <strong>
                        <i>{quoteStatusNames[quoteStatus.IN_PROGRESS].singular.toLowerCase()}</i>
                    </strong>:
                    <Divider />
                    <Form error={isInfoMessageError}>
                        <Form.TextArea
                            placeholder='Escribe tu mensaje...'
                            error={isInfoMessageError}
                            onInput={(event, data) => setInfoMessage(data.value)}
                            value={infoMessage} />
                        {
                            isInfoMessageError &&
                            <Message error>
                                Es obligatorio escribir el mensaje
                            </Message>
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton
                        compact
                        onClick={() => setRequestInfoSelected(false)}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton
                        compact
                        onClick={requestInfo}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
            <Modal open={isGiveApprovalSelected}
                size='mini'
                dimmer='blurring'>
                <Modal.Header>
                    Dar conformidad
                </Modal.Header>
                <Modal.Content>
                    ¿Seguro que quieres dar conformidad a la solicitud? {' '}
                    Su estado pasará a estar {' '}
                    <strong>
                        <i>{quoteStatusNames[quoteStatus.IN_PROGRESS].singular.toLowerCase()}</i>
                    </strong>.
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton
                        compact
                        onClick={() => setGiveApprovalSelected(false)}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton
                        compact
                        onClick={approveQuote}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
            <Modal open={isChangeToInProgressSelected || isChangeToQuotedSelected || isChangeToRejectedSelected}
                size='mini'
                dimmer='blurring'>
                <Modal.Header>
                    Confirmar cambio de estado
                </Modal.Header>
                <Modal.Content>
                    ¿Seguro que quieres cambiar el estado de la solicitud a {' '}
                    <strong>
                        <i>
                            {
                                isChangeToInProgressSelected
                                    ? quoteStatusNames[quoteStatus.IN_PROGRESS].singular.toLowerCase()
                                    : isChangeToQuotedSelected
                                        ? quoteStatusNames[quoteStatus.QUOTED].singular.toLowerCase()
                                        : quoteStatusNames[quoteStatus.REJECTED].singular.toLowerCase()
                            }
                        </i>
                    </strong>
                    ?
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton
                        compact
                        onClick={() => {
                            setChangeToInProgressSelected(false)
                            setChangeToQuotedSelected(false)
                            setChangeToRejectedSelected(false)
                        }}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton
                        compact
                        onClick={() => {
                            const nextStatus = isChangeToInProgressSelected
                                ? quoteStatus.IN_PROGRESS
                                : isChangeToQuotedSelected
                                    ? quoteStatus.QUOTED
                                    : quoteStatus.REJECTED
                            changeStatus(nextStatus)
                        }}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
            <Modal open={isGiveOrderToIssueSelected}
                size='tiny'
                dimmer='blurring'>
                <Modal.Header>
                    Confirmar cambio de estado
                </Modal.Header>
                <Modal.Content scrolling>
                    Introduce la siguiente información para {' '}
                    <strong>dar orden de emisión:</strong>
                    <Divider />
                    <Form error={isValidationErrors}>
                        <Form.Field required error={isValidationErrors && !changeStatusData?.issueEffectiveDate?.raw?.trim()}>
                            <label>Fecha de efecto</label>
                            <Input type='date' name='issueEffectiveDate' onChange={(event, result) => {
                                const { name, value } = result || event.target
                                const valueObj = {
                                    raw: value,
                                    textual: utils.dateToStr(value)
                                }
                                setChangeStatusData({
                                    ...changeStatusData,
                                    [name]: valueObj
                                })
                            }} />
                            {
                                (isValidationErrors && !changeStatusData?.issueEffectiveDate?.raw?.trim()) &&
                                <Label pointing prompt>
                                    Este campo es obligatorio
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field required error={isValidationErrors && !changeStatusData?.issuePolicyholderName?.trim()}>
                            <label>Nombre del tomador</label>
                            <Input placeholder='Nombre del tomador' name='issuePolicyholderName' onChange={handleStatusDataChange} />
                            {
                                (isValidationErrors && !changeStatusData?.issuePolicyholderName?.trim()) &&
                                <Label pointing prompt>
                                    Este campo es obligatorio
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field required error={isValidationErrors && !changeStatusData?.issuePolicyholderTaxID?.trim()}>
                            <label>NIF/CIF del tomador</label>
                            <Input placeholder='NIF/CIF del tomador' name='issuePolicyholderTaxID' onChange={handleStatusDataChange} />
                            {
                                (isValidationErrors && !changeStatusData?.issuePolicyholderTaxID?.trim()) &&
                                <Label pointing prompt>
                                    Este campo es obligatorio
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field required error={isValidationErrors && !changeStatusData?.issuePolicyholderAddress?.trim()}>
                            <label>Domicilio del tomador</label>
                            <Input placeholder='Domicilio del tomador' name='issuePolicyholderAddress' onChange={handleStatusDataChange} />
                            {
                                (isValidationErrors && !changeStatusData?.issuePolicyholderAddress?.trim()) &&
                                <Label pointing prompt>
                                    Este campo es obligatorio
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field required error={isValidationErrors && !changeStatusData?.issuePaymentMethod?.trim()}>
                            <label>Forma de pago</label>
                            <Input placeholder='Forma de pago' name='issuePaymentMethod' onChange={handleStatusDataChange} />
                            {
                                (isValidationErrors && !changeStatusData?.issuePaymentMethod?.trim()) &&
                                <Label pointing prompt>
                                    Este campo es obligatorio
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field required error={isValidationErrors && !changeStatusData?.issueDirectDebitIban?.trim()}>
                            <label>Domiciliación bancaria (IBAN)</label>
                            <NumberFormat
                                customInput={Input}
                                placeholder='Domiciliación bancaria (IBAN)'
                                name='issueDirectDebitIban'
                                label='ES'
                                onValueChange={newValue => {
                                    setChangeStatusData({
                                        ...changeStatusData,
                                        issueDirectDebitIban: newValue.value
                                    })
                                }}
                                decimalScale={0} />
                            {
                                (isValidationErrors && !changeStatusData?.issueDirectDebitIban?.trim()) &&
                                <Label pointing prompt>
                                    Este campo es obligatorio
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field required error={isValidationErrors && !changeStatusData?.issueInsuranceCompany?.trim()}>
                            <label>Aseguradora</label>
                            <Input placeholder='Aseguradora' name='issueInsuranceCompany' onChange={handleStatusDataChange} />
                            {
                                (isValidationErrors && !changeStatusData?.issueInsuranceCompany?.trim()) &&
                                <Label pointing prompt>
                                    Este campo es obligatorio
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field required error={isValidationErrors && !changeStatusData?.issueTotalPremium?.raw}>
                            <label>Prima total</label>
                            <NumberFormat
                                customInput={Input}
                                placeholder='Prima total'
                                name='issueTotalPremium'
                                label='€'
                                labelPosition='right'
                                onValueChange={newValue => {
                                    setChangeStatusData({
                                        ...changeStatusData,
                                        issueTotalPremium: {
                                            raw: newValue.floatValue,
                                            textual: `${newValue.formattedValue} €`
                                        }
                                    })
                                }}
                                thousandSeparator='.'
                                decimalSeparator=','
                                decimalScale={2}
                                fixedDecimalScale={true} />
                            {
                                (isValidationErrors && !changeStatusData?.issueTotalPremium?.raw) &&
                                <Label pointing prompt>
                                    Este campo es obligatorio
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field>
                            <label>Observaciones</label>
                            <TextArea placeholder='Observaciones' name='issueRemarks' onChange={handleStatusDataChange} />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton
                        compact
                        onClick={() => {
                            setGiveOrderToIssueSelected(false)
                            setChangeStatusData({})
                            setValidationErrors(false)
                        }}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton compact onClick={() => {
                        if (validateIssueOrderForm()) {
                            changeStatus(quoteStatus.ISSUED, changeStatusData)
                            setChangeStatusData({})
                            setValidationErrors(false)
                        }
                    }}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
            <Modal open={isChangeToNotAcceptedSelected}
                size='tiny'
                dimmer='blurring'>
                <Modal.Header>
                    Confirmar cambio de estado
                </Modal.Header>
                <Modal.Content>
                    Introduce la siguiente información para cambiar el estado de la solicitud a {' '}
                    <strong>
                        <i>{quoteStatusNames[quoteStatus.NOT_ACCEPTED].singular.toLowerCase()}</i>
                    </strong>:
                    <Divider />
                    <Form error={isValidationErrors}>
                        <Form.Field error={isValidationErrors && !changeStatusData?.notAcceptedReason?.trim()}>
                            <label>Motivo de desistimiento</label>
                            <TextArea placeholder='Motivo de desistimiento' name='notAcceptedReason' onChange={handleStatusDataChange} />
                            {
                                (isValidationErrors && !changeStatusData?.notAcceptedReason?.trim()) &&
                                <Label pointing prompt>
                                    Este campo es obligatorio
                                </Label>
                            }
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton
                        compact
                        onClick={() => {
                            setChangeToNotAcceptedSelected(false)
                            setChangeStatusData({})
                            setValidationErrors(false)
                        }}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton compact onClick={() => {
                        if (validateNotAcceptedForm()) {
                            changeStatus(quoteStatus.NOT_ACCEPTED, changeStatusData)
                            setChangeStatusData({})
                            setValidationErrors(false)
                        }
                    }}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default QuoteManagementPanel