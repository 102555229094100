export const authGroup = {
    ADMINISTRATORS: 'administrators',
    BROKERS: 'brokers',
    BUSINESS_DEPARTMENT: 'business_department'
}

export const authGroupsList = [
    authGroup.ADMINISTRATORS,
    authGroup.BROKERS,
    authGroup.BUSINESS_DEPARTMENT
]