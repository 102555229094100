import React, { useEffect, useRef, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { Button, Icon, Label, Modal, Segment } from 'semantic-ui-react'

import {
    fetchUpdatesOnDemand,
    resetNewIssueOrders,
    getNotificationsPage,
    useNotifications,
    usePeriodicUpdatesState,
    usePeriodicUpdatesDispatch
} from '../../contexts/PeriodicUpdates'
import { useUserGroups } from '../../contexts/Auth'
import { accessRequestStatus } from '../../assets/constants/accessRequest'
import { eventTypes } from '../../assets/constants/notifications'
import { paths } from '../../config/routes'
import notificationsService from '../../services/notificationsService'
import Notification from '../../assets/js/notificationBuilder'
import * as utils from '../../assets/js/utils'

import PrimaryButton from '../UI/PrimaryButton/PrimaryButton'

import './NotificationsWidget.scss'

const NotificationsWidget = (props) => {
    const periodicUpdatesDispatch = usePeriodicUpdatesDispatch()
    const periodicUpdatesData = usePeriodicUpdatesState()
    const userGroups = useUserGroups()
    const notifications = useNotifications()
    const location = useLocation()
    const history = useHistory()
    const widgetRef = useRef(null)

    const [newIssueOrdersNotificationVisible, setNewIssueOrdersNotificationVisible] = useState(false)
    const [notificationsListVisible, setNotificationsListVisible] = useState(false)
    const [newIssueOrdersAmount, setNewIssueOrdersAmount] = useState(null)

    const generateNotificationItem = (notificationData, index) => {
        const event = notificationData.event

        if (!event?.code) {
            return null
        }

        let className = `cb-notification${notificationData.read ? '' : ' cb-unread'}`
        let notification = new Notification(event, userGroups.brokers)
        let message = notification.message
        let link = notification.link
        let datetime = utils.datetimeToStr(notificationData.created_at)

        let content = (
            <>
                <div className='cb-read-marker'></div>
                <span className='cb-notification-message'>{message}</span>
                <span className='cb-notification-datetime'>{datetime}</span>
            </>
        )

        let props = {
            key: index,
            className: className,
            onClick: () => {
                if (!notificationData.read) {
                    markOneRead(notificationData.id)
                }

                if (link && (link !== location.pathname)) {
                    history.push(link)
                }
            }
        }

        return (
            <div {...props}>
                {content}
            </div>
        )
    }

    const markAllRead = () => {
        if (!notifications.total_unread) {
            return
        }

        notificationsService.markAllNotificationsAsRead()
            .then(() => (
                fetchUpdatesOnDemand(periodicUpdatesDispatch, periodicUpdatesData.updateTimerId))
            )
    }

    const markOneRead = (id) => {
        notificationsService.markOneNotificationAsRead(id)
            .then(() => (
                fetchUpdatesOnDemand(periodicUpdatesDispatch, periodicUpdatesData.updateTimerId))
            )
    }

    const handleClickOutside = (event) => {
        if (widgetRef.current && !widgetRef.current.contains(event.target)) {
            setNotificationsListVisible(false);
        }
    }

    const retrievePreviousNotifications = () => {
        if (!notifications.previous) {
            return
        }

        getNotificationsPage(periodicUpdatesDispatch, notifications.previous)
    }
    const retrieveNextNotifications = () => {
        if (!notifications.next) {
            return
        }

        getNotificationsPage(periodicUpdatesDispatch, notifications.next)
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (!periodicUpdatesData.newIssueOrders || newIssueOrdersNotificationVisible) {
            return
        }

        setNewIssueOrdersAmount(periodicUpdatesData.newIssueOrders)
        setNewIssueOrdersNotificationVisible(true)
    }, [periodicUpdatesData.newIssueOrders])

    return (
        <div className='cb-notifications-widget' ref={widgetRef}>
            <div
                className='cb-main-content'
                onClick={() => setNotificationsListVisible(!notificationsListVisible)}>
                <Icon name='bell outline'/>
                {
                    (notifications.total_unread > 0) &&
                    <Label color='red' floating circular>
                        {
                            (notifications.total_unread > 99)
                            ? '99+'
                            : notifications.total_unread
                        }
                    </Label>
                }
            </div>
            {
                (notifications.data.length > 0 && notificationsListVisible) &&
                <Segment className='cb-notifications-manager' raised>
                    {
                        (notifications.total_unread > 0) &&
                        <header>
                            <Button fluid compact onClick={markAllRead}>Marcar todo como leído</Button>
                        </header>
                    }
                    <div className='cb-notifications-list'>
                        {
                            notifications.data.map((notification, index) => (
                                generateNotificationItem(notification, index)
                            ))
                        }
                    </div>
                    {
                        (notifications.lastPage > 1) &&
                        <footer>
                            <Icon
                                name='chevron circle left'
                                size='large'
                                className={`cb-page-link${!notifications.previous ? ' cb-disabled' : ''}`}
                                onClick={retrievePreviousNotifications}/>
                            <strong>Página {notifications.currentPage}/{notifications.lastPage}</strong>
                            <Icon
                                name='chevron circle right'
                                size='large'
                                className={`cb-page-link${!notifications.next ? ' cb-disabled' : ''}`}
                                onClick={retrieveNextNotifications}/>
                        </footer>
                    }
                </Segment>
            }
            {
                <Modal open={newIssueOrdersNotificationVisible}
                       size='mini'
                       dimmer='blurring'>
                    <Modal.Header>
                        <Icon color='orange' name='warning circle'/>
                        Nuevas emisiones
                    </Modal.Header>
                    <Modal.Content>
                        Hay {newIssueOrdersAmount}
                        {
                            newIssueOrdersAmount === 1
                            ? <> nueva orden de emisión</>
                            : <> nuevas órdenes de emisión</>
                        }
                        . Revisa el listado de notificaciones para acceder a las cotizaciones emitidas.
                    </Modal.Content>
                    <Modal.Actions>
                        <PrimaryButton compact onClick={() => {
                            resetNewIssueOrders(periodicUpdatesDispatch)
                            setNewIssueOrdersNotificationVisible(false)
                            setNewIssueOrdersAmount(null)
                        }}>
                            Aceptar
                        </PrimaryButton>
                    </Modal.Actions>
                </Modal>
            }
        </div>
    )
}

export default NotificationsWidget