import React, { createContext, useReducer } from 'react'

import { FormReducer, initialState } from './reducer'
 
export const FormStateContext = createContext()
export const FormDispatchContext = createContext()

export const FormProvider = ({ children }) => {
    const [formState, dispatch] = useReducer(FormReducer, initialState)

    return (
        <FormStateContext.Provider value={formState}>
            <FormDispatchContext.Provider value={dispatch}>
                {children}
            </FormDispatchContext.Provider>
        </FormStateContext.Provider>
    )
}