import React, { useEffect } from 'react'

import './PDFViewer.scss'

const PDFViewer = (props) => {
    return (
        <object
            className='cb-pdf-viewer'
            data={`${props.fileUrl}&#view=FitV`}
            type='application/pdf'/>
    )
}

export default PDFViewer