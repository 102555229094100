import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import {
    Button, Divider, Dropdown, Form,
    Icon, Input, Label, Menu, Message,
    Modal, Table
} from 'semantic-ui-react'

import { paths } from '../../../config/routes'
import quotesService from '../../../services/quotesService'
import documentationService from '../../../services/documentationService'
import { useErrorState } from '../../../contexts/ErrorHandling'
import { quotesFilters, quoteStatus, quoteStatusNames } from '../../../assets/constants/quote'
import * as utils from '../../../assets/js/utils'

import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'
import InfoLabel from '../../UI/InfoLabel/InfoLabel'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'

import './AssignedQuotesTable.scss'

const AssignedQuotesTable = (props) => {
    const initialFilterConfig = {
        [quotesFilters.PAGE]: 1,
        [quotesFilters.PAGE_SIZE]: 10,
        [quotesFilters.SEARCH]: '',
        [quotesFilters.ORDER_BY]: '',
        [quotesFilters.DIRECTION]: ''
    }
    const [filterConfig, setFilterConfig] = useState({...initialFilterConfig})
    const [searchString, setSearchString] = useState('')
    const [tempSortingConfig, setTempSortingConfig] = useState(null)
    const [quotesData, setQuotesData] = useState(null)
    const [sortingFormErrors, setSortingFormErrors] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isSortingModalVisible, setSortingModalVisible] = useState(false)
    const { setErrorDialog } = useErrorState()
    const history = useHistory()

    const handleInput = (event, result) => {
        const { value } = result || event.target
        setSearchString(value)
    }

    const handleTempChange = (event, result, fieldName) => {
        const { value } = result || event.target
        setTempSortingConfig({
            ...tempSortingConfig,
            [fieldName]: value
        })
    }

    const handleChange = (event, result, fieldName) => {
        const { value } = result || event.target
        setFilterConfig({
            ...filterConfig,
            [fieldName]: value
        })
    }

    const handleClick = pageNumber => {
        setFilterConfig({
            ...filterConfig,
            [quotesFilters.PAGE]: pageNumber
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setFilterConfig({
                ...filterConfig,
                [quotesFilters.SEARCH]: searchString
            })
        }
    }

    const generateInfoLabelContent = () => {
        const statusName = quoteStatusNames[quoteStatus.IN_PROGRESS].plural.toUpperCase()
        const labelStart = `COTIZACIONES ${statusName}`

        return (
            <>
                {labelStart} <strong>{quotesData.total}</strong>
            </>
        )
    }

    const generateDropdownOptions = () => {
        const options = []

        for (let i = 1; i <= 15; i++) {
            options.push({
                key: i,
                text: i,
                value: i,
                content: i,
            })
        }

        return options
    }

    const generateResultsNumbersInformation = () => {
        const pageSize = quotesData.pageSize
        const firstElementNumber = ((quotesData.currentPage - 1) * pageSize + 1)
        const lastElementNumber = ((firstElementNumber + quotesData.results.length) - 1)
        const totalAmount = quotesData.total

        return `${firstElementNumber}-${lastElementNumber} de ${totalAmount}`
    }

    const cancelSorting = () => {
        setSortingModalVisible(false)
        setTempSortingConfig(null)
        setSortingFormErrors([])
    }

    const applySorting = () => {
        const errors = []

        if (!tempSortingConfig?.[quotesFilters.ORDER_BY]) {
            errors.push(quotesFilters.ORDER_BY)
        }

        if (!tempSortingConfig?.[quotesFilters.DIRECTION]) {
            errors.push(quotesFilters.DIRECTION)
        }

        if (errors.length > 0) {
            setSortingFormErrors(errors)
            return
        }

        setSortingModalVisible(false)
        setFilterConfig({
            ...filterConfig,
            ...tempSortingConfig
        })
        setTempSortingConfig(null)
        setSortingFormErrors([])
    }

    const getAssignedQuotes = () => {
        setLoading('listado')

        const params = {}

        for (let key in filterConfig) {
            const value = filterConfig[key]

            if (value) {
                params[key] = value
            }
        }

        quotesService.getAssignedQuotes(params)
            .then(res => {
                setQuotesData(res.data)
                setLoading(false)
            })
            .catch(error => {
                const currentPage = filterConfig[quotesFilters.PAGE]

                if ((error?.response?.status === 404) && (currentPage > 1)) {
                    setFilterConfig({
                        ...filterConfig,
                        [quotesFilters.PAGE]: 1
                    })
                    return
                }

                setLoading(false)

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al cargar las cotizaciones. Por favor, inténtalo de nuevo más tarde.'
                }

                setErrorDialog({
                    title: 'Error cargando cotizaciones',
                    description: errorMessage,
                    onConfirm: () => history.push(paths.DASHBOARD)
                })
            })
    }

    useEffect(() => {
        getAssignedQuotes()
    }, [...Object.values(filterConfig)])

    return (
        <>
            {
                (isLoading || !quotesData) &&
                <FullPageLoader text={`Cargando ${isLoading}...`}/>
            }
            {
                (!isLoading && quotesData) &&
                <div className='cb-assigned-quotes-table-wrapper'>
                    <div className='cb-assigned-quotes-table-header'>
                        <div className='cb-assigned-quotes-search-bar'>
                            <Input
                                autoFocus
                                icon='search'
                                iconPosition='left'
                                placeholder='Buscar...'
                                className='cb-search-input'
                                value={searchString}
                                onKeyDown={handleKeyDown}
                                onChange={handleInput}/>
                            <Button
                                basic
                                icon
                                className='cb-sorting-button'
                                onClick={() => setSortingModalVisible(true)}>
                                <Icon name='sort amount down'/>
                            </Button>
                        </div>
                    </div>
                    {
                        (quotesData.results?.length > 0) &&
                        <>
                            <div className='cb-assigned-quotes-table'>
                                <Table stackable selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={4}>TOMADOR</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>NIF/CIF</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>FECHA DE ENTRADA</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>Nº COTIZACIÓN</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>MODALIDAD</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            quotesData?.results?.map((quote, index) => (
                                                <Table.Row
                                                    key={index}
                                                    className='cb-quote'
                                                    onClick={
                                                        () => history.push(paths.QUOTE_MANAGEMENT.replace(':quoteId', quote.id))
                                                    }>
                                                    <Table.Cell>
                                                        <strong>{quote.data.policyholderName.textual || '-'}</strong>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {quote.data.policyholderTaxID.textual || '-'}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {utils.datetimeToStr(quote.display_date)}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {quote.number || '-'}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Label
                                                            size='large'
                                                            content={quote.modality.short_name.toUpperCase()}
                                                            className='cb-modality-label'/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                            <div className='cb-assigned-quotes-page-management'>
                                <Table>
                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell width={6}>
                                                <InfoLabel>
                                                    {generateInfoLabelContent()}
                                                </InfoLabel>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={5}>
                                                <strong>Resultados por página:</strong>
                                                <Dropdown
                                                    inline
                                                    scrolling
                                                    upward
                                                    options={generateDropdownOptions()}
                                                    name={quotesFilters.PAGE_SIZE}
                                                    value={filterConfig[quotesFilters.PAGE_SIZE]}
                                                    onChange={(event, result) => handleChange(event, result, quotesFilters.PAGE_SIZE)}
                                                    className='cb-page-size-dropdown'/>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={2} className='cb-page-results-numbers'>
                                                <strong>{generateResultsNumbersInformation()}</strong>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={3} className='cb-page-switch-buttons'>
                                                <Button
                                                    basic
                                                    compact
                                                    icon
                                                    disabled={quotesData.previousPage === null}
                                                    onClick={() => handleClick(quotesData.previousPage)}>
                                                    <Icon name='chevron left'/>
                                                </Button>
                                                <Button
                                                    basic
                                                    compact
                                                    icon
                                                    disabled={quotesData.nextPage === null}
                                                    onClick={() => handleClick(quotesData.nextPage)}>
                                                    <Icon name='chevron right'/>
                                                </Button>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </>
                    }
                    {
                        (quotesData.results?.length === 0) &&
                        <Message info icon size='large' className='cb-empty-message'>
                            <Icon name='info circle'/>
                            <Message.Content>
                                <Message.Header>
                                    No se han encontrado cotizaciones
                                </Message.Header>
                                Actualmente no eres técnico de ninguna cotización que se encuentre
                                <strong>
                                    <i> {quoteStatusNames[quoteStatus.IN_PROGRESS].singular.toLowerCase()} </i>
                                </strong>
                                {
                                    (filterConfig[quotesFilters.SEARCH] !== '') &&
                                    <>
                                        que cumpla con los criterios de búsqueda
                                    </>
                                }
                            </Message.Content>
                        </Message>
                    }
                </div>
            }
            <Modal open={isSortingModalVisible}
                   size='tiny'
                   dimmer='blurring'
                   className='cb-sorting-modal'>
                <Modal.Header>
                    Ordenar listado
                </Modal.Header>
                <Modal.Content>
                    <Form error={sortingFormErrors.length > 0}>
                        <Form.Dropdown
                            selection
                            fluid
                            error={sortingFormErrors.includes(quotesFilters.ORDER_BY)}
                            label='Ordenar por'
                            placeholder='Seleccionar'
                            name={quotesFilters.ORDER_BY}
                            value={tempSortingConfig?.[quotesFilters.ORDER_BY] || ''}
                            onChange={(event, result) => handleTempChange(event, result, quotesFilters.ORDER_BY)}
                            options={[
                                {
                                    key: 'policyholderName',
                                    text: 'Tomador',
                                    value: 'policyholderName'
                                },
                                {
                                    key: 'policyholderTaxID',
                                    text: 'NIF/CIF',
                                    value: 'policyholderTaxID'
                                },
                                {
                                    key: 'date',
                                    text: 'Fecha',
                                    value: 'date'
                                },
                                {
                                    key: 'modality',
                                    text: 'Modalidad',
                                    value: 'modality'
                                }
                            ]}/>
                        <Form.Dropdown
                            selection
                            fluid
                            error={sortingFormErrors.includes(quotesFilters.DIRECTION)}
                            label='Sentido del orden'
                            placeholder='Seleccionar'
                            name={quotesFilters.DIRECTION}
                            value={tempSortingConfig?.[quotesFilters.DIRECTION] || ''}
                            onChange={(event, result) => handleTempChange(event, result, quotesFilters.DIRECTION)}
                            options={[
                                {
                                    key: 'ascending',
                                    text: 'Ascendente',
                                    value: 'ascending'
                                },
                                {
                                    key: 'descending',
                                    text: 'Descendente',
                                    value: 'descending'
                                }
                            ]}/>
                        {
                            (sortingFormErrors.length > 0) &&
                            <Message error>
                                <Message.List>
                                    <Message.Item>
                                        Es obligatorio indicar todos los campos
                                    </Message.Item>
                                </Message.List>
                            </Message>
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton
                        compact
                        onClick={cancelSorting}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton
                        compact
                        onClick={applySorting}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default AssignedQuotesTable