export const initialState = {
    initialized: false,
    validated: false,
    changeCounter: 0,
    lastChange: null,
    config: null,
    modality: null,
    values: {},
    enablements: {},
    requirements: {},
    visibilities: {},
    hiddenFields: [],
    readOnly: false
}

export const FormActionTypes = {
    INIT_FORM: 'INIT_FORM',
    RESET_FORM: 'RESET_FORM',
    UPDATE_VALUE: 'UPDATE_VALUE',
    UPDATE_ENABLEMENT: 'UPDATE_ENABLEMENT',
    UPDATE_REQUIREMENT: 'UPDATE_REQUIREMENT',
    UPDATE_VISIBILITY: 'UPDATE_VISIBILITY',
    DELETE_REFERENCES: 'DELETE_REFERENCES',
    UPDATE_VALIDATION: 'UPDATE_VALIDATION'
}

const sanitizeValues = (values) => {
    if (values && (Object.keys(values).length > 0)) {
        return {...values}
    }

    return {}
}
 
export const FormReducer = (initialState, action) => {
    switch (action.type) {
        case FormActionTypes.INIT_FORM:
            return {
                ...initialState,
                config: {...action.data.config},
                modality: action.data.modality,
                values: sanitizeValues(action.data.values),
                hiddenFields: [...action.data.hiddenFields],
                initialized: true,
                readOnly: action.data.readOnly
            }
        case FormActionTypes.RESET_FORM:
            return {
                initialized: false,
                validated: false,
                changeCounter: 0,
                lastChange: null,
                config: null,
                modality: null,
                values: {},
                enablements: {},
                requirements: {},
                visibilities: {},
                hiddenFields: [],
                readOnly: false
            }
        case FormActionTypes.UPDATE_VALUE:
            return {
                ...initialState,
                changeCounter: initialState.changeCounter + 1,
                lastChange: action.data.name,
                values: {
                    ...sanitizeValues(initialState.values),
                    [action.data.name]: action.data.value
                }
            }
        case FormActionTypes.UPDATE_ENABLEMENT:
            return {
                ...initialState,
                enablements: {
                    ...initialState.enablements,
                    [action.data.name]: action.data.enabled
                }
            }
        case FormActionTypes.UPDATE_REQUIREMENT:
            return {
                ...initialState,
                requirements: {
                    ...initialState.requirements,
                    [action.data.name]: action.data.required
                }
            }
        case FormActionTypes.UPDATE_VISIBILITY:
            return {
                ...initialState,
                visibilities: {
                    ...initialState.visibilities,
                    [action.data.name]: action.data.visible
                }
            }
        case FormActionTypes.DELETE_REFERENCES:
            const nextState = {...initialState}
            
            delete nextState.values[action.data.name]
            delete nextState.enablements[action.data.name]
            delete nextState.requirements[action.data.name]
            delete nextState.visibilities[action.data.name]

            return nextState
        case FormActionTypes.UPDATE_VALIDATION:
            return {
                ...initialState,
                validated: action.data.validated
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}