import axios from 'axios'

import { axiosInstance } from './serviceConfig'

const oauth2Endpoint = '/o/token/'
const authEndpoint = '/api/auth'

const getNewAccessToken = data => {
    const params = new URLSearchParams()
    params.append('grant_type', 'password')
    params.append('username', data?.username?.toLowerCase())
    params.append('password', data?.password)

    const config = {
        auth: {
            username: process.env.REACT_APP_OAUTH2_CLIENT_ID,
            password: process.env.REACT_APP_OAUTH2_CLIENT_SECRET
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    return axiosInstance.post(oauth2Endpoint, params, config)
}

const refreshAccessToken = () => {
    const refreshToken = JSON.parse(localStorage.getItem('accessToken'))?.refresh_token

    const grantTypeParam = 'grant_type=refresh_token'
    const clientIdParam = `client_id=${process.env.REACT_APP_OAUTH2_CLIENT_ID}`
    const clientSecretParam = `client_secret=${process.env.REACT_APP_OAUTH2_CLIENT_SECRET}`
    const refreshTokenParam = `refresh_token=${refreshToken}`

    const data = [grantTypeParam, clientIdParam, clientSecretParam, refreshTokenParam].join('&')

    return axiosInstance.post(oauth2Endpoint, data)
}

const getCurrentUser = () => (
    axiosInstance.get(`${authEndpoint}/current-user`)
)

const requestPasswordReset = data => (
    axiosInstance.post(`${authEndpoint}/reset-password`, data)
)

const checkResetKey = resetKey => {
    if (!resetKey) {
        resetKey = 'none'
    }
    
    return axiosInstance.get(`${authEndpoint}/reset-password/${resetKey}`)
}

const resetPassword = (resetKey, data) => (
    axiosInstance.put(`${authEndpoint}/reset-password/${resetKey}`, data)
)

const changePassword = data => (
    axiosInstance.put(`${authEndpoint}/change-password`, data)
)

const getUserData = userId => (
    axiosInstance.get(`${authEndpoint}/users/${userId}`)
)

const updateUserData = (userId, data) => (
    axiosInstance.put(`${authEndpoint}/users/${userId}`, data)
)

const deleteUser = userId => (
    axiosInstance.delete(`${authEndpoint}/users/${userId}`)
)

export default {
    getNewAccessToken,
    refreshAccessToken,
    getCurrentUser,
    requestPasswordReset,
    checkResetKey,
    resetPassword,
    changePassword,
    getUserData,
    updateUserData,
    deleteUser
}