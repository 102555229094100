const accessToken = JSON.parse(localStorage.getItem('accessToken'))

export const initialState = {
    accessToken: accessToken || null,
    loggedUser: null,
    checkingUser: false,
    loginLoading: false,
    loginErrorMessage: null,
    tokenRefreshTimeoutId: null,
    tokenRefreshFailed: false,
    logoutTimeoutId: null,
    minutesUntilLogout: null
}

export const AuthActionTypes = {
    REQUEST_LOGIN: 'REQUEST_LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT: 'LOGOUT',
    LOGIN_ERROR: 'LOGIN_ERROR',
    CHECK_USER: 'CHECK_USER',
    RECOVER_USER: 'RECOVER_USER',
    RECOVER_USER_SUCCESS: 'RECOVER_USER_SUCCESS',
    RECOVER_USER_ERROR: 'RECOVER_USER_ERROR',
    REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',
    SCHEDULE_TOKEN_REFRESH_SUCCESS: 'SCHEDULE_TOKEN_REFRESH_SUCCESS',
    NOTIFY_TOKEN_REFRESH_FAILURE: 'NOTIFY_TOKEN_REFRESH_FAILURE',
    SCHEDULE_LOGOUT_SUCCESS: 'SCHEDULE_LOGOUT_SUCCESS'
}
 
export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case AuthActionTypes.REQUEST_LOGIN:
            return {
                ...initialState,
                loginLoading: true
            }
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...initialState,
                loggedUser: action.authData.currentUser,
                accessToken: action.authData.accessToken,
                loginLoading: false,
                loginErrorMessage: null,
                tokenRefreshFailed: false,
                logoutTimeoutId: null,
                minutesUntilLogout: null
            }
        case AuthActionTypes.LOGOUT:
            return {
                ...initialState,
                loggedUser: null,
                accessToken: null,
                tokenRefreshTimeoutId: null,
                logoutTimeoutId: null
            }
        case AuthActionTypes.LOGIN_ERROR:
            return {
                ...initialState,
                loginLoading: false,
                loginErrorMessage: action.errorMessage
            }
        case AuthActionTypes.RECOVER_USER:
            return {
                ...initialState,
                checkingUser: true
            }
        case AuthActionTypes.RECOVER_USER_SUCCESS:
            return {
                ...initialState,
                checkingUser: false,
                loggedUser: action.authData.currentUser
            }
        case AuthActionTypes.RECOVER_USER_ERROR:
            return {
                ...initialState,
                checkingUser: false,
                loggedUser: null,
                accessToken: null,
                tokenRefreshTimeoutId: null
            }
        case AuthActionTypes.REFRESH_TOKEN_SUCCESS:
            return {
                ...initialState,
                loggedUser: action.authData.currentUser,
                accessToken: action.authData.accessToken
            }
        case AuthActionTypes.REFRESH_TOKEN_ERROR:
            return {
                ...initialState,
                tokenRefreshTimeoutId: null,
                tokenRefreshFailed: true,
                minutesUntilLogout: action.minutesUntilLogout
            }
        case AuthActionTypes.SCHEDULE_TOKEN_REFRESH_SUCCESS:
            return {
                ...initialState,
                tokenRefreshTimeoutId: action.timeoutId
            }
        case AuthActionTypes.SCHEDULE_TOKEN_REFRESH_ERROR:
            return {
                ...initialState,
                tokenRefreshTimeoutId: null
            }
        case AuthActionTypes.SCHEDULE_LOGOUT_SUCCESS:
            return {
                ...initialState,
                logoutTimeoutId: action.timeoutId
            }
        case AuthActionTypes.NOTIFY_TOKEN_REFRESH_FAILURE:
            return {
                ...initialState,
                tokenRefreshFailed: false,
                minutesUntilLogout: null
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}