import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Dimmer, Input, Loader, Table } from 'semantic-ui-react'

import { paths } from '../../../config/routes'
import { useErrorState } from '../../../contexts/ErrorHandling'
import { 
    useClosedQuotesAmount,
    useQuotesAmountByStatus
} from '../../../contexts/PeriodicUpdates'

import { quoteStatus } from '../../../assets/constants/quote'
import * as utils from '../../../assets/js/utils'
import quotesService from '../../../services/quotesService'

import './QuotesBasicOverviewTable.scss'


const QuotesBasicOverviewTable = (props) => {
    const [loading, setLoading] = useState(true)
    const [quotes, setQuotes] = useState([])
    const [searchString, setSearchString] = useState('')
    const { setErrorDialog } = useErrorState()
    const newQuotesAmount = useQuotesAmountByStatus(quoteStatus.NEW)
    const closedQuotesAmount = useClosedQuotesAmount()
    const history = useHistory()

    const handleInputChange = (event, result) => {
        const { value } = result || event.target
        setSearchString(value)
    }

    const getUpdateDependencies = () => {
        switch (props.statusRange) {
            case quoteStatus.ranges.NEW:
                return [newQuotesAmount]
            case quoteStatus.ranges.CLOSED:
                return [closedQuotesAmount]
            default:
                return []
        }
    }

    const generateNoDataMessage = () => {
        let message

        switch (props.statusRange) {
            case quoteStatus.ranges.NEW:
                message = 'No hay nuevas cotizaciones'
                break
            case quoteStatus.ranges.CLOSED:
                message = 'Todavía no hay cotizaciones cerradas'
                break
            default:
                message = 'No se han encontrado datos'
                break
        }

        return (
            <h1>
                <i>
                    {message}
                </i>
            </h1>
        )
    }

    const generateDataTable = () => (
        <Table stackable selectable striped padded basic='very' size='large'>
            <Table.Body>
                {
                    quotes.map((quote, index) => {
                        let result = null
                        let brokerage = quote.requester?.brokerage?.name
                        let formattedDate = utils.datetimeToStr(quote.display_date)

                        if (brokerage?.toLowerCase()?.includes(searchString) ||
                            formattedDate?.toLowerCase()?.includes(searchString)){
                            result = (
                                <Table.Row
                                    key={index}
                                    onClick={() => history.push(paths.QUOTE_MANAGEMENT.replace(':quoteId', quote.id))}>
                                    <Table.Cell width={10}>
                                        <strong>{brokerage}</strong>
                                    </Table.Cell>
                                    <Table.Cell textAlign='right' className='cb-date-data' width={6}>
                                        {utils.datetimeToStr(quote.display_date)}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }

                        return result
                    })
                }
            </Table.Body>
        </Table>
    )
    
    useEffect(() => {
        quotesService.getQuotesOverview(props.statusRange)
            .then(res => {
                setQuotes(res.data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                setErrorDialog({
                    title: 'Error al cargar datos',
                    description: `Ha tenido lugar un error al cargar los datos de cotizaciones.`
                })
            })
    }, getUpdateDependencies())

    return (
        <div className='cb-quotes-basic-overview-table'>
            <div className='cb-table-header'>
                <h3>
                    {props.title}
                </h3>
                <Input
                    icon='search'
                    iconPosition='left'
                    placeholder='Buscar...'
                    size='small'
                    className='cb-search-input'
                    onChange={handleInputChange}/>
            </div>
            <div className={`cb-table-wrapper${loading ? ' cb-loading' : ''}${(!loading && !quotes.length) ? ' cb-empty' : ''}`}>
                {
                    loading &&
                    <Loader active size='large' inline='centered'>
                        Cargando datos...
                    </Loader>
                }
                {
                    !loading && (quotes.length > 0) &&
                    generateDataTable()
                }
                {
                    !loading && (quotes.length === 0) &&
                    generateNoDataMessage()
                }
            </div>
        </div>
    )
}

export default QuotesBasicOverviewTable