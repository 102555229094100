import React, { createContext, useReducer } from 'react'

import { useAuthState } from '../Auth'

import { PeriodicUpdatesReducer, initialState } from './reducer'
 
export const PeriodicUpdatesStateContext = createContext()
export const PeriodicUpdatesDispatchContext = createContext()

export const PeriodicUpdatesProvider = ({ children }) => {
    const [formState, dispatch] = useReducer(PeriodicUpdatesReducer, initialState)

    const authData = useAuthState()

    return (
        <PeriodicUpdatesStateContext.Provider value={formState}>
            <PeriodicUpdatesDispatchContext.Provider value={dispatch}>
                {children}
            </PeriodicUpdatesDispatchContext.Provider>
        </PeriodicUpdatesStateContext.Provider>
    )
}