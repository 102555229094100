import React, { useEffect, useState } from 'react'

import { TextArea } from 'semantic-ui-react'

import {
    deleteReferences,
    updateValue,
    updateVisibility,
    useEnablement,
    useValue,
    useFormState,
    useFormDispatch
} from '../../../../../contexts/Form'

import FieldWrapper from '../FieldWrapper/FieldWrapper'
import InfoTooltip from '../../../InfoTooltip/InfoTooltip'


const TextareaField = (props) => {
    const formState = useFormState()
    const dispatch = useFormDispatch()
    const enabled = useEnablement(props.name)
    const value = useValue(props.name)

    const getBasicProps = () => {
        return {
            placeholder: props.placeholder ? props.placeholder : props.label,
            rows: props.rows ? props.rows : 3,
            disabled: !Boolean(enabled),
            name: props.name,
            readOnly: props.readOnly || formState.readOnly,
            value: value?.raw || ''
        }
    }

    const handleChange = (event, result) => {
        const { value } = result || event.target
        const valueObj = {
            raw: value,
            textual: value
        }
        
        updateValue(dispatch, props.name, valueObj)
    }

    useEffect(() => {
        if (value === undefined) {
            let defaultValue = props.defaultValue

            if (defaultValue) {
                defaultValue = {
                    raw: defaultValue,
                    textual: defaultValue
                }
            }
            
            updateValue(dispatch, props.name, defaultValue)
        }
    }, [])

    return (
        <FieldWrapper {...props}>
            {
                props.label &&
                <label>
                    {props.label}
                    {
                        props.info &&
                        <InfoTooltip text={props.info}/>
                    }
                </label>
            }
            <TextArea
                {...getBasicProps()}
                onChange={handleChange}/>
        </FieldWrapper>
    )
}

export default TextareaField