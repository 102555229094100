import React, { useState } from 'react'
import { Button, Divider, Icon, Image, Message, Segment } from 'semantic-ui-react'

import PasswordResetForm from '../PasswordResetForm/PasswordResetForm'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'

import './PasswordResetSegment.scss'

const PasswordResetSegment = (props) => {
    const [loading, setLoading] = useState(false)
    const [requestSuccessData, setRequestSuccessData] = useState(null)

    return (
        <Segment className='cb-password-reset-segment'>
            {
                requestSuccessData
                ? <Message positive icon className='cb-top-margin'>
                      <Icon name='envelope'/>
                      <Message.Content>
                          <Message.Header>Instrucciones enviadas</Message.Header>
                          <p>
                              Se ha enviado un email a tu dirección de correo electrónico
                              con las instrucciones para restaurar la contraseña. Por favor,
                              revisa tu bandeja de entrada. La solicitud caducará dentro
                              de {requestSuccessData.hours_left} horas.
                          </p>
                      </Message.Content>
                  </Message>
                : <PasswordResetForm setLoading={setLoading} setRequestSuccessData={setRequestSuccessData}/>
            }
            <Divider/>
            {
                requestSuccessData
                ? <SecondaryButton fluid className='cb-bottom-margin' onClick={props.showLogin}>
                      Aceptar
                  </SecondaryButton>
                : <Button.Group fluid className='cb-bottom-margin'>
                      <PrimaryButton onClick={props.showLogin}>
                          Cancelar
                      </PrimaryButton>
                      <SecondaryButton form='cb-password-reset-form' type='submit' loading={loading} disabled={loading}>
                          Obtener contraseña
                      </SecondaryButton>
                  </Button.Group>
            }
            
        </Segment>
    )
}

export default PasswordResetSegment